import { Flex } from "@chakra-ui/react"
import { memo } from "react"
import { useNavigate } from "react-router-dom"

import FormikForm from "../../common/Form/FormikForm"
import AddNewButton from "../../common/AddNewButton/AddNewButton"
import IntentionsFilterBar from "./IntentionsFilterBar"
import IntentionsTable from "./IntentionsTable"
import { useUpdateIntentionsMutation } from "../../../services/api/intentionsApi"

const IntentionsForm = ({ initValues }) => {
	const [updateIntentions] = useUpdateIntentionsMutation()
	const navigate = useNavigate()

	const handleNavigateToAdd = () => navigate("/calendar-intentions/add")

	return (
		<FormikForm
			isPatchMethod
			enableReinitialize
			initialValues={{ intentions: initValues }}
			onSubmit={updateIntentions}
		>
			<Flex justifyContent={"flex-end"} mt={{ base: 3, md: 6 }} gap={3} flexWrap={"wrap"}>
				<IntentionsFilterBar />
				<AddNewButton onClick={handleNavigateToAdd} />
			</Flex>
			<IntentionsTable />
		</FormikForm>
	)
}

export default memo(IntentionsForm)
