import {
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
} from "@chakra-ui/react"
import { Field, useField } from "formik"
import React, { useState } from "react"

import IoEyeOutlineInvisible from "../../../assets/icons/login/IoEyeOutlineInvisible"
import IoEyeOutline from "../../../assets/icons/login/IoEyeOutline"

const PasswordField = ({ label, ...props }) => {
	const [field, meta] = useField(props)
	const [showPassword, setShowPassword] = useState(false)

	const fieldProps = {
		...field,
		...props,
	}

	const handleShowHidePassword = () => {
		if (props.disabled) return
		setShowPassword(!showPassword)
	}

	return (
		<FormControl mb={["1", "1", "1", "2"]} isInvalid={meta.error && meta.touched}>
			<FormLabel my='0'>{label}</FormLabel>
			<InputGroup w={props.w || props.width}>
				<Field
					as={Input}
					fontSize={[13, 14, 15, 15, 16]}
					h={{ base: "30px", lg: "35px" }}
					my='0'
					autoComplete='on'
					{...fieldProps}
					type={showPassword ? "text" : "password"}
					pe={{ base: 16, md: 20 }}
				/>
				<InputRightElement justifyContent={"flex-end"} w={{ base: "60px" }} h='100%' alignContent='center'>
					<Button
						type='button'
						aria-label='Show/Hide button'
						h={props.h}
						pe={5}
						colorScheme='transparent'
						isDisabled={props.disabled}
						bgColor={"transparent"}
						onClick={handleShowHidePassword}
					>
						{showPassword ? (
							<IoEyeOutlineInvisible boxSize={[5, 6, 7]} />
						) : (
							<IoEyeOutline boxSize={[5, 6, 7]} />
						)}
					</Button>
				</InputRightElement>
			</InputGroup>
			<FormErrorMessage my='0' fontSize={[11, 12, 13, 13, 14]}>
				{meta.error}{" "}
			</FormErrorMessage>
		</FormControl>
	)
}

export default PasswordField
