import { FormControl, FormErrorMessage, FormLabel, Select } from "@chakra-ui/react"
import { Field, useField } from "formik"

const SelectField = ({ label, options, ...props }) => {
	const [field, meta] = useField(props)

	return (
		<FormControl isInvalid={meta.error && meta.touched}>
			<FormLabel>{label}</FormLabel>
			<Field
				as={Select}
				fontSize={[13, 14, 15, 15, 16]}
				h={{ base: "30px", lg: "35px" }}
				my='0'
				cursor='pointer'
				{...field}
				{...props}
			>
				{options?.map((option) => (
					<option key={option.id} value={option.value}>
						{option.name}
					</option>
				))}
			</Field>
			<FormErrorMessage fontSize={[11, 12, 13, 13, 14]} ms={1} my={0}>
				{meta.error}
			</FormErrorMessage>
		</FormControl>
	)
}

export default SelectField
