import { Flex } from "@chakra-ui/react"
import { memo, useEffect, useState } from "react"
import * as Yup from "yup"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import FormActionsButtons from "../../common/Form/FormActionsButtons"
import FormikForm from "../../common/Form/FormikForm"
import PagesTable from "./PagesTable"
import { useUpdatePagesMutation } from "../../../services/api/pagesApi"
import DragAndDropButton from "./DragAndDropButton"
import AddNewButton from "../../common/AddNewButton/AddNewButton"
import PagesFilterBar from "./PagesFilterBar"
import PermissionGuard from "../../common/Permissions/PermissionGuard"

const PagesForm = () => {
	const [updatePages] = useUpdatePagesMutation()
	const pages = useSelector((store) => store.pages.pages)
	const initValues = useSelector((store) => store.pages.initValues)
	const [isPagesHasData, setIsPagesHasData] = useState(!!pages?.length)
	const navigate = useNavigate()

	useEffect(() => {
		if (!!pages?.length && isPagesHasData === true) return
		if (!pages?.length && isPagesHasData === false) return

		setIsPagesHasData(!!pages?.length)
	}, [pages?.length])

	const handleNavigateToAdd = () => navigate("/pages/add")

	return (
		<FormikForm
			isPatchMethod={true}
			enableReinitialize
			initialValues={{ pages: initValues }}
			validationSchema={Yup.object().shape({
				pages: Yup.array().of(
					Yup.object().shape({
						title: Yup.string().required("Tytuł jest wymagany"),
					}),
				),
			})}
			onSubmit={updatePages}
		>
			<Flex justifyContent={"flex-end"} mt={{ base: 3, md: 6 }} gap={3} flexWrap={"wrap"}>
				<PagesFilterBar />
				<DragAndDropButton />
				<PermissionGuard>
					<AddNewButton onClick={handleNavigateToAdd} />
				</PermissionGuard>
			</Flex>
			<PagesTable />
			{isPagesHasData && (
				<Flex justifyContent={"flex-end"} mt={8}>
					<FormActionsButtons />
				</Flex>
			)}
		</FormikForm>
	)
}

export default memo(PagesForm)
