import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const homiliesApi = createApi({
	reducerPath: "homiliesApi",
	// eslint-disable-next-line no-undef
	baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_KEY}/api`, credentials: "include" }),
	tagTypes: ["Homilies"],
	endpoints: (builder) => ({
		getHomilies: builder.query({
			query: () => "/homilies",
			keepUnusedDataFor: 0,
			providesTags: ["Homilies"],
		}),
		addHomilies: builder.mutation({
			query: (homilie) => ({
				url: "/homilies",
				method: "POST",
				body: homilie,
			}),
		}),
		updateHomilies: builder.mutation({
			query: (homilie) => ({
				url: "/homilies",
				method: "PATCH",
				body: homilie,
			}),
			invalidatesTags: ["Homilies"],
		}),
		getHomilie: builder.query({
			query: (id) => `/homilies/${id}`,
			keepUnusedDataFor: 0,
		}),
	}),
})

export const { useGetHomiliesQuery, useAddHomiliesMutation, useUpdateHomiliesMutation, useGetHomilieQuery } =
	homiliesApi
