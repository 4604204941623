import { Flex } from "@chakra-ui/layout"
import * as Yup from "yup"

import FormikForm from "../../../common/Form/FormikForm"
import FormActionsButtons from "../../../common/Form/FormActionsButtons"
import { useUpdateHomiliesMutation } from "../../../../services/api/homiliesApi"
import HomiliesEditBasicDetails from "./HomiliesEditBasicDetails"
import HomiliesEditSound from "./HomiliesEditSound"
import HomiliesEditSummary from "./HomiliesEditSummary"

const HomiliesEditForm = ({ initValues }) => {
	const [updateHomilie] = useUpdateHomiliesMutation()

	return (
		<FormikForm
			isPatchMethod
			enableReinitialize
			shouldNavigate
			initialValues={initValues || { date: "" }}
			validationSchema={Yup.object().shape({
				topic: Yup.string().required("Temat jest wymagany"),
				date: Yup.date()
					.min("01-01-2000", "Data nie może być mniejsza niz 01.01.2000")
					.max("12-31-2099", "Data nie może być większa niz 31.12.2099")
					.required("Data jest wymagana"),
			})}
			onSubmit={updateHomilie}
		>
			<Flex flexDirection={"column"} gap={5} mt={7}>
				<HomiliesEditBasicDetails />
				<HomiliesEditSound />
				<HomiliesEditSummary />
			</Flex>
			<Flex justifyContent={"flex-end"} mt={5}>
				<FormActionsButtons />
			</Flex>
		</FormikForm>
	)
}

export default HomiliesEditForm
