import { FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react"
import { Field, useField } from "formik"

const TimeField = ({ label, ...props }) => {
	const [field, meta] = useField(props)

	const fieldProps = {
		...field,
		...props,
	}

	return (
		<FormControl isInvalid={meta.error && meta.touched}>
			<FormLabel my='0'>{label}</FormLabel>
			<Field
				as={Input}
				fontSize={[13, 14, 15, 15, 16]}
				type='time'
				h={{ base: "30px", lg: "35px" }}
				my='0'
				{...fieldProps}
			/>
			<FormErrorMessage my='0' fontSize={[11, 12, 13, 13, 14]}>
				{meta.error}
			</FormErrorMessage>
		</FormControl>
	)
}

export default TimeField
