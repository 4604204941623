import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"

import { notificationMiddleware } from "../../middlewares/notificationMiddleware"
import { authApi } from "../../api/authApi"
import { pagesApi } from "../../api/pagesApi"
import { pagesWithSubPagesApi } from "../../api/pagesWithSubPagesApi"
import { singlePagesApi } from "../../api/singlePagesApi"
import { newsApi } from "../../api/newsApi"
import { exCathedraApi } from "../../api/exCathedraApi"
import { galleryApi } from "../../api/galleryApi"
import { homiliesApi } from "../../api/homiliesApi"
import { intentionsApi } from "../../api/intentionsApi"
import { recurringEventsApi } from "../../api/recurringEventsApi"
import { oneDayEventsApi } from "../../api/oneDayEventsApi"
import pagesSlice from "../reducers/pages-slice"
import pagesWithSubPagesSlice from "../reducers/pagesWithSubPages-slice"
import singlePageSlice from "../reducers/singlePage-slice"
import newsSlice from "../reducers/news-slice"
import exCathedraSlice from "../reducers/exCathedra-slice"
import gallerySlice from "../reducers/gallery-slice"
import homiliesSlice from "../reducers/homilies-slice"
import intentionsSlice from "../reducers/intentions-slice"
import recurringEventsSlice from "../reducers/recurringEvents-slice"
import oneDayEventsSlice from "../reducers/oneDayEvents-slice"
import { footerApi } from "../../api/footerApi"
import footerSlice from "../reducers/footer-slice"

/**
 * Variable which fusion all slices
 * @param reducer - Object with all reducers
 * @return store - Object with all reducers
 */
const store = configureStore({
	reducer: {
		[authApi.reducerPath]: authApi.reducer,
		[pagesApi.reducerPath]: pagesApi.reducer,
		pages: pagesSlice,
		[pagesWithSubPagesApi.reducerPath]: pagesWithSubPagesApi.reducer,
		subPages: pagesWithSubPagesSlice,
		[singlePagesApi.reducerPath]: singlePagesApi.reducer,
		singlePages: singlePageSlice,
		[newsApi.reducerPath]: newsApi.reducer,
		news: newsSlice,
		[exCathedraApi.reducerPath]: exCathedraApi.reducer,
		exCathedra: exCathedraSlice,
		[galleryApi.reducerPath]: galleryApi.reducer,
		gallery: gallerySlice,
		[homiliesApi.reducerPath]: homiliesApi.reducer,
		homilies: homiliesSlice,
		[intentionsApi.reducerPath]: intentionsApi.reducer,
		intentions: intentionsSlice,
		[recurringEventsApi.reducerPath]: recurringEventsApi.reducer,
		recurringEvents: recurringEventsSlice,
		[oneDayEventsApi.reducerPath]: oneDayEventsApi.reducer,
		oneDayEvents: oneDayEventsSlice,
		[footerApi.reducerPath]: footerApi.reducer,
		footer: footerSlice,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(
			authApi.middleware,
			pagesApi.middleware,
			pagesWithSubPagesApi.middleware,
			singlePagesApi.middleware,
			newsApi.middleware,
			exCathedraApi.middleware,
			galleryApi.middleware,
			homiliesApi.middleware,
			intentionsApi.middleware,
			recurringEventsApi.middleware,
			oneDayEventsApi.middleware,
			footerApi.middleware,
			notificationMiddleware,
		),
})

setupListeners(store.dispatch)

export default store
