import { Button } from "@chakra-ui/react"
import { useFormikContext } from "formik"

const SaveButton = ({ ...props }) => {
	const { setSubmitting, handleSubmit } = useFormikContext()

	const handleOnSubmit = () => (e) => {
		e.preventDefault()
		setSubmitting(true)
		setTimeout(() => {
			handleSubmit()
		}, 310)
	}

	return (
		<Button
			type='submit'
			colorScheme='green'
			h={["25px", "30px", "32px", "33px", "35px"]}
			fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
			fontWeight={500}
			onClick={handleOnSubmit()}
			{...props}
		>
			Zapisz zmiany
		</Button>
	)
}

export default SaveButton
