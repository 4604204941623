import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
} from "@chakra-ui/react"
import { Field, useField } from "formik"

const YearField = ({ label, ...props }) => {
	const [field, meta, helper] = useField(props)

	const fieldProps = {
		...field,
		...props,
	}

	const handlePressSpecialChar = () => (e) => {
		if (e.key === "." || e.key === "-" || e.key === "+") e.preventDefault()
	}

	return (
		<FormControl isInvalid={meta.error && meta.touched}>
			<FormLabel my='0'>{label}</FormLabel>
			<Field
				as={NumberInput}
				min={2000}
				max={2999}
				clampValueOnBlur={false}
				{...fieldProps}
				onChange={(value) => helper.setValue(value)}
				onKeyDown={handlePressSpecialChar()}
				allowMouseWheel
			>
				<NumberInputField
					fontSize={[13, 14, 15, 15, 16]}
					h={{ base: "30px", lg: "35px" }}
					placeholder={props?.placeholder}
				/>
				<NumberInputStepper>
					<NumberIncrementStepper />
					<NumberDecrementStepper />
				</NumberInputStepper>
			</Field>
			<FormErrorMessage my='0' fontSize={[11, 12, 13, 13, 14]}>
				{meta.error}
			</FormErrorMessage>
		</FormControl>
	)
}

export default YearField
