import { Flex, Spinner } from "@chakra-ui/react"

const LoaderHOC = ({ isLoading, children, ...props }) => {
	return isLoading ? (
		<Flex w='100%' h='100%' justifyContent={"center"} alignItems={"center"} {...props}>
			<Spinner speed='0.6s' />
		</Flex>
	) : (
		children
	)
}

export default LoaderHOC
