import { Button, useDisclosure } from "@chakra-ui/react"

import BinIcon from "../../../assets/icons/common/BinIcon"
import DeleteAlert from "../Alerts/DeleteAlert"

const DeleteButton = ({ id, mutation, ...props }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	const handleDeleteRecord = () => {
		mutation(id)
		onClose()
	}

	return (
		<>
			<Button
				colorScheme='transparent'
				h={["25px", "30px", "32px", "33px", "35px"]}
				_hover={{ bgColor: "#E5E5DF" }}
				onClick={onOpen}
				{...props}
			>
				<BinIcon color='red' boxSize={{ base: 7, sm: 8, xl: 9 }} />
			</Button>
			<DeleteAlert isOpen={isOpen} onClose={onClose} handleConfirmRemove={handleDeleteRecord} />
		</>
	)
}

export default DeleteButton
