import { Flex, Table, TableContainer } from "@chakra-ui/react"
import { memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import NoData from "../../common/NoData/NoData"
import OneDayEventsTableHeaders from "./OneDayEventsTableHeaders"
import OneDayEventsTableBody from "./OneDayEventsTableBody"
import { useFormikContext } from "formik"
import { setCurrentPage, setRecordsPerPage } from "../../../services/state/reducers/oneDayEvents-slice"
import Pagination from "../../common/Pagination/Pagination"
import FormActionsButtons from "../../common/Form/FormActionsButtons"

const OneDayEventsTable = () => {
	const dispatch = useDispatch()
	const { values } = useFormikContext()
	const searchingTerm = useSelector((store) => store.oneDayEvents.searchingTerm)
	const recordsPerPage = useSelector((store) => store.oneDayEvents.recordsPerPage)
	const currentPage = useSelector((store) => store.oneDayEvents.currentPage)
	const [startIndex, setStartIndex] = useState(0)
	const [endIndex, setEndIndex] = useState(recordsPerPage)
	const [oneDayEvents, setOneDayEvents] = useState(
		values?.oneDayEvents?.filter((event) =>
			event.content?.toString().toLowerCase().includes(searchingTerm?.toString().toLowerCase()),
		),
	)

	useEffect(() => {
		const start = (currentPage - 1) * recordsPerPage
		setStartIndex((currentPage - 1) * recordsPerPage)
		setEndIndex(start + recordsPerPage)
	}, [currentPage, recordsPerPage])

	useEffect(() => {
		setOneDayEvents(
			values?.oneDayEvents?.filter((event) =>
				event.content?.toString().toLowerCase().includes(searchingTerm?.toString().toLowerCase()),
			),
		)
	}, [searchingTerm])

	useEffect(() => {
		const updatedIntentions = oneDayEvents.map((intention) => ({
			...values.oneDayEvents.find((item) => item.id === intention.id),
		}))
		setOneDayEvents(updatedIntentions)
	}, [values.intentions])

	const handleChangePage = (page) => {
		dispatch(setCurrentPage(page))
	}

	const handleChangeRecordsPerPage = (event) => {
		dispatch(setRecordsPerPage(Number(event.target.value)))
	}

	return (
		<>
			<TableContainer mt={10}>
				<Table
					borderRadius={"4px"}
					variant='simple'
					bgColor='#FFF'
					fontSize={{ base: 11, sm: 12, md: 13, lg: 13, xl: 14 }}
				>
					<OneDayEventsTableHeaders oneDayEvents={oneDayEvents} setOneDayEvents={setOneDayEvents} />
					{!!oneDayEvents?.length && (
						<OneDayEventsTableBody oneDayEvents={oneDayEvents?.slice(startIndex, endIndex)} />
					)}
				</Table>
			</TableContainer>
			{!oneDayEvents?.length && <NoData />}
			{oneDayEvents?.length && (
				<Flex flexDirection={"column"} mt={10}>
					<Pagination
						totalRecords={oneDayEvents?.length}
						page={currentPage}
						recordsPerPage={recordsPerPage}
						setRecordsPerPage={handleChangeRecordsPerPage}
						onPageChange={(page) => handleChangePage(page)}
					/>
					<Flex justifyContent={"flex-end"} mt={8}>
						<FormActionsButtons />
					</Flex>
				</Flex>
			)}
		</>
	)
}

export default memo(OneDayEventsTable)
