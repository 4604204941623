import { Flex } from "@chakra-ui/react"
import { memo, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import FormActionsButtons from "../../common/Form/FormActionsButtons"
import FormikForm from "../../common/Form/FormikForm"
import AddNewButton from "../../common/AddNewButton/AddNewButton"
import { useUpdateGalleryMutation } from "../../../services/api/galleryApi"
import GalleryFilterBar from "./GalleryFilterBar"
import GalleryTable from "./GalleryTable"

const GalleryForm = ({ initValues }) => {
	const [updateGallery] = useUpdateGalleryMutation()
	const [isGalleryHasData, setIsGalleyHasData] = useState(!!initValues?.length)
	const navigate = useNavigate()

	useEffect(() => {
		if (!!initValues?.length && isGalleryHasData === true) return
		if (!initValues?.length && isGalleryHasData === false) return

		setIsGalleyHasData(!!initValues?.length)
	}, [initValues?.length])

	const handleNavigateToAdd = () => navigate("/gallery/add")

	return (
		<FormikForm isPatchMethod enableReinitialize initialValues={{ gallery: initValues }} onSubmit={updateGallery}>
			<Flex justifyContent={"flex-end"} mt={{ base: 3, md: 6 }} gap={3} flexWrap={"wrap"}>
				<GalleryFilterBar />
				<AddNewButton onClick={handleNavigateToAdd} />
			</Flex>
			<GalleryTable />
			{isGalleryHasData && (
				<Flex justifyContent={"flex-end"} mt={8}>
					<FormActionsButtons />
				</Flex>
			)}
		</FormikForm>
	)
}

export default memo(GalleryForm)
