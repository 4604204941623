import { Box, Flex, Text } from "@chakra-ui/layout"
import { FastField, Field, useFormikContext } from "formik"
import { Checkbox } from "@chakra-ui/react"

import DebounceTextField from "../../../common/Form/DebounceTextField"
import DateField from "../../../common/Form/DateField"
import TimeField from "../../../common/Form/TimeField"

const OneDayEventsAddPanel = () => {
	const { isSubmitting } = useFormikContext()

	return (
		<Box
			borderRadius='4px'
			boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
			bgColor='#FFF'
			p={7}
		>
			<Flex flexDirection={"column"} gap={5}>
				<FastField name={"is_active"}>
					{() => (
						<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
							<Text
								minW={{ base: "70px", sm: "120px", lg: "150px" }}
								fontWeight={600}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								Aktywny
							</Text>
							<Field as={Checkbox} name={"is_active"} isDisabled={isSubmitting} type='checkbox' />
						</Flex>
					)}
				</FastField>
				<FastField name='in_day'>
					{() => (
						<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
							<Text
								minW={{ base: "70px", sm: "120px", lg: "150px" }}
								fontWeight={600}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								W dniu
							</Text>
							<DateField
								w={{ base: "150px", sm: "170px", md: "180px", xl: "190px" }}
								isDisabled={isSubmitting}
								name='in_day'
							/>
						</Flex>
					)}
				</FastField>
				<FastField name='hour'>
					{() => (
						<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
							<Text
								minW={{ base: "70px", sm: "120px", lg: "150px" }}
								fontWeight={600}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								Godzina
							</Text>
							<TimeField
								w={{ base: "150px", sm: "170px", md: "180px", xl: "190px" }}
								isDisabled={isSubmitting}
								name='hour'
							/>
						</Flex>
					)}
				</FastField>
				<FastField name={"is_main"}>
					{() => (
						<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
							<Text
								minW={{ base: "70px", sm: "120px", lg: "150px" }}
								fontWeight={600}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								Główne
							</Text>
							<Field as={Checkbox} name={"is_main"} isDisabled={isSubmitting} type='checkbox' />
						</Flex>
					)}
				</FastField>
				<FastField name='content'>
					{() => (
						<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
							<Text
								minW={{ base: "70px", sm: "120px", lg: "150px" }}
								fontWeight={600}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								Treść
							</Text>
							<DebounceTextField
								isDisabled={isSubmitting}
								name='content'
								w={{ base: "170px", sm: "300px", lg: "350px" }}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
								placeholder='Wpisz treść'
							/>
						</Flex>
					)}
				</FastField>
			</Flex>
		</Box>
	)
}

export default OneDayEventsAddPanel
