import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const exCathedraApi = createApi({
	reducerPath: "exCathedraApi",
	// eslint-disable-next-line no-undef
	baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_KEY}/api`, credentials: "include" }),
	tagTypes: ["ExCathedra"],
	endpoints: (builder) => ({
		getExCathedra: builder.query({
			query: () => "/exCathedra",
			keepUnusedDataFor: 0,
			providesTags: ["ExCathedra"],
		}),
		addExCathedra: builder.mutation({
			query: (data) => ({
				url: "/exCathedra",
				method: "POST",
				body: data,
			}),
		}),
		updateExCathedra: builder.mutation({
			query: (data) => ({
				url: "/exCathedra",
				method: "PATCH",
				body: data,
			}),
			invalidatesTags: ["ExCathedra"],
		}),
		getExCathedraById: builder.query({
			query: (id) => `/exCathedra/${id}`,
			keepUnusedDataFor: 0,
		}),
	}),
})

export const {
	useGetExCathedraQuery,
	useAddExCathedraMutation,
	useUpdateExCathedraMutation,
	useGetExCathedraByIdQuery,
} = exCathedraApi
