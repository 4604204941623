import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const pagesWithSubPagesApi = createApi({
	reducerPath: "pagesWithSubPagesApi",
	// eslint-disable-next-line no-undef
	baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_KEY}/api`, credentials: "include" }),
	tagTypes: ["PagesWithSubpages"],
	endpoints: (builder) => ({
		getSubpages: builder.query({
			query: () => "/contentWithSubpages",
			keepUnusedDataFor: 0,
			providesTags: ["PagesWithSubpages"],
		}),
		getSubpage: builder.query({
			query: (id) => `/contentWithSubpages/${id}`,
			keepUnusedDataFor: 0,
		}),
		updateSubPage: builder.mutation({
			query: (newSubpage) => ({
				url: "/contentWithSubpages",
				method: "PATCH",
				body: newSubpage,
			}),
		}),
		getSubpageParentMenu: builder.query({
			query: (id) => `/getParentMenu/${id}`,
			keepUnusedDataFor: 0,
		}),
	}),
})

export const { useGetSubpagesQuery, useGetSubpageQuery, useUpdateSubPageMutation, useGetSubpageParentMenuQuery } =
	pagesWithSubPagesApi
