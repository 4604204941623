import { isMobileOnly } from "react-device-detect"

import { ENDPOINTS_WITHOUT_NOTIFICATION } from "../../utils/endpointsWithoutNotification"
import { matchingHandler } from "../../utils/helpers/statusHandler"
import loadingToast from "../notification/loadingToast"

let toastIds = []

const checkIsUrlIgnore = (url) => ENDPOINTS_WITHOUT_NOTIFICATION.some((item) => item === url)

export const notificationMiddleware = () => (next) => async (action) => {
	if (isMobileOnly) return next(action)
	const notify = !checkIsUrlIgnore(action.meta?.arg?.endpointName)

	if (
		(!action.type.endsWith("pending") && !action.type.endsWith("fulfilled") && !action.type.endsWith("rejected")) ||
		!notify
	)
		return next(action)

	const result = await next(action)

	if (action.type.endsWith("pending")) {
		toastIds[action.meta.requestId] = loadingToast()
		return result
	}

	const status = action?.meta?.baseQueryMeta?.response?.status

	let message = action?.payload?.data?.message || "Zakończono pomyślnie"

	if (action.type.endsWith("rejected")) {
		message = action?.payload?.data?.data?.message || action?.meta?.baseQueryMeta?.response?.statusText
	}

	matchingHandler(status).handler({ toastId: toastIds[action.meta.requestId], message })

	delete toastIds[action.meta.requestId]

	return result
}
