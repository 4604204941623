import { ChevronDownIcon } from "@chakra-ui/icons"
import { FixedSizeList as List } from "react-window"
import { Box, Button, Flex, Input, Text, useBreakpointValue } from "@chakra-ui/react"
import { memo, useCallback, useEffect, useRef, useState } from "react"

const OptionItem = ({ index, style, data }) => {
	const { handleSelect, options } = data
	const option = options[index]

	return (
		<Button
			key={option.id}
			position='relative'
			zIndex={10}
			display='flex'
			p={0}
			justifyContent='flex-start'
			fontSize={[13, 14, 15, 16]}
			w='100%'
			minH={["25px", "30px", "32px", "33px", "35px"]}
			bgColor={"transparent"}
			_hover={{ backgroundColor: "rgba(0,0,0,0.03)" }}
			onMouseDown={() => handleSelect(option)}
			style={style}
		>
			<Box my={2} mx={3}>
				{<Text mx={2}>{option.name}</Text>}
			</Box>
		</Button>
	)
}

const Select = ({
	name,
	value,
	values,
	w = "200px",
	placeholder,
	isDisabled,
	onChange,
	iconStyles,
	handleClearInput,
}) => {
	const [options, setOptions] = useState(values || [])
	const [optionsCopy, setOptionsCopy] = useState(values || [])
	const optionsRef = useRef(null)
	const inputRef = useRef(null)
	const chevronRef = useRef(null)
	const [searchText, setSearchText] = useState("")
	const heightBreakpoints = {
		base: 150,
		sm: 150,
		md: 200,
		lg: 225,
		xl: 250,
	}
	const height = useBreakpointValue(heightBreakpoints)

	useEffect(() => {
		const newValue = values?.find((item) => item.id === value)?.name
		setSearchText(newValue || "")
	}, [value, values])

	useEffect(() => {
		if (values && optionsCopy !== values) {
			setOptions(values)
			setOptionsCopy(values)
		}
	}, [values])

	const handleOutsideClick = useCallback((event) => {
		if (
			inputRef.current &&
			!inputRef.current.contains(event.target) &&
			chevronRef.current &&
			!chevronRef.current.contains(event.target) &&
			optionsRef.current &&
			!optionsRef.current.contains(event.target)
		) {
			optionsRef.current.style.display = "none"
		}
	}, [])

	useEffect(() => {
		document.addEventListener("mousedown", handleOutsideClick)
		return () => {
			document.removeEventListener("mousedown", handleOutsideClick)
		}
	}, [handleOutsideClick])

	const clearInput = () => {
		setSearchText("")
		setOptions(optionsCopy)
		if (onChange && handleClearInput && searchText) handleClearInput(name)
		if (searchText === "") return
	}

	const handleOpenSelect = () => {
		if (isDisabled) return
		clearInput()
		optionsRef.current.style.display = optionsRef.current.style.display === "flex" ? "none" : "flex"
		if (optionsRef.current.style.display === "flex") {
			inputRef.current.focus()
		}
	}

	const handleInputClick = () => {
		if (optionsRef.current.style.display === "none") {
			clearInput()
		}
		optionsRef.current.style.display = "flex"
	}

	const handleSelect = useCallback(
		(option) => {
			setSearchText(option.name)
			optionsRef.current.style.display = "none"
			if (onChange) onChange(option)
		},
		[onChange],
	)

	const handleSearching = (event) => {
		optionsRef.current.style.display = "flex"
		setSearchText(event.target.value)
		if (event.target.value === "") return setOptions(values)
		const filteredOptions = values.filter((option) =>
			option.name.toString().toLowerCase().includes(event.target.value.toLowerCase()),
		)
		setOptions(filteredOptions)
	}

	const handleBlockSubmitValues = (e) => {
		if (e.key !== "Enter") return

		e.preventDefault()
	}

	return (
		<Box position={"relative"} w={w}>
			<Input
				isDisabled={isDisabled}
				display='inline-flex'
				position='relative'
				ref={inputRef}
				autoComplete='off'
				bgColor={"#FFF"}
				list='empty-datalist'
				value={searchText}
				w={w}
				h={["25px", "30px", "32px", "33px", "35px"]}
				pe={10}
				fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
				placeholder={placeholder}
				onChange={handleSearching}
				onClick={handleInputClick}
				onKeyDown={handleBlockSubmitValues}
			/>
			<Flex
				w={["25px", "25px", "30px"]}
				h={["25px", "30px", "32px", "33px", "35px"]}
				cursor={isDisabled ? "not-allowed" : "pointer"}
				ref={chevronRef}
				onClick={handleOpenSelect}
				zIndex={1}
				position='absolute'
				right={0}
				top={0}
				justifyContent='center'
				alignItems='center'
				style={iconStyles}
			>
				<ChevronDownIcon boxSize={{ base: 6, sm: 7, xl: 8 }} />
			</Flex>
			<Box
				display='none'
				ref={optionsRef}
				bgColor='#FFF'
				borderRadius={"6px"}
				position='absolute'
				top={["25px", "30px", "32px", "33px", "35px"]}
				w={w}
				zIndex={2}
			>
				<List
					height={options?.length > 5 ? height : options.length * 39}
					display='none'
					itemCount={options.length}
					itemSize={41}
					width={w}
					style={{
						height: options?.length > 5 ? height : "auto",
						border: "1px solid #D4D4CD",
						borderRadius: "6px",
						overflowY: options?.length > 5 ? "auto" : "hidden",
					}}
					itemData={{ options, handleSelect }}
				>
					{OptionItem}
				</List>
			</Box>
		</Box>
	)
}

export default memo(Select)
