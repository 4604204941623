import { Flex } from "@chakra-ui/layout"
import * as Yup from "yup"

import FormikForm from "../../../common/Form/FormikForm"
import FormActionsButtons from "../../../common/Form/FormActionsButtons"
import { useAddHomiliesMutation } from "../../../../services/api/homiliesApi"
import HomiliesAddBasicDetails from "./HomiliesAddBasicDetails"
import HomiliesAddSound from "./HomiliesAddSound"
import HomiliesAddSummary from "./HomiliesAddSummary"

const HomiliesAddForm = () => {
	const [addHomilie] = useAddHomiliesMutation()

	return (
		<FormikForm
			enableReinitialize
			shouldNavigate={true}
			initialValues={{
				topic: "",
				date: "",
				file: "",
				summary: "",
			}}
			validationSchema={Yup.object().shape({
				topic: Yup.string().required("Temat jest wymagany"),
				date: Yup.date()
					.min("01-01-2000", "Data nie może być mniejsza niz 01.01.2000")
					.max("12-31-2099", "Data nie może być większa niz 31.12.2099")
					.required("Data jest wymagana"),
			})}
			onSubmit={addHomilie}
		>
			<Flex flexDirection={"column"} gap={5} mt={7}>
				<HomiliesAddBasicDetails />
				<HomiliesAddSound />
				<HomiliesAddSummary />
			</Flex>
			<Flex justifyContent={"flex-end"} mt={5}>
				<FormActionsButtons />
			</Flex>
		</FormikForm>
	)
}

export default HomiliesAddForm
