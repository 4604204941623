import { AddIcon } from "@chakra-ui/icons"
import { Button } from "@chakra-ui/react"

const AddNewButton = ({ ...props }) => {
	return (
		<Button
			colorScheme='blue'
			h={["25px", "30px", "32px", "33px", "35px"]}
			fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
			fontWeight={500}
			gap={3}
			{...props}
		>
			<AddIcon />
			Dodaj nowe
		</Button>
	)
}

export default AddNewButton
