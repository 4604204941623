import { Th, Thead, Tr, useBoolean } from "@chakra-ui/react"
import { memo, useCallback } from "react"

import SortDownIcon from "../../../assets/icons/common/SortDownIcon"
import SortUpIcon from "../../../assets/icons/common/SortUpIcon"
import { useDispatch, useSelector } from "react-redux"
import { setExCathedra } from "../../../services/state/reducers/exCathedra-slice"

const ExCathedraTableHeaders = () => {
	const dispatch = useDispatch()
	const exCathedra = useSelector((store) => store.exCathedra.exCathedra)
	const [isDescSortByDate, setIsDescSortByDate] = useBoolean(true)
	const [isDescSortByNumber, setIsDescSortByNumber] = useBoolean(true)
	const [isDescSortByYear, setIsDescSortByYear] = useBoolean(true)

	const sorting = useCallback((array, key, order) => {
		return array?.toSorted((a, b) => {
			const valueA = typeof a[key] === "string" ? a[key].toLowerCase() : a[key]
			const valueB = typeof b[key] === "string" ? b[key].toLowerCase() : b[key]

			if (!order) {
				if (valueA < valueB) return -1
				if (valueA > valueB) return 1
				return 0
			}
			if (valueA > valueB) return -1
			if (valueA < valueB) return 1
			return 0
		})
	}, [])

	const handleSorting = (toggle, key, order) => {
		if (exCathedra?.length <= 1) return
		const sortedData = sorting(exCathedra, key, order)

		dispatch(setExCathedra(sortedData))
		toggle()
	}

	const getSortIcon = (isDescSort) => {
		switch (isDescSort) {
			case true:
				return <SortDownIcon ms={2} boxSize={6} color='blue.600' />
			case false:
				return <SortUpIcon ms={2} boxSize={6} color='blue.600' />
			default:
				return <SortDownIcon ms={2} boxSize={6} color='blue.600' />
		}
	}

	return (
		<Thead>
			<Tr>
				<Th
					cursor={exCathedra?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByDate.toggle, "date", isDescSortByDate)}
				>
					Data
					{getSortIcon(isDescSortByDate)}
				</Th>
				<Th
					cursor={exCathedra?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByNumber.toggle, "number", isDescSortByNumber)}
				>
					Numer
					{getSortIcon(isDescSortByNumber)}
				</Th>
				<Th
					cursor={exCathedra?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByYear.toggle, "year", setIsDescSortByYear)}
				>
					Rocznik
					{getSortIcon(isDescSortByYear)}
				</Th>
				<Th textAlign='end' fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }} borderColor={"gray.700"}>
					Edytuj
				</Th>
			</Tr>
		</Thead>
	)
}

export default memo(ExCathedraTableHeaders)
