import { Box, Button, Divider, Flex, Image, Input, Text } from "@chakra-ui/react"
import { FastField, FieldArray, useFormikContext } from "formik"
import React, { useMemo, useRef, useState } from "react"

import DebounceTextField from "../../../../common/Form/DebounceTextField"
import DebounceTextArea from "../../../../common/Form/DebounceTextArea"
import AddNewButton from "../../../../common/AddNewButton/AddNewButton"
import BinIcon from "../../../../../assets/icons/common/BinIcon"

const FooterIconsAddPanel = () => {
	const { values, isSubmitting, setFieldValue } = useFormikContext()
	const iconAddInputRef = useRef()
	const iconEditInputRef = useRef()
	const [editItemId, setEditItemId] = useState()

	const handleAddClick = () => {
		if (!iconAddInputRef) return

		iconAddInputRef.current.click()
	}

	const handleEditClick = (id) => () => {
		if (!iconEditInputRef) return

		setEditItemId(id)
		iconEditInputRef.current.click()
	}

	const emptyIconObject = useMemo(() => ({
		image: "",
		name: "",
		alt: "",
		link: "",
	}))

	const handleAddImage = (push) => (e) => {
		const file = e.target.files[0]
		if (!file || !file.type.includes("image")) return
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => {
			const iconObject = emptyIconObject
			iconObject.image = reader.result
			iconObject.name = file.name
			iconAddInputRef.current.value = null
			push(iconObject)
		}
	}

	const handleEditImage = (e) => {
		setEditItemId(null)
		const file = e.target.files[0]
		if (!file || !file.type.includes("image")) return
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => {
			iconEditInputRef.current.value = null
			setFieldValue(`icons[${editItemId}].image`, reader.result)
		}
	}

	return (
		<Box
			borderRadius='4px'
			boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
			bgColor='#FFF'
			p={7}
		>
			<FieldArray name='icons'>
				{({ push, remove }) => (
					<>
						{values?.icons.length === 0 ? (
							<Button
								bgColor={"blackAlpha.100"}
								_hover={{
									bgColor: "blackAlpha.300",
								}}
								w='100%'
								h={{ base: "200px" }}
								justifyContent={"center"}
								alignItems={"center"}
								onClick={handleAddClick}
							>
								<Text color='gray' fontSize={[20]}>
									Dodaj ikonę
								</Text>
							</Button>
						) : (
							<>
								<Flex justifyContent={"flex-end"} mb={5}>
									<AddNewButton onClick={handleAddClick} />
								</Flex>
								{values?.icons.map((item, index) => (
									<React.Fragment key={index}>
										<Flex
											gap={[5, 6]}
											alignItems={"center"}
											justifyContent={"space-evenly"}
											flexDirection={["column", "row"]}
											flexWrap={"wrap"}
										>
											<Flex flexDirection={"column"} gap={2}>
												<Box boxSize={{ base: "120px", md: "160px" }}>
													<Image w='100%' h='100%' objectFit={"contain"} src={item?.image} />
												</Box>
												<Button
													isDisabled={isSubmitting}
													fontSize={[11, 12, 13, 14, 15]}
													fontWeight={500}
													p={{ base: 0 }}
													minH='20px'
													h={["20px", "25px"]}
													colorScheme='blue'
													onClick={handleEditClick(index)}
												>
													Edytuj ikonę
												</Button>
											</Flex>
											<Flex flexDirection={"column"} gap={7} alignItems={"center"}>
												<Flex gap={4}>
													<Text
														fontSize={[13, 14, 15, 15, 16]}
														w={{ base: "100px", sm: "120px", md: "150px" }}
														maxW={{ base: "100px", sm: "120px", md: "150px" }}
													>
														Nazwa
													</Text>
													<FastField name={`icons[${index}].name`}>
														{() => (
															<DebounceTextField
																isDisabled={isSubmitting}
																name={`icons[${index}].name`}
																w={{ base: "170px", sm: "220px", md: "450px" }}
																placeholder='Wpisz nazwę'
															/>
														)}
													</FastField>
												</Flex>
												<Flex gap={4}>
													<Text
														fontSize={[13, 14, 15, 15, 16]}
														w={{ base: "100px", sm: "120px", md: "150px" }}
														maxW={{ base: "100px", sm: "120px", md: "150px" }}
													>
														Alt
													</Text>
													<FastField name={`icons[${index}].alt`}>
														{() => (
															<DebounceTextArea
																isDisabled={isSubmitting}
																name={`icons[${index}].alt`}
																w={{ base: "170px", sm: "220px", md: "450px" }}
																fontSize={[13, 14, 15, 15, 16]}
																placeholder='Wpisz alt'
															/>
														)}
													</FastField>
												</Flex>
												<Flex gap={4}>
													<Text
														fontSize={[13, 14, 15, 15, 16]}
														w={{ base: "100px", sm: "120px", md: "150px" }}
														maxW={{ base: "100px", sm: "120px", md: "150px" }}
														wordBreak={"break-word"}
													>
														Link do przekierowania
													</Text>
													<FastField name={`icons[${index}].link`}>
														{() => (
															<DebounceTextArea
																isDisabled={isSubmitting}
																name={`icons[${index}].link`}
																w={{ base: "170px", sm: "220px", md: "450px" }}
																fontSize={[13, 14, 15, 15, 16]}
																placeholder='Wpisz link'
															/>
														)}
													</FastField>
												</Flex>
											</Flex>
											<Button
												isDisabled={isSubmitting}
												p={{ base: 0 }}
												boxSize={["30px", "35px"]}
												colorScheme='gray'
												onClick={() => remove(index)}
											>
												<BinIcon color='red' boxSize={[8, 9]} />
											</Button>
										</Flex>
										<Divider
											display={
												values?.icons?.length === 0 ||
												(index === values?.icons?.length - 1 && "none")
											}
											borderWidth={["1%", "0.1rem"]}
											my={5}
										/>
									</React.Fragment>
								))}
							</>
						)}
						<Input
							ref={iconAddInputRef}
							display={"none"}
							type='file'
							accept='image/*'
							onChange={handleAddImage(push)}
						/>
					</>
				)}
			</FieldArray>
			<Input ref={iconEditInputRef} display={"none"} type='file' accept='image/*' onChange={handleEditImage} />
		</Box>
	)
}

export default FooterIconsAddPanel
