import { Flex, Text } from "@chakra-ui/layout"
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/breadcrumb"
import { ChevronRightIcon } from "@chakra-ui/icons"
import { Link } from "react-router-dom"

import AnimatedPage from "../../../../utils/animations/routes/AnimatedPage"
import NavigationButtons from "../../../../components/common/NavigationButtons/NavigationButtons"
import HomiliesAddForm from "../../../../components/management-panel/Homilies/Add/HomiliesAddForm"

const HomiliesAddPage = () => {
	return (
		<AnimatedPage>
			<Flex alignItems={"center"} gap={5}>
				<NavigationButtons />
				<Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
					<BreadcrumbItem>
						<BreadcrumbLink as={Link} to='/homilies' fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}>
							Homilie
						</BreadcrumbLink>
					</BreadcrumbItem>
					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}>Dodawanie</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>
			<Text mt={5} fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}>
				Dodawanie homilii
			</Text>
			<HomiliesAddForm />
		</AnimatedPage>
	)
}

export default HomiliesAddPage
