import { Table, TableContainer } from "@chakra-ui/react"
import { memo, useEffect, useState } from "react"
import { useSelector } from "react-redux"

import NoData from "../../../common/NoData/NoData"
import FooterIconsTableHeaders from "./FooterIconsTableHeaders"
import FooterIconsTableBody from "./FooterIconsTableBody"

const FooterIconsTable = ({ icons }) => {
	const searchingTerm = useSelector((store) => store.footer.searchingTerm)
	const [filteredIcons, setFilteredIcons] = useState(
		icons?.filter((item) => item.name.toString().toLowerCase().includes(searchingTerm.toString().toLowerCase())),
	)

	useEffect(() => {
		if (!icons) return
		const filterResults = icons?.filter((item) =>
			item.name.toString().toLowerCase().includes(searchingTerm.toString().toLowerCase()),
		)

		setFilteredIcons(filterResults)
	}, [icons, searchingTerm])

	return (
		<>
			<TableContainer mt={10}>
				<Table
					borderRadius={"4px"}
					variant='simple'
					bgColor='#FFF'
					fontSize={{ base: 11, sm: 12, md: 13, lg: 13, xl: 14 }}
				>
					<FooterIconsTableHeaders filteredIcons={filteredIcons} setFilteredIcons={setFilteredIcons} />
					{!!filteredIcons?.length && <FooterIconsTableBody filteredIcons={filteredIcons} />}
				</Table>
			</TableContainer>
			{!filteredIcons?.length && <NoData />}
		</>
	)
}

export default memo(FooterIconsTable)
