import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex } from "@chakra-ui/react"
import { useEffect } from "react"
import { ChevronRightIcon } from "@chakra-ui/icons"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"

import NavigationButtons from "../../../../components/common/NavigationButtons/NavigationButtons"
import AnimatedPage from "../../../../utils/animations/routes/AnimatedPage"
import LoaderHOC from "../../../../components/common/Loader/LoaderHOC"
import { useGetFooterIconsQuery } from "../../../../services/api/footerApi"
import FooterIconsFilterBar from "../../../../components/management-panel/PageFooter/Icons/FooterIconsFilterBar"
import AddNewButton from "../../../../components/common/AddNewButton/AddNewButton"
import FooterIconsTable from "../../../../components/management-panel/PageFooter/Icons/FooterIconsTable"
import { clearSearchingTerm } from "../../../../services/state/reducers/footer-slice"

const FooterIconsPage = () => {
	const dispatch = useDispatch()
	const { data, isLoading } = useGetFooterIconsQuery()
	const navigate = useNavigate()

	useEffect(() => {
		return () => dispatch(clearSearchingTerm())
	}, [])

	const handleNavigateToAdd = () => navigate("/footer/icons/add")

	return (
		<AnimatedPage>
			<Flex alignItems={"center"} gap={5}>
				<NavigationButtons />
				<Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
					<BreadcrumbItem>
						<BreadcrumbLink as={Link} to='/footer' fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}>
							Stopka strony
						</BreadcrumbLink>
					</BreadcrumbItem>
					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}>Ikony</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>
			<LoaderHOC isLoading={isLoading} mt={{ base: "60px", md: "140px", lg: "250px" }}>
				{data?.data?.icons && (
					<>
						<Flex justifyContent={"flex-end"} mt={{ base: 3, md: 6 }} gap={3} flexWrap={"wrap"}>
							<FooterIconsFilterBar icons={data?.data?.icons} />
							<AddNewButton onClick={handleNavigateToAdd} />
						</Flex>
						<FooterIconsTable icons={data?.data?.icons} />
					</>
				)}
			</LoaderHOC>
		</AnimatedPage>
	)
}

export default FooterIconsPage
