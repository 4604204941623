import { Flex, Image, Switch, Tbody, Td, Tr } from "@chakra-ui/react"
import { memo, useCallback } from "react"
import { useFormikContext } from "formik"
import { useLocation, useNavigate } from "react-router-dom"

import EditButton from "../../../common/EditButton/EditButton"
import DeleteButton from "../../../common/DeleteButton/DeleteButton"
import { useDeleteGalleryImageMutation } from "../../../../services/api/galleryApi"

const GalleryImagesTableBody = ({ filteredImages }) => {
	const { values, setFieldValue, isSubmitting } = useFormikContext()
	const [deleteImage] = useDeleteGalleryImageMutation()
	const location = useLocation()
	const navigate = useNavigate()

	const handleEditNavigate = (id) => {
		const splittedPath = location.pathname.split("/")
		navigate(`/${splittedPath[1]}/${splittedPath[2]}/${splittedPath[3]}/${id}`)
	}

	const getValuesAfterChangedSwitch = useCallback(
		(id) =>
			values.images.map((item) => {
				const newItem = { ...item }
				if (newItem.id !== id) {
					newItem.is_promoted = false
					return newItem
				}
				newItem.is_promoted = !newItem.is_promoted
				return newItem
			}),
		[values],
	)

	const handleChangeSwitch = (id) => () => {
		const newValues = getValuesAfterChangedSwitch(id)

		setFieldValue("images", newValues)
	}

	return (
		<Tbody>
			{filteredImages?.map((item, index) => (
				<Tr key={index}>
					<Td>
						<Image
							alt='Zdjęcie'
							src={item?.image}
							boxSize={{ base: "35px", sm: "45px", lg: "50px", xl: "60px" }}
							objectFit={"contain"}
						/>
					</Td>
					<Td>{item?.title}</Td>
					<Td>{item?.description}</Td>
					<Td>
						<Switch
							size={["md", "lg"]}
							isChecked={values?.images?.find((record) => record?.id === item?.id)?.is_promoted || false}
							onChange={handleChangeSwitch(item.id)}
						/>
					</Td>
					<Td>
						<Flex justifyContent={"flex-end"} alignItems={"center"} gap={5}>
							<EditButton onClick={() => handleEditNavigate(item?.id)} />
							<DeleteButton
								id={item?.id}
								mutation={deleteImage}
								isDisabled={isSubmitting}
								minW='21px'
								h={{ base: "25px", md: "27px", lg: "30px" }}
								w={{ base: "25px", sm: "27px", lg: "35px" }}
							/>
						</Flex>
					</Td>
				</Tr>
			))}
		</Tbody>
	)
}

export default memo(GalleryImagesTableBody)
