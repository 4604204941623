import { Button, Tooltip, useBoolean, useOutsideClick } from "@chakra-ui/react"
import { useDispatch, useSelector } from "react-redux"
import { useRef } from "react"

import { setIsActiveDragAndDrop } from "../../../services/state/reducers/pages-slice"

const DragAndDropButton = () => {
	const dispatch = useDispatch()
	const sortModeBtn = useRef()
	const isDisabled = useSelector((store) => store.pages.isDisabledDragAndDrop)
	const isActive = useSelector((store) => store.pages.isActiveDragAndDrop)
	const [isSortBtnClick, setIsSortBtnClick] = useBoolean(false)
	useOutsideClick({
		ref: sortModeBtn,
		handler: setIsSortBtnClick.off,
	})

	const handleActiveDragAndDrop = () => {
		dispatch(setIsActiveDragAndDrop(true))
	}

	return (
		<Tooltip
			isOpen={isSortBtnClick}
			hasArrow
			label={isDisabled && "Aby skorzystać z tego trybu należy wybrać menu nadrzędne oraz wyczyścić wyszukiwarke"}
			fontSize={{ base: 11, md: 12, lg: 13 }}
			bg='gray.300'
			color='black'
		>
			<Button
				ref={sortModeBtn}
				colorScheme='blue'
				h={["25px", "30px", "32px", "33px", "35px"]}
				fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
				fontWeight={500}
				isDisabled={isDisabled}
				variant={isDisabled || !isActive ? "outline" : "solid"}
				bgColor={isActive ? "blue.500" : "#FFF"}
				onMouseOver={setIsSortBtnClick.on}
				onMouseLeave={setIsSortBtnClick.off}
				onTouchStart={setIsSortBtnClick.toggle}
				onClick={handleActiveDragAndDrop}
			>
				Tryb sortowania
			</Button>
		</Tooltip>
	)
}

export default DragAndDropButton
