import { Flex, Text } from "@chakra-ui/layout"
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/breadcrumb"
import { ChevronRightIcon } from "@chakra-ui/icons"
import { Link, useParams } from "react-router-dom"

import AnimatedPage from "../../../../../utils/animations/routes/AnimatedPage"
import NavigationButtons from "../../../../../components/common/NavigationButtons/NavigationButtons"
import LoaderHOC from "../../../../../components/common/Loader/LoaderHOC"
import { useGetFooterIconQuery } from "../../../../../services/api/footerApi"
import FooterIconEditForm from "../../../../../components/management-panel/PageFooter/Icons/Edit/FooterIconEditForm"

const FooterIconsEditPage = () => {
	const { iconId } = useParams()
	const { data, isLoading } = useGetFooterIconQuery(iconId)

	return (
		<AnimatedPage>
			<Flex alignItems={"center"} gap={5}>
				<NavigationButtons />
				<Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
					<BreadcrumbItem>
						<BreadcrumbLink as={Link} to='/footer' fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}>
							Stopka strony
						</BreadcrumbLink>
					</BreadcrumbItem>
					<BreadcrumbItem>
						<BreadcrumbLink as={Link} to='/footer/icons' fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}>
							Ikony
						</BreadcrumbLink>
					</BreadcrumbItem>
					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}>
							{data?.data?.icon?.name}
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>
			<Text mt={5} fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}>
				Edycja ikony
			</Text>
			<LoaderHOC isLoading={isLoading}>
				<FooterIconEditForm initValues={data?.data?.icon} />
			</LoaderHOC>
		</AnimatedPage>
	)
}

export default FooterIconsEditPage
