import { Button, useDisclosure } from "@chakra-ui/react"
import { useFormikContext } from "formik"
import { useLocation, useNavigate } from "react-router-dom"

import CancelAlert from "../Alerts/CancelAlert"

const CancelButton = ({ ...props }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { resetForm } = useFormikContext()
	const location = useLocation()
	const navigate = useNavigate()

	const getPathToNavigate = (splittedPath) => {
		let path = ""
		splittedPath.forEach((item, index) => {
			if (index === 0 || index === splittedPath.length - 1) return
			path = `${path}/${item}`
		})
		return path
	}

	const handleCancel = () => {
		const splittedPath = location.pathname.split("/")
		if (splittedPath?.length > 2) {
			const path = getPathToNavigate(splittedPath)
			navigate(path)
			return
		}

		resetForm()
		onClose()
	}

	return (
		<>
			<Button
				colorScheme='red'
				h={["25px", "30px", "32px", "33px", "35px"]}
				fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
				fontWeight={500}
				onClick={onOpen}
				{...props}
			>
				Anuluj
			</Button>
			<CancelAlert isOpen={isOpen} onClose={onClose} handleConfirmCancel={handleCancel} />
		</>
	)
}

export default CancelButton
