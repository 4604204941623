import { Flex, Heading } from "@chakra-ui/react"

import LoginForm from "./LoginForm"

const LoginPanel = () => {
	return (
		<Flex w='100%' h='100%' justifyContent={"center"} alignItems={"center"}>
			<Flex w='350px' borderRadius={"24px"} flexDirection={"column"} bgColor={"#FFF"} p={20}>
				<Heading
					fontSize={[17, 18, 19, 20, 21]}
					fontFamily={`'EB Garamond', system-ui`}
					textAlign={"center"}
					w={{ base: "150px", sm: "160px", lg: "180px" }}
					mx='auto'
					mb={16}
				>
					Witaj w systemie Katedry Włocławek
				</Heading>
				<LoginForm />
			</Flex>
		</Flex>
	)
}

export default LoginPanel
