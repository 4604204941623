import { Box, Flex } from "@chakra-ui/react"
import LoginPanel from "../../components/auth/LoginPanel"
import Footer from "../../components/common/Footer/Footer"
import AnimatedPage from "../../utils/animations/routes/AnimatedPage"

const LoginPage = () => {
	return (
		<AnimatedPage>
			<Flex flexDirection={"column"} gap={2} h='100vh'>
				<Box h='100%' as={"main"}>
					<LoginPanel />
				</Box>
				<Footer />
			</Flex>
		</AnimatedPage>
	)
}

export default LoginPage
