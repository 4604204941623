import { Slide, toast } from "react-toastify"

const loadingToast = () => {
	return toast.loading("Proszę czekać...", {
		position: "bottom-center",
		closeOnClick: false,
		limit: 5,
		theme: "colored",
		transition: Slide,
	})
}

export default loadingToast
