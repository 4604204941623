import { Flex } from "@chakra-ui/layout"

import FormikForm from "../../../../common/Form/FormikForm"
import FormActionsButtons from "../../../../common/Form/FormActionsButtons"
import { useUpdateGalleryImageMutation } from "../../../../../services/api/galleryApi"
import GalleryImagesEdit from "./GalleryImagesEdit"

const GalleryImagesEditForm = ({ initValues }) => {
	const [updateGallery] = useUpdateGalleryImageMutation()

	return (
		<FormikForm isPatchMethod enableReinitialize shouldNavigate initialValues={initValues} onSubmit={updateGallery}>
			<Flex flexDirection={"column"} gap={5} mt={7}>
				<GalleryImagesEdit />
			</Flex>
			<Flex justifyContent={"flex-end"} mt={5}>
				<FormActionsButtons />
			</Flex>
		</FormikForm>
	)
}

export default GalleryImagesEditForm
