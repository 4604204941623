import { Box, Flex, Text } from "@chakra-ui/layout"
import { FastField, useFormikContext } from "formik"

import DebounceTextField from "../../../common/Form/DebounceTextField"
import YearField from "../../../common/Form/YearField"
import DateField from "../../../common/Form/DateField"

const ExCathedraEditBasicDetails = () => {
	const { isSubmitting } = useFormikContext()

	return (
		<Box
			borderRadius='4px'
			boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
			bgColor='#FFF'
			px={7}
			py={3}
		>
			<Flex flexDirection={"column"} gap={4}>
				<FastField name='date'>
					{() => (
						<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
							<Text
								minW={{ base: "70px", sm: "120px", lg: "150px" }}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								Data
							</Text>
							<DateField
								isDisabled={isSubmitting}
								name='date'
								w={{ base: "170px", sm: "300px", lg: "350px" }}
							/>
						</Flex>
					)}
				</FastField>
				<FastField name='number'>
					{() => (
						<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
							<Text
								minW={{ base: "70px", sm: "120px", lg: "150px" }}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								Numer
							</Text>
							<DebounceTextField
								isDisabled={isSubmitting}
								name='number'
								w={{ base: "170px", sm: "300px", lg: "350px" }}
								placeholder='Wpisz numer'
							/>
						</Flex>
					)}
				</FastField>
				<FastField name='year'>
					{() => (
						<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
							<Text
								minW={{ base: "70px", sm: "120px", lg: "150px" }}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								Rocznik
							</Text>
							<YearField
								isDisabled={isSubmitting}
								name='year'
								placeholder='Wpisz rocznik'
								w={{ base: "170px", sm: "300px", lg: "350px" }}
							/>
						</Flex>
					)}
				</FastField>
			</Flex>
		</Box>
	)
}

export default ExCathedraEditBasicDetails
