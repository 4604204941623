import React, { useState, useEffect } from "react"
import { Flex, Button, Text, Input, Select } from "@chakra-ui/react"
import { ArrowLeftIcon, ChevronLeftIcon, ArrowRightIcon, ChevronRightIcon } from "@chakra-ui/icons"

const Pagination = ({ style, array, totalRecords, page = 1, onPageChange, recordsPerPage = 10, setRecordsPerPage }) => {
	const [currentPage, setCurrentPage] = useState(page)
	const [currentPageValue, setCurrentPageValue] = useState(1)

	const totalPages = Math.ceil(totalRecords / recordsPerPage)

	useEffect(() => {
		setCurrentPage(1)
		setCurrentPageValue(1)
	}, [array])

	useEffect(() => {
		setCurrentPage(page)
		setCurrentPageValue(page)
	}, [page])

	const handleChangeInputValue = (page) => {
		setCurrentPageValue(page)
		handlePageChange(page)
	}

	const handlePageChange = (page) => {
		if (page === 0 || page > totalPages) return
		setCurrentPage(page)
		setCurrentPageValue(page)
		onPageChange(page)
	}

	const renderPageNumbers = () => {
		const pages = []
		const visiblePageCount = 5

		if (totalPages <= visiblePageCount) {
			for (let i = 1; i <= totalPages; i++) {
				pages.push(
					<Button
						key={i}
						p={0}
						minW='25px'
						w={["25px", "30px", "35px"]}
						h={["25px", "30px", "35px"]}
						bgColor={currentPage === i ? "blue.500" : "transparent"}
						color={currentPage === i ? "white" : "black"}
						borderRadius='8px'
						_hover={{
							bgColor: currentPage === i ? "blue.600" : "#FFF",
						}}
						fontSize={{ base: 11, sm: 12, xl: 13 }}
						onClick={() => handlePageChange(i)}
					>
						{i}
					</Button>,
				)
			}
		} else {
			let leftBoundary = Math.max(1, currentPage - Math.floor(visiblePageCount / 2))
			let rightBoundary = leftBoundary + visiblePageCount - 1

			if (leftBoundary <= 2) {
				leftBoundary = 1
				rightBoundary = visiblePageCount + 1
			} else if (rightBoundary >= totalPages - 1) {
				leftBoundary = totalPages - visiblePageCount
				rightBoundary = totalPages
			}

			if (leftBoundary > 1) {
				pages.push(
					<Button
						key={1}
						p={0}
						minW='25px'
						w={["25px", "30px", "35px"]}
						h={["25px", "30px", "35px"]}
						bgColor={currentPage === 1 ? "blue.500" : "transparent"}
						color={currentPage === 1 ? "white" : "black"}
						_hover={{
							bgColor: currentPage === 1 ? "blue.600" : "#FFF",
						}}
						fontSize={{ base: 11, sm: 12, xl: 13 }}
						borderRadius='8px'
						onClick={() => handlePageChange(1)}
					>
						1
					</Button>,
				)
				if (leftBoundary > 1) {
					pages.push(
						<Text
							key='ellipsis-start'
							display='flex'
							justifyContent='center'
							alignItems='center'
							w={["25px", "30px", "35px"]}
							h={["25px", "30px", "35px"]}
							color='gray.500'
							fontSize={{ base: 11, sm: 12, xl: 13 }}
						>
							...
						</Text>,
					)
				}
			}

			rightBoundary = leftBoundary === 1 ? rightBoundary - 1 : rightBoundary
			leftBoundary = rightBoundary === totalPages ? leftBoundary + 1 : leftBoundary
			for (let i = leftBoundary; i <= rightBoundary; i++) {
				pages.push(
					<Button
						key={i}
						p={0}
						minW='25px'
						w={["25px", "30px", "35px"]}
						h={["25px", "30px", "35px"]}
						bgColor={currentPage === i ? "blue.500" : "transparent"}
						color={currentPage === i ? "white" : "black"}
						_hover={{
							bgColor: currentPage === i ? "blue.600" : "#FFF",
						}}
						borderRadius={["4px", "6px", "8px"]}
						fontSize={{ base: 11, sm: 12, xl: 13 }}
						onClick={() => handlePageChange(i)}
					>
						{i}
					</Button>,
				)
			}

			if (rightBoundary < totalPages) {
				if (rightBoundary < totalPages - 1) {
					pages.push(
						<Text
							key='ellipsis-end'
							display='flex'
							justifyContent='center'
							alignItems='center'
							w={["25px", "30px", "35px"]}
							h={["25px", "30px", "35px"]}
							fontSize={{ base: 11, sm: 12, xl: 13 }}
							color='gray.500'
						>
							...
						</Text>,
					)
				}
				pages.push(
					<Button
						key={totalPages}
						p={0}
						minW='25px'
						w={["25px", "30px", "35px"]}
						h={["25px", "30px", "35px"]}
						bgColor={currentPage === totalPages ? "blue.500" : "transparent"}
						color={currentPage === totalPages ? "white" : "black"}
						_hover={{
							bgColor: currentPage === totalPages ? "blue.600" : "#FFF",
						}}
						fontSize={{ base: 11, sm: 12, xl: 13 }}
						borderRadius='8px'
						onClick={() => handlePageChange(totalPages)}
					>
						{totalPages}
					</Button>,
				)
			}
		}

		return pages
	}

	return (
		<Flex style={style} justifyContent='center' alignItems='center' flexWrap={"wrap"} rowGap={2}>
			<Flex alignItems={"center"} me={2} gap={2} w={{ base: "100%", sm: "auto" }} justifyContent={"center"}>
				<Text fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}>Pokaż</Text>
				<Select
					bgColor={"white"}
					fontSize={[12, 13, 14, 15]}
					w='auto'
					h={{ base: "25px", lg: "30px" }}
					onChange={setRecordsPerPage}
				>
					<option value='5'>5</option>
					<option value='10'>10</option>
					<option value='15'>15</option>
					<option value='25'>25</option>
					<option value='50'>50</option>
					<option value='75'>75</option>
					<option value='100'>100</option>
				</Select>
			</Flex>
			<Button
				p={0}
				minW='25px'
				w={["25px", "30px", "40px"]}
				h={["15px", "20px", "30px"]}
				bgColor='transparent'
				aria-label='First item button'
				_hover={{
					bgColor: "#FFF",
				}}
				onClick={() => handlePageChange(1)}
				isDisabled={currentPage === 1}
			>
				<ArrowLeftIcon boxSize={{ base: 4, xl: 5 }} />
			</Button>
			<Button
				p={0}
				minW='25px'
				w={["25px", "30px", "40px"]}
				h={["15px", "20px", "30px"]}
				bgColor='transparent'
				_hover={{
					bgColor: "#FFF",
				}}
				aria-label='Backward button'
				onClick={() => handlePageChange(currentPage - 1)}
				isDisabled={currentPage === 1}
			>
				<ChevronLeftIcon boxSize={{ base: 9, xl: 10 }} />
			</Button>

			{renderPageNumbers()}

			<Button
				p={0}
				minW='25px'
				w={["25px", "30px", "40px"]}
				h={["15px", "20px", "30px"]}
				bgColor='transparent'
				aria-label='Forward button'
				_hover={{
					bgColor: "#FFF",
				}}
				onClick={() => handlePageChange(currentPage + 1)}
				isDisabled={currentPage === totalPages}
			>
				<ChevronRightIcon boxSize={{ base: 9, xl: 10 }} />
			</Button>
			<Button
				p={0}
				minW='25px'
				w={["25px", "30px", "40px"]}
				h={["15px", "20px", "30px"]}
				bgColor='transparent'
				aria-label='Last item button'
				_hover={{
					bgColor: "#FFF",
				}}
				onClick={() => handlePageChange(totalPages)}
				isDisabled={currentPage === totalPages}
			>
				<ArrowRightIcon boxSize={{ base: 4, xl: 5 }} />
			</Button>
			<Flex alignItems={"center"}>
				<Text ms={2} me={2} fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}>
					Przejdź do
				</Text>
				<Input
					aria-label='Change page input'
					w={["50px", "50px", "60px"]}
					fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
					h={["25px", "30px", "30px", "35px"]}
					value={currentPageValue}
					bgColor={"#FFF"}
					onChange={(e) => handleChangeInputValue(Number(e.target.value))}
				/>
				<Text ms={2} fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}>{`z ${totalPages}`}</Text>
			</Flex>
		</Flex>
	)
}

export default Pagination
