import { createSlice } from "@reduxjs/toolkit"

const intentionsSlice = createSlice({
	name: "gallery",
	initialState: {
		recordsPerPage: null,
		currentPage: null,
		searchingTerm: null,
	},
	reducers: {
		setIntentionsData(state) {
			state.currentPage = 1
			state.recordsPerPage = 5
			state.searchingTerm = ""
		},
		clearIntentionsData(state) {
			state.recordsPerPage = null
			state.currentPage = null
			state.searchingTerm = null
		},
		setCurrentPage(state, action) {
			state.currentPage = action.payload
		},
		setSearchingTerm(state, action) {
			state.searchingTerm = action.payload
		},
		setRecordsPerPage(state, action) {
			state.recordsPerPage = action.payload
		},
	},
})

export const { setIntentionsData, clearIntentionsData, setCurrentPage, setSearchingTerm, setRecordsPerPage } =
	intentionsSlice.actions

export default intentionsSlice.reducer
