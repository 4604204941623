import { Box, Flex, Text } from "@chakra-ui/layout"
import { useFormikContext } from "formik"
import { Input } from "@chakra-ui/input"
import { Image } from "@chakra-ui/image"
import { Button } from "@chakra-ui/button"
import { useCallback, useRef, useState } from "react"

import BinIcon from "../../../../assets/icons/common/BinIcon"
import PdfIcon from "../../../../assets/icons/common/PdfIcon"
import IoEyeOutline from "../../../../assets/icons/login/IoEyeOutline"

const ExCathedraEditFiles = () => {
	const iconInputRef = useRef()
	const pdfInputRef = useRef()
	const { isSubmitting, setFieldValue, values, errors, touched } = useFormikContext()
	const [pdfFile, setPdfFile] = useState(values.pdf)

	const handleSetIcon = (e) => {
		e.preventDefault()

		const file = e.type === "drop" ? e.dataTransfer.files[0] : e.target.files[0]
		if (!file || !file.type.includes("image")) return
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => {
			setFieldValue("icon", reader.result)
		}
	}

	const handleClickAddIcon = () => {
		if (!iconInputRef) return

		iconInputRef.current.click()
	}

	const handleDeleteIcon = () => {
		if (!values.icon) return

		iconInputRef.current.value = null
		setFieldValue("icon", "")
	}

	const getIcon = () => {
		if (typeof values?.icon === "string") return values?.icon

		return URL.createObjectURL(values?.icon)
	}

	const handleClickAddPDF = () => {
		if (!pdfInputRef) return

		pdfInputRef.current.click()
	}

	const handleDeletePDF = () => {
		if (!values.pdf) return

		pdfInputRef.current.value = null
		setFieldValue("pdf", { file: "", file_name: "" })
		setPdfFile(null)
	}

	const onPDFUpload = (e) => {
		e.preventDefault()

		const file = e.type === "drop" ? e.dataTransfer.files[0] : e.target.files[0]
		if (!file || file.type !== "application/pdf") return
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => {
			setFieldValue("pdf", { file: reader.result, file_name: file.name })
			setPdfFile(file)
		}
	}

	const openPdfInNewTab = () => {
		if (!values.pdf) return
		if (values.pdf.name) {
			window.open(values.pdf.file, "_blank")
			return
		}
		const fileURL = URL.createObjectURL(pdfFile)
		window.open(fileURL, "_blank")
	}

	const bytesToKB = useCallback((bytes) => {
		if (bytes === 0) return "0 KB"

		const k = 1024
		const sizes = ["B", "KB", "MB", "GB", "TB"]

		const i = parseInt(Math.floor(Math.log(bytes) / Math.log(k)), 10)
		return `${(bytes / Math.pow(k, i)).toFixed(2)} ${sizes[i]}`
	}, [])

	return (
		<Box
			borderRadius='4px'
			boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
			bgColor='#FFF'
			px={7}
			py={3}
		>
			<Flex flexDirection={"column"} gap={4}>
				<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
					<Text
						w={{ base: "100px", sm: "120px", lg: "150px" }}
						fontWeight={600}
						fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
					>
						Ikonka
					</Text>
					<Box w='100%' onDragOver={(e) => e.preventDefault()} onDrop={handleSetIcon}>
						{values?.icon ? (
							<Flex alignItems={"center"} gap={10}>
								<Image
									alt='Ikona'
									boxSize={{ base: "30px", sm: "32px", lg: "40px" }}
									objectFit={"contain"}
									src={getIcon()}
								/>
								<Button
									p={1}
									h={{ base: "30px", sm: "32px", lg: "35px" }}
									w={{ base: "30px", sm: "32px", lg: "35px" }}
									isDisabled={isSubmitting}
									onClick={handleDeleteIcon}
								>
									<BinIcon color='red' boxSize={[8, 9]} />
								</Button>
							</Flex>
						) : (
							<Button
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
								colorScheme='blue'
								isDisabled={isSubmitting}
								onClick={handleClickAddIcon}
							>
								Dodaj ikone
							</Button>
						)}
					</Box>
					<Input ref={iconInputRef} display={"none"} type='file' accept='image/*' onChange={handleSetIcon} />
				</Flex>
				<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
					<Text
						w={{ base: "100px", sm: "120px", lg: "150px" }}
						fontWeight={600}
						fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
					>
						PDF
					</Text>
					<Flex
						w='100%'
						gap={[2, 4]}
						alignItems='center'
						fontSize={{ base: 11, sm: 12, md: 13, lg: 14, xl: 15 }}
						onDragOver={(e) => e.preventDefault()}
						onDrop={onPDFUpload}
					>
						{values?.pdf.file ? (
							<>
								<PdfIcon color='red.500' boxSize={[6, 7, 8, 9]} me={2} />
								<Flex gap={[0, 1]}>
									<Text
										_hover={{ color: "#2C7A7B" }}
										wordBreak={"break-word"}
										cursor='pointer'
										onClick={openPdfInNewTab}
									>
										{pdfFile?.file_name || pdfFile?.name}
									</Text>
									<Text color='#2C7A7B' ms={2}>
										{bytesToKB(pdfFile?.size)}
									</Text>
								</Flex>
								<IoEyeOutline ms={4} boxSize={[5, 6, 7]} cursor='pointer' onClick={openPdfInNewTab} />
								<BinIcon
									color={isSubmitting ? "gray.300" : "red.500"}
									cursor={isSubmitting ? "not-allowed" : "pointer"}
									ms={2}
									boxSize={[5, 6, 7]}
									onClick={handleDeletePDF}
								/>
							</>
						) : (
							<Box>
								<Button
									fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
									colorScheme='red'
									isDisabled={isSubmitting}
									onClick={handleClickAddPDF}
								>
									Dodaj PDF
								</Button>
								<Text fontSize={[11, 12, 13, 13, 14]} color='red.500'>
									{touched?.pdf?.file && errors?.pdf?.file}
								</Text>
							</Box>
						)}
					</Flex>
					<Input display='none' ref={pdfInputRef} type='file' accept='.pdf' onChange={onPDFUpload} />
				</Flex>
			</Flex>
		</Box>
	)
}

export default ExCathedraEditFiles
