import { Flex, Text, useBoolean } from "@chakra-ui/react"
import { Link } from "react-router-dom"

const Footer = () => {
	const [isHoverLink, setIsHoverLink] = useBoolean(false)
	return (
		<Flex as='footer' mb={5} alignItems={"flex-end"} justifyContent={"center"}>
			<Flex
				bgColor={"blue.700"}
				w='97%'
				h='70px'
				borderRadius={24}
				alignItems={"center"}
				px={10}
				justifyContent={{ base: "center", md: "space-between" }}
				flexWrap={"wrap"}
				flexDirection={{ base: "column", md: "row" }}
				gap={2}
			>
				<Text fontSize={[11, 12, 13, 14]} color='#FFF'>
					<Link to='https://katedrawloclawek.pl' target='_blank'>
						katedrawloclawek.pl
					</Link>
				</Text>
				<Flex>
					<Text textAlign={{ base: "center", md: "start" }} fontSize={[11, 12, 13, 14]} color='#FFF'>
						Copyright &copy; {new Date().getFullYear()} Katedra Włocławek | Wykonanie
						<Link
							to='https://emkaem.pl'
							target='_blank'
							style={{ marginLeft: 5, textDecoration: isHoverLink ? "underline" : "none" }}
							onMouseEnter={setIsHoverLink.on}
							onMouseLeave={setIsHoverLink.off}
						>
							MKM - Kamil Maciejewski
						</Link>
					</Text>
				</Flex>
			</Flex>
		</Flex>
	)
}

export default Footer
