import { Th, Thead, Tr, useBoolean } from "@chakra-ui/react"
import { memo, useCallback } from "react"

import SortDownIcon from "../../../../assets/icons/common/SortDownIcon"
import SortUpIcon from "../../../../assets/icons/common/SortUpIcon"
import { useFormikContext } from "formik"

const GalleryImagesTableHeaders = ({ filteredImagesLength }) => {
	const { values, setFieldValue } = useFormikContext()
	const [isDescSortByTitle, setIsDescSortByTitle] = useBoolean(true)
	const [isDescSortByDescription, setIsDescSortByDescription] = useBoolean(true)
	const [isDescSortByPromoted, setIsDescSortByPromoted] = useBoolean(true)

	const sorting = useCallback((array, key, order) => {
		return array?.toSorted((a, b) => {
			const valueA = a[key].toString().toLowerCase()
			const valueB = b[key].toString().toLowerCase()

			if (!order) {
				if (valueA < valueB) return -1
				if (valueA > valueB) return 1
				return 0
			}
			if (valueA > valueB) return -1
			if (valueA < valueB) return 1
			return 0
		})
	}, [])

	const handleSorting = (toggle, key, order) => {
		if (values?.images?.length <= 1) return
		const sortedData = sorting(values?.images, key, order)

		setFieldValue("images", sortedData)

		toggle()
	}

	const getSortIcon = (isDescSort) => {
		switch (isDescSort) {
			case true:
				return <SortDownIcon ms={2} boxSize={6} color='blue.600' />
			case false:
				return <SortUpIcon ms={2} boxSize={6} color='blue.600' />
			default:
				return <SortDownIcon ms={2} boxSize={6} color='blue.600' />
		}
	}

	return (
		<Thead>
			<Tr>
				<Th borderColor={"gray.700"}></Th>
				<Th
					cursor={filteredImagesLength > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByTitle.toggle, "title", isDescSortByTitle)}
				>
					Tytuł
					{getSortIcon(isDescSortByTitle)}
				</Th>
				<Th
					cursor={filteredImagesLength > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() =>
						handleSorting(setIsDescSortByDescription.toggle, "description", isDescSortByDescription)
					}
				>
					Opis
					{getSortIcon(isDescSortByDescription)}
				</Th>
				<Th
					cursor={filteredImagesLength > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByPromoted.toggle, "is_promoted", isDescSortByPromoted)}
				>
					Promuj
					{getSortIcon(isDescSortByPromoted)}
				</Th>
				<Th borderColor={"gray.700"}></Th>
			</Tr>
		</Thead>
	)
}

export default memo(GalleryImagesTableHeaders)
