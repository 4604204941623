import { createSlice } from "@reduxjs/toolkit"

const oneDayEventsSlice = createSlice({
	name: "oneDayEvents",
	initialState: {
		recordsPerPage: null,
		currentPage: null,
		searchingTerm: null,
	},
	reducers: {
		setOneDayEventsData(state) {
			state.currentPage = 1
			state.recordsPerPage = 5
			state.searchingTerm = ""
		},
		clearOneDayEventsData(state) {
			state.recordsPerPage = null
			state.currentPage = null
			state.searchingTerm = null
		},
		setCurrentPage(state, action) {
			state.currentPage = action.payload
		},
		setSearchingTerm(state, action) {
			state.searchingTerm = action.payload
		},
		setRecordsPerPage(state, action) {
			state.recordsPerPage = action.payload
		},
	},
})

export const { setOneDayEventsData, clearOneDayEventsData, setCurrentPage, setSearchingTerm, setRecordsPerPage } =
	oneDayEventsSlice.actions

export default oneDayEventsSlice.reducer
