import { Flex } from "@chakra-ui/react"
import { memo } from "react"
import { useFormikContext } from "formik"
import { useDispatch, useSelector } from "react-redux"

import SearchInput from "../../common/SearchInput/SearchInput"
import { setSearchingTerm } from "../../../services/state/reducers/gallery-slice"

const GalleryFilterBar = () => {
	const dispatch = useDispatch()
	const { values } = useFormikContext()
	const searchingTerm = useSelector((store) => store.gallery.searchingTerm)

	const handleSetSearchingTerm = (results) => {
		dispatch(setSearchingTerm(results))
	}

	return (
		<Flex gap={3} flexWrap={{ base: "wrap", md: "nowrap" }} justifyContent={"flex-end"}>
			<SearchInput
				searchText={searchingTerm}
				setSearchText={handleSetSearchingTerm}
				fieldsToFilter={["title"]}
				isDisabled={!values?.gallery}
				originalArray={values?.gallery}
			/>
		</Flex>
	)
}

export default memo(GalleryFilterBar)
