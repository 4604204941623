import { Th, Thead, Tr, useBoolean } from "@chakra-ui/react"
import { memo, useCallback } from "react"

import SortDownIcon from "../../../assets/icons/common/SortDownIcon"
import SortUpIcon from "../../../assets/icons/common/SortUpIcon"

const SubpagesTableHeaders = ({ subpages, setSubPages }) => {
	const [isDescSortByTitle, setIsDescSortByTitle] = useBoolean(true)
	const [isDescSortByIntroduction, setIsDescSortByIntroduction] = useBoolean(true)
	const [isDescSortByParentPage, setIsDescSortByParentPage] = useBoolean(true)
	const [isDescSortByOrder, setIsDescSortByOrder] = useBoolean(true)

	const sorting = useCallback((array, key, order) => {
		return array?.sort((a, b) => {
			const valueA = typeof a[key] === "string" ? a[key].toLowerCase() : a[key]
			const valueB = typeof b[key] === "string" ? b[key].toLowerCase() : b[key]

			if (!order) {
				if (valueA < valueB) return -1
				if (valueA > valueB) return 1
				return 0
			}
			if (valueA > valueB) return -1
			if (valueA < valueB) return 1
			return 0
		})
	}, [])

	const handleSorting = (toggle, key, order) => {
		if (subpages?.length <= 1) return
		const sortResults = sorting(subpages, key, order)

		setSubPages([...sortResults])
		toggle()
	}

	const getSortIcon = (isDescSort) => {
		switch (isDescSort) {
			case true:
				return <SortDownIcon ms={2} boxSize={6} color='blue.600' />
			case false:
				return <SortUpIcon ms={2} boxSize={6} color='blue.600' />
			default:
				return <SortDownIcon ms={2} boxSize={6} color='blue.600' />
		}
	}

	return (
		<Thead>
			<Tr>
				<Th
					cursor={subpages?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByTitle.toggle, "header", isDescSortByTitle)}
				>
					Tytuł
					{getSortIcon(isDescSortByTitle)}
				</Th>
				<Th
					cursor={subpages?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() =>
						handleSorting(setIsDescSortByIntroduction.toggle, "introduction", isDescSortByIntroduction)
					}
				>
					Wprowadzenie
					{getSortIcon(isDescSortByIntroduction)}
				</Th>
				<Th
					cursor={subpages?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByParentPage.toggle, "page_menu", isDescSortByParentPage)}
				>
					Strona w menu
					{getSortIcon(isDescSortByParentPage)}
				</Th>
				<Th
					cursor={subpages?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByOrder.toggle, "order", isDescSortByOrder)}
				>
					Kolejność
					{getSortIcon(isDescSortByOrder)}
				</Th>
				<Th textAlign='end' fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }} borderColor={"gray.700"}>
					Edytuj
				</Th>
			</Tr>
		</Thead>
	)
}

export default memo(SubpagesTableHeaders)
