import {
	Button,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerOverlay,
	Flex,
	Heading,
	Tooltip,
	useBoolean,
	useBreakpointValue,
	useOutsideClick,
} from "@chakra-ui/react"
import { DownloadIcon } from "@chakra-ui/icons"
import { useContext, useRef } from "react"

import HamburgerButton from "./HamburgerButton"
import MenuItems from "../DesktopMenu/MenuItems"
import LogOutButton from "../DesktopMenu/LogOutButton"
import { PWAContext } from "../../../App"

const MobileMenu = () => {
	const downloadBtnRef = useRef()
	const { promptEvent: pwa, appInstalled } = useContext(PWAContext)
	const [isDownloadBtnClick, setIsDownloadBtnClick] = useBoolean(false)
	const [isOpen, setIsOpen] = useBoolean()
	const headingFontSize = useBreakpointValue({
		base: 17,
		sm: 18,
		md: 19,
	})
	const menuFontSize = useBreakpointValue({
		base: 12,
		sm: 13,
		md: 14,
	})
	useOutsideClick({
		ref: downloadBtnRef,
		handler: setIsDownloadBtnClick.off,
	})

	const handleDownloadPWA = () => {
		if (!pwa) return
		pwa.prompt()
	}

	return (
		<>
			<HamburgerButton isOpen={isOpen} toggle={setIsOpen.toggle} />
			<Drawer isOpen={isOpen} placement='left' onClose={setIsOpen.toggle} size='sm'>
				<DrawerOverlay zIndex={1}>
					<DrawerContent bg='blue.600' transition='transform 100ms linear'>
						<DrawerBody>
							<Flex flexDirection={"column"} justifyContent={"space-between"} gap={10} h='100%'>
								<Flex flexDirection={"column"} mt={"70px"} overflow={"auto"}>
									<Flex gap={2}>
										<Flex
											w='100%'
											bgColor={"blue.900"}
											borderRadius={"4px"}
											justifyContent={"center"}
											alignItems={"center"}
											p={3}
										>
											<Heading
												as='h1'
												fontSize={headingFontSize}
												fontWeight={600}
												fontFamily={`'EB Garamond', system-ui`}
												color='#FFF'
											>
												Katedra Włocławek
											</Heading>
										</Flex>
										{!appInstalled && (
											<Tooltip
												isOpen={isDownloadBtnClick}
												isDisabled={pwa}
												hasArrow
												fontSize={{ base: 9, sm: 10, md: 11 }}
												aria-label='Download button tooltip'
												label={"Twoja przeglądarka nie wspiera tej funkcjonalności"}
											>
												<Button
													ref={downloadBtnRef}
													isDisabled={!pwa}
													p={0}
													m={0}
													w={{ base: "35px", sm: "40px" }}
													h='100%'
													bgColor={"blue.700"}
													_hover={{
														bgColor: "blue.800",
													}}
													fontSize={{ lg: 18, xl: 19 }}
													fontWeight={500}
													color='#FFF'
													_focusVisible={{
														boxShadow: "none",
													}}
													onTouchStart={() => !pwa && setIsDownloadBtnClick.toggle()}
													onClick={handleDownloadPWA}
												>
													<DownloadIcon mx='auto' boxSize={{ base: 6, sm: 7 }} />
												</Button>
											</Tooltip>
										)}
									</Flex>
									<MenuItems gap={{ base: 4, md: 5 }} mt={8} fontSize={menuFontSize} />
								</Flex>
								<LogOutButton boxSize={[5, 6]} fontSize={[12, 13]} minH='30px' h={"35px"} />
							</Flex>
						</DrawerBody>
					</DrawerContent>
				</DrawerOverlay>
			</Drawer>
		</>
	)
}

export default MobileMenu
