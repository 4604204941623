import { Button, Flex } from "@chakra-ui/react"
import * as Yup from "yup"

import FormikForm from "../common/Form/FormikForm"
import { useLoginMutation } from "../../services/api/authApi"
import TextField from "../common/Form/TextField"
import PasswordField from "../common/Form/PasswordField"
import { ArrowForwardIcon } from "@chakra-ui/icons"

const LoginForm = () => {
	const [login, { isLoading, isSuccess }] = useLoginMutation()

	return (
		<FormikForm
			initialValues={{
				login: "",
				password: "",
			}}
			validationSchema={Yup.object({
				login: Yup.string().required("Login jest wymagany"),
				password: Yup.string()
					.required("Hasło jest wymagane")
					.min(8, "Hasło jest za krótkie")
					.test("has-lowercase", "Hasło musi zawierać co najmniej jedną małą literę", (value) =>
						/[a-z]/.test(value),
					)
					.test("has-uppercase", "Hasło musi zawierać co najmniej jedną wielką literę", (value) =>
						/[A-Z]/.test(value),
					)
					.test("has-special-char", "Hasło musi zawierać co najmniej jeden znak specjalny", (value) =>
						/[!@#$%^&*()\-=_+[\]{}|\\;:'",.<>/?]/.test(value),
					),
			})}
			onSubmit={login}
		>
			<Flex flexDirection={"column"} gap={4}>
				<TextField name='login' placeholder={"Login"} />
				<PasswordField name='password' placeholder={"Hasło"} />
				<Button
					type='submit'
					isDisabled={isLoading || isSuccess}
					fontSize={[13, 14, 15, 15]}
					h={{ base: "30px", lg: "35px" }}
					fontWeight={500}
					gap={2}
					w='100%'
					bgColor={"blue.700"}
					color='white'
					colorScheme='blue'
				>
					Zaloguj się
					<ArrowForwardIcon boxSize={7} />
				</Button>
			</Flex>
		</FormikForm>
	)
}

export default LoginForm
