import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex } from "@chakra-ui/react"
import { useEffect } from "react"
import { ChevronRightIcon } from "@chakra-ui/icons"
import { useDispatch } from "react-redux"

import NavigationButtons from "../../../components/common/NavigationButtons/NavigationButtons"
import AnimatedPage from "../../../utils/animations/routes/AnimatedPage"
import LoaderHOC from "../../../components/common/Loader/LoaderHOC"
import RecurringEventsForm from "../../../components/management-panel/RecurringEvents/RecurringEventsForm"
import { useGetRecurringEventsQuery } from "../../../services/api/recurringEventsApi"
import {
	clearRecurringEventsData,
	setRecurringEventsData,
} from "../../../services/state/reducers/recurringEvents-slice"

const CalendarRecurringEventsPage = () => {
	const { data, isLoading } = useGetRecurringEventsQuery()
	const dispatch = useDispatch()

	useEffect(() => {
		if (!data) return
		dispatch(setRecurringEventsData())

		return () => dispatch(clearRecurringEventsData())
	}, [data])

	return (
		<AnimatedPage>
			<Flex alignItems={"center"} gap={5}>
				<NavigationButtons />
				<Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}>
							Wydarzenia do kalendarza cykliczne
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>
			<LoaderHOC isLoading={isLoading} mt={{ base: "60px", md: "140px", lg: "250px" }}>
				{data?.data?.recurring_events && <RecurringEventsForm initValues={data?.data?.recurring_events} />}
			</LoaderHOC>
		</AnimatedPage>
	)
}

export default CalendarRecurringEventsPage
