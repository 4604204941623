import { Tbody } from "@chakra-ui/react"
import { memo } from "react"
import { useSelector } from "react-redux"

import PageTableRow from "./PageTableRow"

const PagesTableBody = ({ pages }) => {
	const parentMenus = useSelector((store) => store.pages.parentMenus)
	const isActiveDragAndDrop = useSelector((store) => store.pages.isActiveDragAndDrop)

	return (
		<Tbody>
			{pages?.map((page, index) => (
				<PageTableRow
					key={index}
					isActiveDragAndDrop={isActiveDragAndDrop}
					page={page}
					pages={pages}
					pageId={page.id}
					parentMenus={parentMenus}
				/>
			))}
		</Tbody>
	)
}

export default memo(PagesTableBody)
