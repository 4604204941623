import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex } from "@chakra-ui/react"
import { useEffect } from "react"
import { ChevronRightIcon } from "@chakra-ui/icons"
import { useDispatch, useSelector } from "react-redux"

import NavigationButtons from "../../../components/common/NavigationButtons/NavigationButtons"
import AnimatedPage from "../../../utils/animations/routes/AnimatedPage"
import PagesForm from "../../../components/management-panel/Pages/PagesForm"
import { useGetPagesQuery } from "../../../services/api/pagesApi"
import LoaderHOC from "../../../components/common/Loader/LoaderHOC"
import { clearPagesData, setPagesData } from "../../../services/state/reducers/pages-slice"

const PagesPage = () => {
	const { data, isLoading } = useGetPagesQuery()
	const dispatch = useDispatch()
	const pages = useSelector((store) => store.pages.pages)

	useEffect(() => {
		if (!data) return

		dispatch(
			setPagesData({
				pages: data?.data?.pages,
				parentMenus: data?.data?.parent_menu_names,
			}),
		)
	}, [data])

	useEffect(() => {
		return () => dispatch(clearPagesData())
	}, [])

	return (
		<AnimatedPage>
			<Flex alignItems={"center"} gap={5}>
				<NavigationButtons />
				<Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}>Strony</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>
			<LoaderHOC isLoading={isLoading} mt={{ base: "60px", md: "140px", lg: "250px" }}>
				{pages && <PagesForm />}
			</LoaderHOC>
		</AnimatedPage>
	)
}

export default PagesPage
