import { Flex } from "@chakra-ui/layout"

import FormikForm from "../../../common/Form/FormikForm"
import FormActionsButtons from "../../../common/Form/FormActionsButtons"
import { useAddRecurringEventMutation } from "../../../../services/api/recurringEventsApi"
import RecurringEventsAddPanel from "./RecurringEventsAddPanel"

const RecurringEventsAddForm = () => {
	const [addEvent] = useAddRecurringEventMutation()

	return (
		<FormikForm
			enableReinitialize
			shouldNavigate={true}
			initialValues={{
				is_active: false,
				from: "",
				to: "",
				hour: "",
				except_day: "",
				except_from: "",
				except_to: "",
				is_main: false,
				monday: false,
				tuesday: false,
				wednesday: false,
				thursday: false,
				friday: false,
				saturday: false,
				sunday: false,
				content: "",
			}}
			onSubmit={addEvent}
		>
			<Flex flexDirection={"column"} gap={5} mt={7}>
				<RecurringEventsAddPanel />
			</Flex>
			<Flex justifyContent={"flex-end"} mt={5}>
				<FormActionsButtons />
			</Flex>
		</FormikForm>
	)
}

export default RecurringEventsAddForm
