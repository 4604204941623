import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex } from "@chakra-ui/react"
import { useEffect } from "react"
import { ChevronRightIcon } from "@chakra-ui/icons"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import NavigationButtons from "../../../components/common/NavigationButtons/NavigationButtons"
import AnimatedPage from "../../../utils/animations/routes/AnimatedPage"
import LoaderHOC from "../../../components/common/Loader/LoaderHOC"
import AddNewButton from "../../../components/common/AddNewButton/AddNewButton"
import HomiliesTable from "../../../components/management-panel/Homilies/HomiliesTable"
import HomiliesFilterBar from "../../../components/management-panel/Homilies/HomiliesFilterBar"
import { useGetHomiliesQuery } from "../../../services/api/homiliesApi"
import { clearHomiliesData, setHomiliesData } from "../../../services/state/reducers/homilies-slice"

const HomiliesPage = () => {
	const dispatch = useDispatch()
	const homilies = useSelector((store) => store.homilies.homilies)
	const { data, isLoading } = useGetHomiliesQuery()
	const navigate = useNavigate()

	useEffect(() => {
		if (!data) return
		dispatch(setHomiliesData(data?.data?.homilies))

		return () => dispatch(clearHomiliesData())
	}, [data])

	const handleNavigateToAdd = () => navigate("/homilies/add")

	return (
		<AnimatedPage>
			<Flex alignItems={"center"} gap={5}>
				<NavigationButtons />
				<Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}>Homilie</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>
			<LoaderHOC isLoading={isLoading} mt={{ base: "60px", md: "140px", lg: "250px" }}>
				{homilies && (
					<>
						<Flex justifyContent={"flex-end"} mt={{ base: 3, md: 6 }} gap={3} flexWrap={"wrap"}>
							<HomiliesFilterBar />
							<AddNewButton onClick={handleNavigateToAdd} />
						</Flex>
						<HomiliesTable />
					</>
				)}
			</LoaderHOC>
		</AnimatedPage>
	)
}

export default HomiliesPage
