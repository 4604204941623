import { Table, TableContainer } from "@chakra-ui/react"
import { memo, useEffect, useState } from "react"

import NoData from "../../common/NoData/NoData"
import SubpagesTableHeaders from "./SubpagesTableHeaders"
import SubpagesTableBody from "./SubpagesTableBody"
import { useSelector } from "react-redux"

const SubpagesTable = () => {
	const subpages = useSelector((store) => store.subPages.subpages)
	const searchingTerm = useSelector((store) => store.subPages.searchingTerm)
	const showFilterValue = useSelector((store) => store.subPages.showFilterValue)
	const [filteredSubPages, setFilteredSubPages] = useState(
		subpages
			.filter((subpage) =>
				subpage?.header.toString().toLowerCase().includes(searchingTerm?.toString().toLowerCase()),
			)
			.filter((page) => (showFilterValue ? page.page_menu_id === showFilterValue : page)),
	)

	useEffect(() => {
		setFilteredSubPages(
			subpages
				.filter((subpage) =>
					subpage?.header.toString().toLowerCase().includes(searchingTerm?.toString().toLowerCase()),
				)
				.filter((page) => (showFilterValue ? page.page_menu_id === showFilterValue : page)),
		)
	}, [searchingTerm, showFilterValue])

	return (
		<>
			<TableContainer mt={10}>
				<Table
					borderRadius={"4px"}
					variant='simple'
					bgColor='#FFF'
					fontSize={{ base: 11, sm: 12, md: 13, lg: 13, xl: 14 }}
				>
					<SubpagesTableHeaders subpages={filteredSubPages} setSubPages={setFilteredSubPages} />
					{!!filteredSubPages?.length && <SubpagesTableBody subpages={filteredSubPages} />}
				</Table>
			</TableContainer>
			{!filteredSubPages?.length && <NoData />}
		</>
	)
}

export default memo(SubpagesTable)
