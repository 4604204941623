import { Table, TableContainer } from "@chakra-ui/react"
import { memo } from "react"
import { useSelector } from "react-redux"

import NoData from "../../common/NoData/NoData"
import HomiliesTableHeaders from "./HomiliesTableHeaders"
import HomiliesTableBody from "./HomiliesTableBody"

const HomiliesTable = () => {
	const homilies = useSelector((store) => store.homilies.homilies)

	return (
		<>
			<TableContainer mt={10}>
				<Table
					borderRadius={"4px"}
					variant='simple'
					bgColor='#FFF'
					fontSize={{ base: 11, sm: 12, md: 13, lg: 13, xl: 14 }}
				>
					<HomiliesTableHeaders />
					{!!homilies?.length && <HomiliesTableBody />}
				</Table>
			</TableContainer>
			{!homilies?.length && <NoData />}
		</>
	)
}

export default memo(HomiliesTable)
