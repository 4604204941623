import { Flex, Image, Tbody, Td, Tr } from "@chakra-ui/react"
import { memo } from "react"
import { useNavigate } from "react-router-dom"

import EditButton from "../../../common/EditButton/EditButton"
import DeleteButton from "../../../common/DeleteButton/DeleteButton"
import { useDeleteFooterIconMutation } from "../../../../services/api/footerApi"

const FooterIconsTableBody = ({ filteredIcons }) => {
	const [deleteIcon] = useDeleteFooterIconMutation()
	const navigate = useNavigate()

	const handleEditNavigate = (id) => navigate(`/footer/icons/${id}`)

	return (
		<Tbody>
			{filteredIcons?.map((item, index) => (
				<Tr key={index}>
					<Td>
						<Image
							src={item?.image}
							alt='Ikona stopki'
							boxSize={{ base: "35px", sm: "45px", lg: "50px", xl: "60px" }}
							objectFit={"contain"}
						/>
					</Td>
					<Td>{item?.name}</Td>
					<Td>{item?.alt}</Td>
					<Td>{item?.link}</Td>
					<Td>
						<Flex justifyContent={"flex-end"} alignItems={"center"} gap={5}>
							<EditButton onClick={() => handleEditNavigate(item?.id)} />
							<DeleteButton
								id={item?.id}
								mutation={deleteIcon}
								minW='21px'
								h={{ base: "25px", md: "27px", lg: "30px" }}
								w={{ base: "25px", sm: "27px", lg: "35px" }}
							/>
						</Flex>
					</Td>
				</Tr>
			))}
		</Tbody>
	)
}

export default memo(FooterIconsTableBody)
