import { Flex } from "@chakra-ui/layout"
import * as Yup from "yup"

import { useUpdateSubPageMutation } from "../../../../services/api/pagesWithSubPagesApi"
import FormikForm from "../../../common/Form/FormikForm"
import SubpageEditHeader from "./SubpageEditHeader"
import SubpageEditBasicDetails from "./SubpageEditBasicDetails"
import SubpageEditIntroduction from "./SubpageEditIntroduction"
import SubpageEditContent from "./SubpageEditContent"
import SubpageEditSound from "./SubpageEditSound"
import SubpageEditAssignment from "./SubpageEditAssignment"
import FormActionsButtons from "../../../common/Form/FormActionsButtons"

const SubpageEditForm = ({ initValues }) => {
	const [updatePage] = useUpdateSubPageMutation()

	return (
		<FormikForm
			isPatchMethod
			enableReinitialize
			shouldNavigate
			initialValues={initValues || {}}
			validationSchema={Yup.object().shape({
				header: Yup.string().required("Nagłówek jest wymagany"),
			})}
			onSubmit={updatePage}
		>
			<Flex flexDirection={"column"} gap={5} mt={7}>
				<SubpageEditHeader />
				<SubpageEditBasicDetails />
				<SubpageEditIntroduction />
				<SubpageEditContent />
				<SubpageEditSound />
				<SubpageEditAssignment />
			</Flex>
			<Flex justifyContent={"flex-end"} mt={5}>
				<FormActionsButtons />
			</Flex>
		</FormikForm>
	)
}

export default SubpageEditForm
