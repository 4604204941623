import { createSlice } from "@reduxjs/toolkit"

const gallerySlice = createSlice({
	name: "gallery",
	initialState: {
		searchingTerm: "",
	},
	reducers: {
		clearSearchingTerm(state) {
			state.searchingTerm = ""
		},
		setSearchingTerm(state, action) {
			state.searchingTerm = action.payload
		},
	},
})

export const { clearSearchingTerm, setSearchingTerm } = gallerySlice.actions

export default gallerySlice.reducer
