import { Table, TableContainer } from "@chakra-ui/react"
import { memo } from "react"
import { useSelector } from "react-redux"

import NoData from "../../common/NoData/NoData"
import NewsTableHeaders from "./NewsTableHeaders"
import NewsTableBody from "./NewsTableBody"

const NewsTable = () => {
	const news = useSelector((store) => store.news.news)

	return (
		<>
			<TableContainer mt={10}>
				<Table
					borderRadius={"4px"}
					variant='simple'
					bgColor='#FFF'
					fontSize={{ base: 11, sm: 12, md: 13, lg: 13, xl: 14 }}
				>
					<NewsTableHeaders />
					{!!news?.length && <NewsTableBody />}
				</Table>
			</TableContainer>
			{!news?.length && <NoData />}
		</>
	)
}

export default memo(NewsTable)
