import { Th, Thead, Tr, useBoolean } from "@chakra-ui/react"
import { memo } from "react"
import { useFormikContext } from "formik"
import { useDispatch } from "react-redux"

import SortDownIcon from "../../../assets/icons/common/SortDownIcon"
import SortUpIcon from "../../../assets/icons/common/SortUpIcon"
import { setCurrentPage } from "../../../services/state/reducers/intentions-slice"

const IntentionsTableHeaders = ({ intentions, setIntentions }) => {
	const dispatch = useDispatch()
	const { values } = useFormikContext()
	const [isDescSortByDate, setIsDescSortByDate] = useBoolean(true)
	const [isDescSortByHour, setIsDescSortByHour] = useBoolean(true)
	const [isDescSortByIntention, setIsDescSortByIntention] = useBoolean(true)
	const [isDescSortByForDead, setIsDescSortByForDead] = useBoolean(true)

	const sort = (array, key) => array?.sort((a, b) => (a[key] > b[key] ? 1 : -1))

	const sortByDate = (array, key) => array?.sort((a, b) => new Date(a[key]) - new Date(b[key]))

	const sortByTime = (array, key) =>
		array?.sort((a, b) => {
			const timeA = a[key]?.split(":") || "00:00"
			const timeB = b[key]?.split(":") || "00:00"

			if (parseInt(timeA[0]) !== parseInt(timeB[0])) {
				return parseInt(timeA[0]) - parseInt(timeB[0])
			}

			return parseInt(timeA[1]) - parseInt(timeB[1])
		})

	const getCurrentSorting = (type) => {
		switch (type) {
			case "date":
				return sortByDate
			case "time":
				return sortByTime
			default:
				return sort
		}
	}

	const handleSorting = (toggle, key, order, type) => {
		if (values?.intentions?.length <= 1) return

		const sortedData = getCurrentSorting(type)(intentions, key)

		setIntentions(order ? [...sortedData] : [...sortedData.reverse()])

		dispatch(setCurrentPage(1))

		toggle()
	}

	const getSortIcon = (isDescSort) => {
		switch (isDescSort) {
			case true:
				return <SortDownIcon ms={2} boxSize={6} color='blue.600' />
			case false:
				return <SortUpIcon ms={2} boxSize={6} color='blue.600' />
			default:
				return <SortDownIcon ms={2} boxSize={6} color='blue.600' />
		}
	}

	return (
		<Thead>
			<Tr>
				<Th
					cursor={intentions?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByDate.toggle, "date", isDescSortByDate, "date")}
				>
					Data
					{getSortIcon(isDescSortByDate)}
				</Th>
				<Th
					cursor={intentions?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByHour.toggle, "hour", isDescSortByHour, "time")}
				>
					Godzina
					{getSortIcon(isDescSortByHour)}
				</Th>
				<Th
					cursor={intentions?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByIntention.toggle, "intention", isDescSortByIntention)}
				>
					Intencja
					{getSortIcon(isDescSortByIntention)}
				</Th>
				<Th
					cursor={intentions?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByForDead.toggle, "for_dead", isDescSortByForDead)}
				>
					Za zmarłych
					{getSortIcon(isDescSortByForDead)}
				</Th>
				<Th fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }} borderColor={"gray.700"}></Th>
			</Tr>
		</Thead>
	)
}

export default memo(IntentionsTableHeaders)
