import { Flex } from "@chakra-ui/layout"

import FormikForm from "../../../../common/Form/FormikForm"
import FormActionsButtons from "../../../../common/Form/FormActionsButtons"
import { useUpdateFooterIconMutation } from "../../../../../services/api/footerApi"
import FooterIconEdit from "./FooterIconEdit"

const FooterIconEditForm = ({ initValues }) => {
	const [updateIcon] = useUpdateFooterIconMutation()

	return (
		<FormikForm
			isPatchMethod
			enableReinitialize
			shouldNavigate
			initialValues={initValues || { image: "", name: "", alt: "", link: "" }}
			onSubmit={updateIcon}
		>
			<Flex flexDirection={"column"} gap={5} mt={7}>
				<FooterIconEdit />
			</Flex>
			<Flex justifyContent={"flex-end"} mt={5}>
				<FormActionsButtons />
			</Flex>
		</FormikForm>
	)
}

export default FooterIconEditForm
