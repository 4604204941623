import { Checkbox, Flex, Tbody, Td, Tr } from "@chakra-ui/react"
import { memo } from "react"
import { FastField, Field, useFormikContext } from "formik"

import DeleteButton from "../../common/DeleteButton/DeleteButton"
import { useDeleteOneDayEventMutation } from "../../../services/api/oneDayEventsApi"
import DebounceTextField from "../../common/Form/DebounceTextField"
import DateField from "../../common/Form/DateField"
import TimeField from "../../common/Form/TimeField"

const OneDayEventsTableBody = ({ oneDayEvents }) => {
	const { values, isSubmitting } = useFormikContext()
	const [deleteEvent] = useDeleteOneDayEventMutation()

	return (
		<Tbody>
			{oneDayEvents?.map((item, index) => {
				const currentItemIndex = values?.oneDayEvents?.findIndex((event) => event?.id === item?.id)
				return (
					currentItemIndex !== undefined && (
						<Tr key={index}>
							<Td>
								<FastField name={`oneDayEvents[${currentItemIndex}].is_active`}>
									{() => (
										<Field
											as={Checkbox}
											name={`oneDayEvents[${currentItemIndex}].is_active`}
											isDisabled={isSubmitting}
											type='checkbox'
											isChecked={
												values?.oneDayEvents?.find((event) => event?.id === item?.id)
													?.is_active || false
											}
										/>
									)}
								</FastField>
							</Td>
							<Td>
								<FastField name={`oneDayEvents[${currentItemIndex}].in_day`}>
									{() => (
										<DateField
											w={{ base: "150px", sm: "170px", md: "180px", xl: "190px" }}
											isDisabled={isSubmitting}
											name={`oneDayEvents[${currentItemIndex}].in_day`}
											fontSize={{ base: 12, sm: 13, xl: 14 }}
										/>
									)}
								</FastField>
							</Td>
							<Td>
								<FastField name={`oneDayEvents[${currentItemIndex}].hour`}>
									{() => (
										<TimeField
											w={{ base: "150px", sm: "170px", md: "180px", xl: "190px" }}
											isDisabled={isSubmitting}
											name={`oneDayEvents[${currentItemIndex}].hour`}
											fontSize={{ base: 12, sm: 13, xl: 14 }}
										/>
									)}
								</FastField>
							</Td>
							<Td>
								<FastField name={`oneDayEvents[${currentItemIndex}].is_main`}>
									{() => (
										<Field
											as={Checkbox}
											name={`oneDayEvents[${currentItemIndex}].is_main`}
											isDisabled={isSubmitting}
											type='checkbox'
											isChecked={
												values?.oneDayEvents?.find((event) => event?.id === item?.id)
													?.is_main || false
											}
										/>
									)}
								</FastField>
							</Td>
							<Td>
								<FastField name={`oneDayEvents[${currentItemIndex}].content`}>
									{() => (
										<DebounceTextField
											name={`oneDayEvents[${currentItemIndex}].content`}
											isDisabled={isSubmitting}
											fontSize={{ base: 12, sm: 13, xl: 14 }}
											placeholder='Wpisz treść'
											w={{ base: "150px", sm: "170px", md: "180px", xl: "190px" }}
										/>
									)}
								</FastField>
							</Td>
							<Td>
								<Flex justifyContent={"center"}>
									<DeleteButton id={item?.id} mutation={deleteEvent} isDisabled={isSubmitting} />
								</Flex>
							</Td>
						</Tr>
					)
				)
			})}
		</Tbody>
	)
}

export default memo(OneDayEventsTableBody)
