import { Flex } from "@chakra-ui/react"
import { memo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import SearchInput from "../../common/SearchInput/SearchInput"
import { setNews } from "../../../services/state/reducers/news-slice"

const NewsFilterBar = () => {
	const dispatch = useDispatch()
	const initArray = useSelector((store) => store.news.initArray)
	const [searchingTerm, setSearchingTerm] = useState("")

	const handleSetResults = (results) => {
		dispatch(setNews(results))
	}

	return (
		<Flex gap={3} flexWrap={{ base: "wrap", md: "nowrap" }} justifyContent={"flex-end"}>
			<SearchInput
				searchText={searchingTerm}
				setSearchText={setSearchingTerm}
				fieldsToFilter={["topic"]}
				isDisabled={!initArray}
				handleSetResults={handleSetResults}
				originalArray={initArray}
			/>
		</Flex>
	)
}

export default memo(NewsFilterBar)
