import { Navigate, Outlet } from "react-router-dom"

/**
 *
 * @returns Navigate to allowed routes if user logout
 */
const LoggedOutGuard = ({ LoggedIn }) => {
	return !LoggedIn ? <Outlet /> : <Navigate to='/pages' />
}

export default LoggedOutGuard
