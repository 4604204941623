import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex } from "@chakra-ui/react"
import { useEffect } from "react"
import { ChevronRightIcon } from "@chakra-ui/icons"
import { useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"

import NavigationButtons from "../../../../components/common/NavigationButtons/NavigationButtons"
import AnimatedPage from "../../../../utils/animations/routes/AnimatedPage"
import LoaderHOC from "../../../../components/common/Loader/LoaderHOC"
import { useGetImagesQuery } from "../../../../services/api/galleryApi"
import { clearSearchingTerm } from "../../../../services/state/reducers/gallery-slice"
import GalleryImagesForm from "../../../../components/management-panel/Gallery/GalleryImages/GalleryImagesForm"

const GalleryImagesPage = () => {
	const dispatch = useDispatch()
	const { galleryId } = useParams()
	const { data, isLoading } = useGetImagesQuery(galleryId)

	useEffect(() => {
		return () => dispatch(clearSearchingTerm())
	}, [])

	return (
		<AnimatedPage>
			<Flex alignItems={"center"} gap={5}>
				<NavigationButtons />
				<Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
					<BreadcrumbItem>
						<BreadcrumbLink as={Link} to='/gallery' fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}>
							Galeria
						</BreadcrumbLink>
					</BreadcrumbItem>
					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}>Zdjęcia</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>
			<LoaderHOC isLoading={isLoading} mt={{ base: "60px", md: "140px", lg: "250px" }}>
				{data?.data?.images && <GalleryImagesForm initValues={data?.data?.images} />}
			</LoaderHOC>
		</AnimatedPage>
	)
}

export default GalleryImagesPage
