import { Flex, Text } from "@chakra-ui/layout"
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/breadcrumb"
import { ChevronRightIcon } from "@chakra-ui/icons"
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import { Link } from "react-router-dom"

import AnimatedPage from "../../../../utils/animations/routes/AnimatedPage"
import NavigationButtons from "../../../../components/common/NavigationButtons/NavigationButtons"
import LoaderHOC from "../../../../components/common/Loader/LoaderHOC"
import { useGetParentMenusQuery } from "../../../../services/api/pagesApi"
import { clearParentMenus, setParentMenus } from "../../../../services/state/reducers/pages-slice"
import PagesAddForm from "../../../../components/management-panel/Pages/Add/PagesAddForm"

const PagesAddPage = () => {
	const { data, isLoading } = useGetParentMenusQuery()
	const dispatch = useDispatch()

	useEffect(() => {
		if (!data) return

		dispatch(setParentMenus(data?.data?.parent_menu_names))

		return () => dispatch(clearParentMenus())
	}, [data])

	return (
		<AnimatedPage>
			<Flex alignItems={"center"} gap={5}>
				<NavigationButtons />
				<Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
					<BreadcrumbItem>
						<BreadcrumbLink as={Link} to='/pages' fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}>
							Strony
						</BreadcrumbLink>
					</BreadcrumbItem>
					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}>Dodawanie</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>
			<Text mt={5} fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}>
				Dodawanie strony
			</Text>
			<LoaderHOC isLoading={isLoading}>
				<PagesAddForm />
			</LoaderHOC>
		</AnimatedPage>
	)
}

export default PagesAddPage
