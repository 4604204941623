import { Flex } from "@chakra-ui/layout"
import { useParams } from "react-router-dom"

import FormikForm from "../../../../common/Form/FormikForm"
import FormActionsButtons from "../../../../common/Form/FormActionsButtons"
import { useAddGalleryImagesMutation } from "../../../../../services/api/galleryApi"
import GalleryImagesAddPanel from "./GalleryImagesAddPanel"

const GalleryImagesAddForm = () => {
	const [addGalleryImages] = useAddGalleryImagesMutation()
	const { galleryId } = useParams()

	return (
		<FormikForm
			enableReinitialize
			shouldNavigate={true}
			initialValues={{ gallery_id: galleryId, images: [] }}
			onSubmit={addGalleryImages}
		>
			<Flex flexDirection={"column"} gap={5} mt={7}>
				<GalleryImagesAddPanel />
			</Flex>
			<Flex justifyContent={"flex-end"} mt={5}>
				<FormActionsButtons />
			</Flex>
		</FormikForm>
	)
}

export default GalleryImagesAddForm
