import { Box, Flex, Text, Divider } from "@chakra-ui/layout"
import { FieldArray, useFormikContext } from "formik"
import { useRef } from "react"
import { Button, Input } from "@chakra-ui/react"

import AudioPlayer from "../../../common/AudioPlayer/AudioPlayer"
import DebounceTextField from "../../../common/Form/DebounceTextField"

const SinglePageEditSound = () => {
	const fileRef = useRef(null)
	const { values, isSubmitting } = useFormikContext()

	const handleClickAddFile = () => {
		if (!fileRef) return

		fileRef?.current?.click()
	}

	const handleAudioAdd = (push) => async (event) => {
		event.preventDefault()
		const files = event.type === "drop" ? event.dataTransfer.files : event.target.files

		if (!files || isSubmitting) return
		Array.from(files).forEach((file) => {
			if (file.type !== "audio/mpeg") return
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => {
				push({ name: file.name || "", file: reader.result })
				fileRef.current.value = null
			}
		})
	}

	return (
		<Box
			borderRadius='4px'
			boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
			bgColor='#FFF'
			px={7}
			py={3}
		>
			<FieldArray name='files'>
				{({ form, remove, push }) => (
					<Flex
						flexDirection={"column"}
						gap={values.files?.length && 10}
						onDragOver={(e) => e.preventDefault()}
						onDrop={handleAudioAdd(push)}
					>
						<Flex gap={{ base: 5, sm: 7, lg: 10 }} alignItems={"center"}>
							<Text fontWeight={600} fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}>
								Pliki
							</Text>
							<Button
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
								colorScheme='blue'
								w='auto'
								isDisabled={isSubmitting}
								onClick={handleClickAddFile}
							>
								Dodaj plik
							</Button>
						</Flex>
						<Flex flexDirection={"column"} gap={{ base: 7, lg: 5 }}>
							{form.values.files?.map((file, index) => (
								<Flex key={index} flexDirection={"column"} gap={{ base: 7, lg: 5 }}>
									<Flex gap={10} flexDirection={{ base: "column", lg: "row" }} alignItems={"center"}>
										<Flex gap={5} flexDirection={["column", "row"]} alignItems={"center"}>
											<Text
												whiteSpace={"nowrap"}
												fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
											>
												Nazwa pliku
											</Text>
											<DebounceTextField
												w={{ base: "100%", xl: "300px" }}
												name={`files[${index}].name`}
												placeholder='Dodaj nazwę'
											/>
										</Flex>
										<AudioPlayer
											isDisabled={isSubmitting}
											audioFile={file?.file}
											handleDeleteFile={() => remove(index)}
										/>
									</Flex>
									{index !== form.values.files.length - 1 && <Divider />}
								</Flex>
							))}
							<Input
								display={"none"}
								ref={fileRef}
								fontSize={{ base: 11, sm: 12, md: 13, xl: 14 }}
								h={{ base: "25px", sm: "27px", lg: "30px" }}
								type='file'
								multiple
								accept='.mp3'
								onChange={handleAudioAdd(push)}
							/>
						</Flex>
					</Flex>
				)}
			</FieldArray>
		</Box>
	)
}

export default SinglePageEditSound
