import { Outlet } from "react-router-dom"
import { Box, Flex, useMediaQuery } from "@chakra-ui/react"

import AnimatedPage from "../utils/animations/routes/AnimatedPage"
import DesktopMenu from "../components/management-panel/DesktopMenu/DesktopMenu"
import MobileMenu from "../components/management-panel/MobileMenu/MobileMenu"

const Layout = () => {
	const [isLargerThan992] = useMediaQuery("(min-width: 992px)", {
		ssr: false,
	})

	return (
		<AnimatedPage>
			<Flex position={"relative"}>
				{isLargerThan992 ? <DesktopMenu /> : <MobileMenu />}
				<Box as='main' w='100%' overflowX={"hidden"} mt={{ base: "80px", lg: 0 }} p={{ base: 4, lg: 7 }}>
					<Outlet />
				</Box>
			</Flex>
		</AnimatedPage>
	)
}

export default Layout
