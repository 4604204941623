import { Flex } from "@chakra-ui/react"
import { memo } from "react"
import { useDispatch, useSelector } from "react-redux"

import SearchInput from "../../common/SearchInput/SearchInput"
import FilterSelect from "../../common/FilterSelect/FilterSelect"
import { setSearchingTerm, setShowFilterValue } from "../../../services/state/reducers/pagesWithSubPages-slice"

const SubpagesFilterBar = () => {
	const dispatch = useDispatch()
	const pageMenus = useSelector((store) => store.subPages.pageMenus)
	const initArray = useSelector((store) => store.subPages.initArray)
	const searchingTerm = useSelector((store) => store.subPages.searchingTerm)
	const showFilterValue = useSelector((store) => store.subPages.showFilterValue)

	const handleSetSearchingResults = (result) => {
		dispatch(setSearchingTerm(result))
	}

	const handleSetFilterValue = (result) => {
		dispatch(setShowFilterValue(result))
	}

	return (
		<Flex gap={3} flexWrap={{ base: "wrap", md: "nowrap" }} justifyContent={"flex-end"}>
			<SearchInput
				searchText={searchingTerm}
				setSearchText={handleSetSearchingResults}
				isDisabled={!initArray}
				originalArray={initArray}
			/>
			<FilterSelect
				name='page_menu'
				isDisabled={!initArray}
				value={showFilterValue}
				setFilterValue={handleSetFilterValue}
				values={pageMenus || []}
				array={initArray}
				placeholder={"Strona w menu"}
			/>
		</Flex>
	)
}

export default memo(SubpagesFilterBar)
