import { Box, Flex, Text } from "@chakra-ui/layout"
import { FastField, Field, useFormikContext } from "formik"
import { Checkbox } from "@chakra-ui/react"

import DebounceTextField from "../../../common/Form/DebounceTextField"
import DateField from "../../../common/Form/DateField"
import TimeField from "../../../common/Form/TimeField"

const RecurringEventsAddPanel = () => {
	const { isSubmitting } = useFormikContext()

	return (
		<Box
			borderRadius='4px'
			boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
			bgColor='#FFF'
			p={7}
		>
			<Flex flexDirection={"column"} gap={5}>
				<FastField name={"is_active"}>
					{() => (
						<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
							<Text
								minW={{ base: "70px", sm: "120px", lg: "150px" }}
								fontWeight={600}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								Aktywny
							</Text>
							<Field as={Checkbox} name={"is_active"} isDisabled={isSubmitting} type='checkbox' />
						</Flex>
					)}
				</FastField>
				<FastField name={"from"}>
					{() => (
						<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
							<Text
								minW={{ base: "70px", sm: "120px", lg: "150px" }}
								fontWeight={600}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								Od
							</Text>
							<DateField w={{ base: "170px", lg: "200px" }} isDisabled={isSubmitting} name='from' />
						</Flex>
					)}
				</FastField>
				<FastField name={"to"}>
					{() => (
						<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
							<Text
								minW={{ base: "70px", sm: "120px", lg: "150px" }}
								fontWeight={600}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								Do
							</Text>
							<DateField w={{ base: "170px", lg: "200px" }} isDisabled={isSubmitting} name='to' />
						</Flex>
					)}
				</FastField>
				<FastField name={"hour"}>
					{() => (
						<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
							<Text
								minW={{ base: "70px", sm: "120px", lg: "150px" }}
								fontWeight={600}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								Godzina
							</Text>
							<TimeField w={{ base: "170px", lg: "200px" }} isDisabled={isSubmitting} name='hour' />
						</Flex>
					)}
				</FastField>
				<FastField name={"except_day"}>
					{() => (
						<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
							<Text
								minW={{ base: "70px", sm: "120px", lg: "150px" }}
								fontWeight={600}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								Oprócz dnia
							</Text>
							<DateField w={{ base: "170px", lg: "200px" }} isDisabled={isSubmitting} name='except_day' />
						</Flex>
					)}
				</FastField>
				<FastField name={"except_from"}>
					{() => (
						<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
							<Text
								minW={{ base: "70px", sm: "120px", lg: "150px" }}
								fontWeight={600}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								Oprócz od
							</Text>
							<DateField
								w={{ base: "170px", lg: "200px" }}
								isDisabled={isSubmitting}
								name='except_from'
							/>
						</Flex>
					)}
				</FastField>
				<FastField name={"except_to"}>
					{() => (
						<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
							<Text
								minW={{ base: "70px", sm: "120px", lg: "150px" }}
								fontWeight={600}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								Oprócz do
							</Text>
							<DateField w={{ base: "170px", lg: "200px" }} isDisabled={isSubmitting} name='except_to' />
						</Flex>
					)}
				</FastField>
				<FastField name={"is_main"}>
					{() => (
						<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
							<Text
								minW={{ base: "70px", sm: "120px", lg: "150px" }}
								fontWeight={600}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								Główne
							</Text>
							<Field as={Checkbox} name={"is_main"} isDisabled={isSubmitting} type='checkbox' />
						</Flex>
					)}
				</FastField>
				<FastField name={"monday"}>
					{() => (
						<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
							<Text
								minW={{ base: "70px", sm: "120px", lg: "150px" }}
								fontWeight={600}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								Pn
							</Text>
							<Field as={Checkbox} name={"monday"} isDisabled={isSubmitting} type='checkbox' />
						</Flex>
					)}
				</FastField>
				<FastField name={"tuesday"}>
					{() => (
						<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
							<Text
								minW={{ base: "70px", sm: "120px", lg: "150px" }}
								fontWeight={600}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								Wt
							</Text>
							<Field as={Checkbox} name={"tuesday"} isDisabled={isSubmitting} type='checkbox' />
						</Flex>
					)}
				</FastField>
				<FastField name={"wednesday"}>
					{() => (
						<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
							<Text
								minW={{ base: "70px", sm: "120px", lg: "150px" }}
								fontWeight={600}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								Sr
							</Text>
							<Field as={Checkbox} name={"wednesday"} isDisabled={isSubmitting} type='checkbox' />
						</Flex>
					)}
				</FastField>
				<FastField name={"thursday"}>
					{() => (
						<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
							<Text
								minW={{ base: "70px", sm: "120px", lg: "150px" }}
								fontWeight={600}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								Cz
							</Text>
							<Field as={Checkbox} name={"thursday"} isDisabled={isSubmitting} type='checkbox' />
						</Flex>
					)}
				</FastField>
				<FastField name={"friday"}>
					{() => (
						<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
							<Text
								minW={{ base: "70px", sm: "120px", lg: "150px" }}
								fontWeight={600}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								Pt
							</Text>
							<Field as={Checkbox} name={"friday"} isDisabled={isSubmitting} type='checkbox' />
						</Flex>
					)}
				</FastField>
				<FastField name={"saturday"}>
					{() => (
						<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
							<Text
								minW={{ base: "70px", sm: "120px", lg: "150px" }}
								fontWeight={600}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								So
							</Text>
							<Field as={Checkbox} name={"saturday"} isDisabled={isSubmitting} type='checkbox' />
						</Flex>
					)}
				</FastField>
				<FastField name={"sunday"}>
					{() => (
						<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
							<Text
								minW={{ base: "70px", sm: "120px", lg: "150px" }}
								fontWeight={600}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								Nd
							</Text>
							<Field as={Checkbox} name={"sunday"} isDisabled={isSubmitting} type='checkbox' />
						</Flex>
					)}
				</FastField>
				<FastField name='content'>
					{() => (
						<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
							<Text
								minW={{ base: "70px", sm: "120px", lg: "150px" }}
								fontWeight={600}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								Treść
							</Text>
							<DebounceTextField
								isDisabled={isSubmitting}
								name='content'
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
								w={{ base: "170px", sm: "300px", lg: "350px" }}
								placeholder='Wpisz treść'
							/>
						</Flex>
					)}
				</FastField>
			</Flex>
		</Box>
	)
}

export default RecurringEventsAddPanel
