import { Box, Flex, Text } from "@chakra-ui/layout"
import { FastField, useFormikContext } from "formik"
import { useRef } from "react"
import { Button, Input } from "@chakra-ui/react"

import AudioPlayer from "../../../common/AudioPlayer/AudioPlayer"
import DebounceTextField from "../../../common/Form/DebounceTextField"

const HomiliesAddSound = () => {
	const fileRef = useRef(null)
	const { values, isSubmitting, setFieldValue } = useFormikContext()

	const handleClickAddFile = () => {
		if (!fileRef) return

		fileRef?.current?.click()
	}

	const handleAudioAdd = async (event) => {
		event.preventDefault()

		const file = event.type === "drop" ? event.dataTransfer.files[0] : event.target.files[0]

		if (!file || isSubmitting) return
		if (file.type !== "audio/mpeg") return
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => {
			setFieldValue("file", reader.result)
			setFieldValue("file_name", file.name)
			fileRef.current.value = null
		}
	}

	const handleRemoveFile = () => {
		setFieldValue("file", "")
		setFieldValue("file_name", "")
	}

	return (
		<Box
			borderRadius='4px'
			boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
			bgColor='#FFF'
			px={7}
			py={3}
		>
			<Flex
				flexDirection={"column"}
				gap={values.file?.length && 10}
				onDragOver={(e) => e.preventDefault()}
				onDrop={handleAudioAdd}
			>
				<Flex gap={{ base: 5, sm: 7, lg: 10 }} alignItems={"center"}>
					<Text fontWeight={600} fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}>
						Plik
					</Text>
					{!values.file && (
						<Button
							fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							colorScheme='blue'
							w='auto'
							isDisabled={isSubmitting}
							onClick={handleClickAddFile}
						>
							Dodaj plik
						</Button>
					)}
				</Flex>
				<Flex flexDirection={"column"} gap={{ base: 7, lg: 5 }}>
					{values?.file_name && (
						<Flex flexDirection={"column"} gap={{ base: 7, lg: 5 }}>
							<Flex gap={10} flexDirection={{ base: "column", lg: "row" }} alignItems={"center"}>
								<FastField name='file'>
									{() => (
										<Flex gap={5} flexDirection={["column", "row"]} alignItems={"center"}>
											<Text
												whiteSpace={"nowrap"}
												fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
											>
												Nazwa pliku
											</Text>
											<DebounceTextField
												w={{ base: "100%", xl: "300px" }}
												name={`file_name`}
												placeholder='Dodaj nazwę'
											/>
										</Flex>
									)}
								</FastField>
								<FastField name='file'>
									{() => (
										<AudioPlayer
											isDisabled={isSubmitting}
											audioFile={values?.file}
											handleDeleteFile={handleRemoveFile}
										/>
									)}
								</FastField>
							</Flex>
						</Flex>
					)}
					<Input
						display={"none"}
						ref={fileRef}
						fontSize={{ base: 11, sm: 12, md: 13, xl: 14 }}
						h={{ base: "25px", sm: "27px", lg: "30px" }}
						type='file'
						accept='.mp3'
						onChange={handleAudioAdd}
					/>
				</Flex>
			</Flex>
		</Box>
	)
}

export default HomiliesAddSound
