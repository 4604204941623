import { Flex } from "@chakra-ui/react"
import { memo } from "react"
import { useDispatch, useSelector } from "react-redux"

import SearchInput from "../../../common/SearchInput/SearchInput"
import { setSearchingTerm } from "../../../../services/state/reducers/footer-slice"

const FooterIconsFilterBar = ({ icons }) => {
	const dispatch = useDispatch()
	const searchingTerm = useSelector((store) => store.footer.searchingTerm)

	const handleSetSearchingTerm = (results) => {
		dispatch(setSearchingTerm(results))
	}

	return (
		<Flex gap={3} flexWrap={{ base: "wrap", md: "nowrap" }} justifyContent={"flex-end"}>
			<SearchInput
				searchText={searchingTerm}
				setSearchText={handleSetSearchingTerm}
				fieldsToFilter={["name"]}
				isDisabled={!icons}
				originalArray={icons}
			/>
		</Flex>
	)
}

export default memo(FooterIconsFilterBar)
