import { Button, Flex, useBreakpointValue } from "@chakra-ui/react"
import { useEffect, useRef } from "react"

import BinIcon from "../../../assets/icons/common/BinIcon"

const AudioPlayer = ({ isDisabled, audioFile, handleDeleteFile }) => {
	const audioRef = useRef(null)
	const widthBreakpoints = useBreakpointValue({ base: "220px", sm: "300px" }, { ssr: false })

	useEffect(() => {
		if (!audioRef.current) return
		audioRef.current.load()
	}, [audioFile])

	return (
		<Flex
			direction='column'
			alignItems='center'
			justifyContent='center'
			fontSize={{ base: 11, sm: 12, md: 13, xl: 14 }}
		>
			<Flex gap={[5, 10]} flexDirection={["column", "row"]} alignItems='center'>
				<audio ref={audioRef} controls style={{ width: widthBreakpoints, height: "40px" }}>
					<source src={audioFile} type='audio/mpeg' />
					Your browser does not support the audio element.
				</audio>
				<Button
					isDisabled={isDisabled}
					p={1}
					h={{ base: "30px", sm: "32px", lg: "35px" }}
					w={{ base: "30px", sm: "32px", lg: "35px" }}
					onClick={handleDeleteFile}
				>
					<BinIcon color='red' boxSize={[8, 9]} />
				</Button>
			</Flex>
		</Flex>
	)
}

export default AudioPlayer
