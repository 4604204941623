import { extendTheme } from "@chakra-ui/react"

const chakraTheme = {
	config: {},
	fonts: {
		body: `'Inter', system-ui`,
	},
	styles: {
		global: {
			html: {
				fontSize: "10px",
			},
			code: {},
			body: {
				backgroundColor: "blue.50",
				WebkitFontSmoothing: "antialiased",
				MozOsxFontSmoothing: "grayscale",
			},
			breakPoints: {
				sm: "30em",
				md: "48em",
				lg: "62em",
				xl: "80em",
				"2xl": "96em",
			},
			".chakra-checkbox > span": {
				height: { base: "15px", sm: "16px", md: "17px" },
				width: { base: "15px", sm: "16px", md: "17px" },
				"div > svg": {
					fontSize: { base: 8.5, sm: 9.2 },
				},
			},
			".chakra-spinner": {
				height: {
					base: "26px !important",
					sm: "30px !important",
					md: "36px !important",
					lg: "42px !important",
					xl: "50px !important",
				},
				width: {
					base: "26px !important",
					sm: "30px !important",
					md: "36px !important",
					lg: "42px !important",
					xl: "50px !important",
				},
			},
			".chakra-radio > span": {
				boxSize: { base: "12px", sm: "13px", md: "15px" },
			},
		},
	},
}

export default extendTheme(chakraTheme)
