import { Flex } from "@chakra-ui/layout"
import * as Yup from "yup"

import FormikForm from "../../../common/Form/FormikForm"
import FormActionsButtons from "../../../common/Form/FormActionsButtons"
import { useAddGalleryMutation } from "../../../../services/api/galleryApi"
import GalleryAddBasicDetails from "./GalleryAddBasicDetails"
import GalleryAddDescription from "./GalleryAddDescription"

const GalleryAddForm = () => {
	const [addGallery] = useAddGalleryMutation()

	return (
		<FormikForm
			enableReinitialize
			shouldNavigate={true}
			initialValues={{
				title: "",
				date: "",
				description: "",
				is_visible: "",
			}}
			validationSchema={Yup.object().shape({
				title: Yup.string().required("Tytuł jest wymagany"),
				date: Yup.date()
					.min("01-01-2000", "Data nie może być mniejsza niz 01.01.2000")
					.max("12-31-2099", "Data nie może być większa niz 31.12.2099")
					.required("Data jest wymagana"),
			})}
			onSubmit={addGallery}
		>
			<Flex flexDirection={"column"} gap={5} mt={7}>
				<GalleryAddBasicDetails />
				<GalleryAddDescription />
			</Flex>
			<Flex justifyContent={"flex-end"} mt={5}>
				<FormActionsButtons />
			</Flex>
		</FormikForm>
	)
}

export default GalleryAddForm
