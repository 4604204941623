import { Flex } from "@chakra-ui/react"
import { memo, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import FormActionsButtons from "../../../common/Form/FormActionsButtons"
import FormikForm from "../../../common/Form/FormikForm"
import AddNewButton from "../../../common/AddNewButton/AddNewButton"
import { useUpdateGalleryImagesMutation } from "../../../../services/api/galleryApi"
import GalleryImagesFilterBar from "./GalleryImagesFilterBar"
import GalleryImagesTable from "./GalleryImagesTable"

const GalleryImagesForm = ({ initValues }) => {
	const [updateImages] = useUpdateGalleryImagesMutation()
	const [isImagesHasData, setIsImagesHasData] = useState(!!initValues?.length)
	const { galleryId } = useParams()
	const navigate = useNavigate()

	useEffect(() => {
		if (!!initValues?.length && isImagesHasData === true) return
		if (!initValues?.length && isImagesHasData === false) return

		setIsImagesHasData(!!initValues?.length)
	}, [initValues?.length])

	const handleNavigateToAdd = () => navigate(`/gallery/${galleryId}/images/add`)

	return (
		<FormikForm
			isPatchMethod
			enableReinitialize
			initialValues={{
				images: initValues,
			}}
			onSubmit={updateImages}
		>
			<Flex justifyContent={"flex-end"} mt={{ base: 3, md: 6 }} gap={3} flexWrap={"wrap"}>
				<GalleryImagesFilterBar />
				<AddNewButton onClick={handleNavigateToAdd} />
			</Flex>
			<GalleryImagesTable />
			{isImagesHasData && (
				<Flex justifyContent={"flex-end"} mt={8}>
					<FormActionsButtons />
				</Flex>
			)}
		</FormikForm>
	)
}

export default memo(GalleryImagesForm)
