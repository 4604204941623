import { Box, Flex, Text } from "@chakra-ui/layout"
import { FastField, Field, useFormikContext } from "formik"
import { Checkbox } from "@chakra-ui/checkbox"
import { useBreakpointValue } from "@chakra-ui/react"
import { useSelector } from "react-redux"

import Select from "../../../common/Select/Select"
import { useGetSinglePageParentMenuQuery } from "../../../../services/api/singlePagesApi"

const SinglePageEditAssignment = () => {
	const { values, isSubmitting, setFieldValue } = useFormikContext()
	const pageMenus = useSelector((store) => store.singlePages.pageMenus)
	const { data } = useGetSinglePageParentMenuQuery(values.page_menu, { skip: !values.page_menu })
	const selectWidth = useBreakpointValue({ base: "150px", sm: "200px", lg: "250px" }, { ssr: false })

	const handleSelectPageMenu = (value) => setFieldValue("page_menu", value.id)

	const handleSelectParentMenu = (value) => setFieldValue("parent_menu", value.id)

	const handleClearSelect = (name) => setFieldValue(name, "")

	return (
		<Box
			borderRadius='4px'
			boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
			bgColor='#FFF'
			px={7}
			py={3}
		>
			<Flex flexDirection={"column"} gap={4}>
				<Flex gap={{ base: 5, md: 10 }} alignItems={"center"}>
					<Text
						minW={{ base: "70px", sm: "120px", lg: "150px" }}
						w={{ base: "100px", sm: "120px", lg: "150px" }}
						fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
					>
						Strona w menu
					</Text>
					<Select
						name='page_menu'
						w={selectWidth}
						value={values.page_menu}
						values={pageMenus}
						onChange={handleSelectPageMenu}
						handleClearInput={handleClearSelect}
						placeholder='Wybierz stronę'
					/>
				</Flex>
				<Flex gap={{ base: 5, md: 10 }} alignItems={"center"}>
					<Text
						minW={{ base: "70px", sm: "120px", lg: "150px" }}
						w={{ base: "100px", sm: "120px", lg: "150px" }}
						fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
					>
						Strona nadrzędna
					</Text>
					<Select
						name='parent_menu'
						isDisabled={!data?.data?.parent_menus?.length || !values?.page_menu}
						w={selectWidth}
						value={values.parent_menu}
						values={data?.data?.parent_menus}
						onChange={handleSelectParentMenu}
						handleClearInput={handleClearSelect}
						placeholder='Wybierz stronę'
					/>
				</Flex>
				<Flex gap={{ base: 5, md: 10 }} alignItems={"center"}>
					<Text
						minW={{ base: "70px", sm: "120px", lg: "150px" }}
						w={{ base: "100px", sm: "120px", lg: "150px" }}
						fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
					>
						Widoczna na stronie
					</Text>
					<FastField name='is_visible'>
						{() => (
							<Field
								as={Checkbox}
								name='is_visible'
								isDisabled={isSubmitting}
								type='checkbox'
								isChecked={values.is_visible}
							/>
						)}
					</FastField>
				</Flex>
			</Flex>
		</Box>
	)
}

export default SinglePageEditAssignment
