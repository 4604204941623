import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex } from "@chakra-ui/react"
import { useEffect } from "react"
import { ChevronRightIcon } from "@chakra-ui/icons"
import { useDispatch, useSelector } from "react-redux"

import NavigationButtons from "../../../components/common/NavigationButtons/NavigationButtons"
import AnimatedPage from "../../../utils/animations/routes/AnimatedPage"
import LoaderHOC from "../../../components/common/Loader/LoaderHOC"
import SubpagesTable from "../../../components/management-panel/ContentWithSubpages/SubpagesTable"
import SubpagesFilterBar from "../../../components/management-panel/ContentWithSubpages/SubpagesFilterBar"
import { useGetSubpagesQuery } from "../../../services/api/pagesWithSubPagesApi"
import { clearSubPagesData, setSubPagesData } from "../../../services/state/reducers/pagesWithSubPages-slice"

const ContentWithSubpagesPage = () => {
	const { data, isLoading } = useGetSubpagesQuery()
	const dispatch = useDispatch()
	const subpages = useSelector((store) => store.subPages.subpages)

	useEffect(() => {
		if (!data) return
		dispatch(
			setSubPagesData({
				subpages: data?.data?.subpages,
				pageMenus: data?.data?.page_menus,
			}),
		)

		return () => dispatch(clearSubPagesData())
	}, [data])

	return (
		<AnimatedPage>
			<Flex alignItems={"center"} gap={5}>
				<NavigationButtons />
				<Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}>
							Treści stron z podstronami
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>
			<LoaderHOC isLoading={isLoading} mt={{ base: "60px", md: "140px", lg: "250px" }}>
				{subpages && (
					<>
						<Flex justifyContent={"flex-end"} mt={{ base: 3, md: 6 }} gap={3} flexWrap={"wrap"}>
							<SubpagesFilterBar />
						</Flex>
						<SubpagesTable />
					</>
				)}
			</LoaderHOC>
		</AnimatedPage>
	)
}

export default ContentWithSubpagesPage
