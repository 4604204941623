import { Flex, Tbody, Td, Tr } from "@chakra-ui/react"
import { memo } from "react"
import { useSelector } from "react-redux"

import EditButton from "../../common/EditButton/EditButton"
import { useLocation, useNavigate } from "react-router-dom"

const SinglePageTableBody = () => {
	const singlePages = useSelector((store) => store.singlePages.singlePages)
	const navigate = useNavigate()
	const location = useLocation()

	const handleNavigateToEdit = (id) => {
		navigate(`/${location.pathname.split("/")[1]}/${id}`)
	}

	return (
		<Tbody>
			{singlePages?.map((subpage, index) => (
				<Tr key={index}>
					<Td>{subpage?.header}</Td>
					<Td>{subpage?.introduction}</Td>
					<Td>
						<Flex justifyContent={"end"}>
							<EditButton onClick={() => handleNavigateToEdit(subpage?.id)} />
						</Flex>
					</Td>
				</Tr>
			))}
		</Tbody>
	)
}

export default memo(SinglePageTableBody)
