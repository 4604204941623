import { Box, Button, Divider, Flex, Image, Input, Text } from "@chakra-ui/react"
import { FastField, FieldArray, useFormikContext } from "formik"
import React, { useMemo, useRef, useState } from "react"

import DebounceTextField from "../../../../common/Form/DebounceTextField"
import DebounceTextArea from "../../../../common/Form/DebounceTextArea"
import AddNewButton from "../../../../common/AddNewButton/AddNewButton"
import BinIcon from "../../../../../assets/icons/common/BinIcon"

const GalleryImagesAddPanel = () => {
	const { values, isSubmitting, setFieldValue } = useFormikContext()
	const imageAddInputRef = useRef()
	const imageEditInputRef = useRef()
	const [editItemId, setEditItemId] = useState()

	const handleAddClick = () => {
		if (!imageAddInputRef) return

		imageAddInputRef.current.click()
	}

	const handleEditClick = (id) => () => {
		if (!imageEditInputRef) return

		setEditItemId(id)
		imageEditInputRef.current.click()
	}

	const emptyImageObject = useMemo(() => ({
		image: "",
		title: "",
		description: "",
		is_promoted: false,
	}))

	const handleAddImage = (push) => (e) => {
		e.preventDefault()

		const file = e.type === "drop" ? e.dataTransfer.files[0] : e.target.files[0]
		if (!file || !file.type.includes("image")) return
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => {
			const imageObject = emptyImageObject
			imageObject.image = reader.result
			imageAddInputRef.current.value = null
			push(imageObject)
		}
	}

	const handleEditImage = (e, index) => {
		e.preventDefault()

		setEditItemId(null)
		const file = e.type === "drop" ? e.dataTransfer.files[0] : e.target.files[0]
		if (!file || !file.type.includes("image")) return
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => {
			imageEditInputRef.current.value = null
			setFieldValue(`images[${index !== undefined ? index : editItemId}].image`, reader.result)
		}
	}

	return (
		<Box
			borderRadius='4px'
			boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
			bgColor='#FFF'
			p={7}
		>
			<FieldArray name='images'>
				{({ push, remove }) => (
					<>
						{values?.images.length === 0 ? (
							<Button
								bgColor={"blackAlpha.100"}
								_hover={{
									bgColor: "blackAlpha.300",
								}}
								w='100%'
								h={{ base: "200px" }}
								justifyContent={"center"}
								alignItems={"center"}
								onClick={handleAddClick}
								onDragOver={(e) => e.preventDefault()}
								onDrop={handleAddImage(push)}
							>
								<Text color='gray' fontSize={[20]}>
									Dodaj zdjęcie
								</Text>
							</Button>
						) : (
							<>
								<Flex
									justifyContent={"flex-end"}
									onDragOver={(e) => e.preventDefault()}
									onDrop={handleAddImage(push)}
								>
									<AddNewButton onClick={handleAddClick} />
								</Flex>
								{values?.images.map((item, index) => (
									<React.Fragment key={index}>
										<Flex
											gap={[5, 2]}
											alignItems={"center"}
											justifyContent={"space-evenly"}
											flexDirection={["column", "row"]}
											flexWrap={"wrap"}
										>
											<Flex
												flexDirection={"column"}
												gap={2}
												onDragOver={(e) => e.preventDefault()}
												onDrop={(e) => handleEditImage(e, index)}
											>
												<Box boxSize={{ base: "120px", md: "160px" }}>
													<Image w='100%' h='100%' objectFit={"contain"} src={item?.image} />
												</Box>
												<Button
													isDisabled={isSubmitting}
													fontSize={[11, 12, 13, 14, 15]}
													fontWeight={500}
													p={{ base: 0 }}
													minH='20px'
													h={["20px", "25px"]}
													colorScheme='blue'
													onClick={handleEditClick(index)}
												>
													Edytuj zdjęcie
												</Button>
											</Flex>
											<Flex flexDirection={"column"} gap={7} alignItems={"center"}>
												<Flex gap={4}>
													<Text fontSize={[13, 14, 15, 15, 16]}>Tytuł</Text>
													<FastField name={`images[${index}].title`}>
														{() => (
															<DebounceTextField
																isDisabled={isSubmitting}
																name={`images[${index}].title`}
																w={{ base: "170px", sm: "220px", md: "450px" }}
																placeholder='Wpisz tytuł'
															/>
														)}
													</FastField>
												</Flex>
												<Flex gap={4}>
													<Text fontSize={[13, 14, 15, 15, 16]}>Opis</Text>
													<FastField name={`images[${index}].description`}>
														{() => (
															<DebounceTextArea
																isDisabled={isSubmitting}
																name={`images[${index}].description`}
																w={{ base: "170px", sm: "220px", md: "450px" }}
																fontSize={[13, 14, 15, 15, 16]}
																placeholder='Wpisz tytuł'
															/>
														)}
													</FastField>
												</Flex>
											</Flex>
											<Button
												isDisabled={isSubmitting}
												p={{ base: 0 }}
												boxSize={["30px", "35px"]}
												colorScheme='gray'
												onClick={() => remove(index)}
											>
												<BinIcon color='red' boxSize={[8, 9]} />
											</Button>
										</Flex>
										<Divider
											display={
												values?.images?.length === 0 ||
												(index === values?.images?.length - 1 && "none")
											}
											borderWidth={["1%", "0.1rem"]}
											my={5}
										/>
									</React.Fragment>
								))}
							</>
						)}
						<Input
							ref={imageAddInputRef}
							display={"none"}
							type='file'
							accept='image/*'
							onChange={handleAddImage(push)}
						/>
					</>
				)}
			</FieldArray>
			<Input ref={imageEditInputRef} display={"none"} type='file' accept='image/*' onChange={handleEditImage} />
		</Box>
	)
}

export default GalleryImagesAddPanel
