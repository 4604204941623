import { Flex, Text } from "@chakra-ui/layout"
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/breadcrumb"
import { ChevronRightIcon } from "@chakra-ui/icons"
import { Link, useParams } from "react-router-dom"

import AnimatedPage from "../../../../../utils/animations/routes/AnimatedPage"
import NavigationButtons from "../../../../../components/common/NavigationButtons/NavigationButtons"
import LoaderHOC from "../../../../../components/common/Loader/LoaderHOC"
import GalleryImagesEditForm from "../../../../../components/management-panel/Gallery/GalleryImages/Edit/GalleryImagesEditForm"
import { useGetImageQuery } from "../../../../../services/api/galleryApi"

const GalleryImagesEditPage = () => {
	const { imageId, galleryId } = useParams()
	const { data, isLoading } = useGetImageQuery(imageId)

	return (
		<AnimatedPage>
			<Flex alignItems={"center"} gap={5}>
				<NavigationButtons />
				<Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
					<BreadcrumbItem>
						<BreadcrumbLink as={Link} to='/gallery' fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}>
							Galeria
						</BreadcrumbLink>
					</BreadcrumbItem>
					<BreadcrumbItem>
						<BreadcrumbLink
							as={Link}
							to={`/gallery/${galleryId}/images`}
							fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}
						>
							Zdjęcia
						</BreadcrumbLink>
					</BreadcrumbItem>
					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}>
							{data?.data?.images?.title}
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>
			<Text mt={5} fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}>
				Edycja zdjęcia
			</Text>
			<LoaderHOC isLoading={isLoading}>
				<GalleryImagesEditForm initValues={data?.data?.images} />
			</LoaderHOC>
		</AnimatedPage>
	)
}

export default GalleryImagesEditPage
