import { createSlice } from "@reduxjs/toolkit"

const singlePage = createSlice({
	name: "singlePage",
	initialState: {
		singlePages: null,
		initArray: null,
		pageMenus: null,
	},
	reducers: {
		setSinglePagesData(state, action) {
			state.singlePages = action.payload.singlePages
			state.initArray = action.payload.singlePages
		},
		clearSinglePagesData(state) {
			state.singlePages = null
			state.initArray = null
		},
		setSinglePages(state, action) {
			state.singlePages = action.payload
		},
		setSinglePageData(state, action) {
			state.pageMenus = action.payload
		},
		clearSinglePageData(state) {
			state.pageMenus = null
		},
	},
})

export const { setSinglePagesData, clearSinglePagesData, setSinglePages, setSinglePageData, clearSinglePageData } =
	singlePage.actions

export default singlePage.reducer
