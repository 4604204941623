import { useBreakpointValue } from "@chakra-ui/media-query"
import { debounce } from "lodash"
import JoditEditor from "jodit-react"
import { memo } from "react"

const Editor = ({ editorData, setEditorData }) => {
	const fontSizeBreakpoints = useBreakpointValue(
		{ base: "11px", sm: "12px", md: "13px", lg: "14px", xl: "15px" },
		{ ssr: false },
	)

	const config = {
		language: "pl",
		buttons: [
			"bold",
			"italic",
			"underline",
			"strikethrough",
			"fontsize",
			"brush",
			"paragraph",
			"ol",
			"ul",
			"link",
			"image",
			"video",
			"table",
			"align",
			"undo",
			"redo",
			"cut",
			"hr",
			"eraser",
			"fullsize",
			"print",
		],
		uploader: {
			insertImageAsBase64URI: true,
		},
		style: {
			fontSize: fontSizeBreakpoints,
		},
		toolbarAdaptive: false,
	}

	const handleSetEditorData = () => debounce((value) => setEditorData(value), 300)

	return <JoditEditor config={config} tabIndex={1} value={editorData} onChange={handleSetEditorData()} />
}

export default memo(Editor)
