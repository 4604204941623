import { Button, Flex, Heading, Tooltip, useBreakpointValue } from "@chakra-ui/react"
import { DownloadIcon } from "@chakra-ui/icons"
import { useContext } from "react"

import MenuItems from "./MenuItems"
import LogOutButton from "./LogOutButton"
import { PWAContext } from "../../../App"

const DesktopMenu = () => {
	const { promptEvent: pwa, appInstalled } = useContext(PWAContext)
	const fontSize = useBreakpointValue({
		lg: 14,
		xl: 16,
	})

	const handleDownloadPWA = () => {
		if (!pwa) return
		pwa.prompt()
	}

	return (
		<Flex
			as='aside'
			position={"sticky"}
			top={0}
			left={0}
			bgColor={"blue.600"}
			minW={{ lg: "250px", xl: "280px" }}
			w={{ lg: "250px", xl: "280px" }}
			h='100vh'
			gap={5}
			flexDirection={"column"}
			justifyContent={"space-between"}
			p={{ lg: 5, xl: 6 }}
		>
			<Flex flexDirection={"column"} overflow={"auto"}>
				<Flex gap={2}>
					<Flex
						w='100%'
						bgColor={"blue.900"}
						borderRadius={"4px"}
						justifyContent={"center"}
						alignItems={"center"}
						p={{ lg: 4, xl: 5 }}
					>
						<Heading
							as='h1'
							fontSize={{ lg: 18, xl: 20 }}
							fontWeight={600}
							fontFamily={"Garamond"}
							color='#FFF'
						>
							Katedra Włocławek
						</Heading>
					</Flex>
					{!appInstalled && (
						<Tooltip
							fontSize={11}
							isDisabled={pwa}
							hasArrow
							aria-label='Download button tooltip'
							label={"Twoja przeglądarka nie wspiera tej funkcjonalności"}
						>
							<Button
								isDisabled={!pwa}
								p={0}
								w={{ lg: "40px", xl: "50px" }}
								h='100%'
								bgColor={"blue.700"}
								_hover={{
									bgColor: "blue.800",
								}}
								fontSize={{ lg: 18, xl: 19 }}
								fontWeight={500}
								color='#FFF'
								onClick={handleDownloadPWA}
							>
								<DownloadIcon mx='auto' boxSize={{ lg: 7, xl: 8 }} />
							</Button>
						</Tooltip>
					)}
				</Flex>
				<MenuItems gap={{ lg: 5, xl: 6 }} mt={{ lg: 12, xl: 16 }} fontSize={fontSize} />
			</Flex>
			<Flex alignItems={"flex-end"}>
				<LogOutButton boxSize={{ lg: 6, xl: 7 }} h={{ lg: "35px", xl: "40px" }} fontSize={{ lg: 14, xl: 15 }} />
			</Flex>
		</Flex>
	)
}

export default DesktopMenu
