import { Flex } from "@chakra-ui/layout"
import * as Yup from "yup"

import FormikForm from "../../../common/Form/FormikForm"
import FormActionsButtons from "../../../common/Form/FormActionsButtons"
import { useAddIntentionMutation } from "../../../../services/api/intentionsApi"
import IntentionsAddPanel from "./IntentionsAddPanel"

const IntentionsAddForm = () => {
	const [addIntention] = useAddIntentionMutation()

	return (
		<FormikForm
			enableReinitialize
			shouldNavigate={true}
			initialValues={{ intentions: [] }}
			validationSchema={Yup.object().shape({
				intentions: Yup.array().of(
					Yup.object().shape({
						date: Yup.date()
							.min("01-01-2000", "Data nie może być mniejsza niz 01.01.2000")
							.max("12-31-2099", "Data nie może być większa niz 31.12.2099")
							.required("Data jest wymagana"),
						hour: Yup.string().required("Godzina jest wymagana"),
						intention: Yup.string().required("Intencja jest wymagana"),
					}),
				),
			})}
			onSubmit={addIntention}
		>
			<Flex flexDirection={"column"} gap={5} mt={7}>
				<IntentionsAddPanel />
			</Flex>
			<Flex justifyContent={"flex-end"} mt={5}>
				<FormActionsButtons />
			</Flex>
		</FormikForm>
	)
}

export default IntentionsAddForm
