import { Table, TableContainer } from "@chakra-ui/react"
import { memo } from "react"

import NoData from "../../common/NoData/NoData"
import SinglePageTableHeaders from "./SinglePageTableHeaders"
import SinglePageTableBody from "./SinglePageTableBody"
import { useSelector } from "react-redux"

const SinglePageTable = () => {
	const singlePages = useSelector((store) => store.singlePages.singlePages)

	return (
		<>
			<TableContainer mt={10}>
				<Table
					borderRadius={"4px"}
					variant='simple'
					bgColor='#FFF'
					fontSize={{ base: 11, sm: 12, md: 13, lg: 13, xl: 14 }}
				>
					<SinglePageTableHeaders />
					{!!singlePages?.length && <SinglePageTableBody />}
				</Table>
			</TableContainer>
			{!singlePages?.length && <NoData />}
		</>
	)
}

export default memo(SinglePageTable)
