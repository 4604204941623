import { Table, TableContainer } from "@chakra-ui/react"
import { memo } from "react"
import { useSelector } from "react-redux"
import { useFormikContext } from "formik"

import NoData from "../../common/NoData/NoData"
import GalleryTableHeaders from "./GalleryTableHeaders"
import GalleryTableBody from "./GalleryTableBody"

const PagesTable = () => {
	const { values } = useFormikContext()
	const searchingTerm = useSelector((store) => store.gallery.searchingTerm)

	const filteredGallery = values?.gallery.filter((item) =>
		item.title.toString().toLowerCase().includes(searchingTerm.toString().toLowerCase()),
	)

	return (
		<>
			<TableContainer mt={10}>
				<Table
					borderRadius={"4px"}
					variant='simple'
					bgColor='#FFF'
					fontSize={{ base: 11, sm: 12, md: 13, lg: 13, xl: 14 }}
				>
					<GalleryTableHeaders filteredGalleryLength={filteredGallery.length} />
					{!!filteredGallery?.length && <GalleryTableBody filteredGallery={filteredGallery} />}
				</Table>
			</TableContainer>
			{!filteredGallery?.length && <NoData />}
		</>
	)
}

export default memo(PagesTable)
