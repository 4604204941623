import { Flex, Text } from "@chakra-ui/layout"
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/breadcrumb"
import { ChevronRightIcon } from "@chakra-ui/icons"
import { Link } from "react-router-dom"

import AnimatedPage from "../../../utils/animations/routes/AnimatedPage"
import NavigationButtons from "../../../components/common/NavigationButtons/NavigationButtons"
import LoaderHOC from "../../../components/common/Loader/LoaderHOC"
import FooterPageEditForm from "../../../components/management-panel/PageFooter/FooterPageEditForm"
import { useGetFooterPageQuery } from "../../../services/api/footerApi"

const EditPageFooter = () => {
	const { data, isLoading } = useGetFooterPageQuery()

	return (
		<AnimatedPage>
			<Flex alignItems={"center"} gap={5}>
				<NavigationButtons />
				<Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink as={Link} to={"/footer"} fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}>
							Stopka strony
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>
			<Text mt={5} fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}>
				Edycja stopki strony
			</Text>
			<LoaderHOC isLoading={isLoading}>
				<FooterPageEditForm initValues={data?.data?.footer} />
			</LoaderHOC>
		</AnimatedPage>
	)
}

export default EditPageFooter
