import { Badge, Checkbox, Flex, Td, Tr } from "@chakra-ui/react"
import { FastField, Field, useFormikContext } from "formik"
import { useDrag, useDrop } from "react-dnd"

import DottedIcon from "../../../assets/icons/common/DottedIcon"
import DebounceTextField from "../../common/Form/DebounceTextField"
import SelectField from "../../common/Form/SelectField"
import PermissionGuard from "../../common/Permissions/PermissionGuard"
import DeleteButton from "../../common/DeleteButton/DeleteButton"
import { useDeletePageMutation } from "../../../services/api/pagesApi"
import { useDispatch } from "react-redux"
import { setPages } from "../../../services/state/reducers/pages-slice"

const PageTableRow = ({ pages, isActiveDragAndDrop, page, pageId, parentMenus }) => {
	const dispatch = useDispatch()
	const [deletePage] = useDeletePageMutation()
	const { values, isSubmitting, setFieldValue } = useFormikContext()

	const [{ isDragging }, drag] = useDrag(
		() => ({
			type: "TableRow",
			item: { id: page.id },
			collect: (monitor) => ({
				isDragging: !!monitor.isDragging(),
			}),
		}),
		[page],
	)

	const [, drop] = useDrop(
		() => ({
			accept: "TableRow",
			drop: (monitor) => {
				if (monitor.id === pageId) return
				let newPages
				const draggedItemId = monitor.id
				const draggedItemIndex = pages.findIndex((item) => item.id === draggedItemId)
				const draggedItem = { ...pages[draggedItemIndex] }

				const targetItemId = pageId
				const targetItemIndex = pages.findIndex((item) => item.id === targetItemId)
				const targetItemOrder = pages[targetItemIndex].order

				draggedItem.order = targetItemOrder

				if (draggedItemIndex > targetItemIndex) {
					newPages = pages.map((item, index) => {
						const newItem = { ...item }
						if (index >= targetItemIndex && index < draggedItemIndex) {
							newItem.order += 1
						}
						return newItem
					})
				}

				if (draggedItemIndex < targetItemIndex) {
					newPages = pages.map((item, index) => {
						const newItem = { ...item }
						if (index <= targetItemIndex && index > draggedItemIndex) {
							if (newItem.order === 1) return newItem
							newItem.order -= 1
						}
						return newItem
					})
				}

				newPages.splice(draggedItemIndex, 1)
				newPages.splice(targetItemIndex, 0, draggedItem)
				dispatch(setPages([...newPages]))

				const newValues = values.pages.map((page) => {
					const newPage = { ...page }

					newPages.forEach((item) => {
						if (item.id === page.id) newPage.order = item.order
					})
					return newPage
				})
				setFieldValue("pages", newValues)
			},
		}),
		[page],
	)

	const currentPagesIndex = values?.pages?.findIndex((item) => item?.id === page?.id)

	return (
		<Tr ref={(node) => isActiveDragAndDrop && drag(drop(node))} style={{ opacity: isDragging ? 0.4 : 1 }}>
			{isActiveDragAndDrop && (
				<Td>
					<DottedIcon color='blue.400' boxSize={{ base: 6, sm: 7, md: 8, xl: 9 }} />
				</Td>
			)}
			<Td>
				<FastField name={`pages[${currentPagesIndex}].title`}>
					{() => (
						<Flex flexDirection={"column"} gap={2}>
							<DebounceTextField
								name={`pages[${currentPagesIndex}].title`}
								isDisabled={isSubmitting}
								h={["25px", "30px", "32px", "33px", "35px"]}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
								placeholder='Wpisz tytuł'
								w={{ base: "150px", md: "170px", xl: "190px" }}
							/>
						</Flex>
					)}
				</FastField>
			</Td>
			<Td>
				<FastField name={`pages[${currentPagesIndex}].is_visible`}>
					{() => (
						<Field
							as={Checkbox}
							name={`pages[${currentPagesIndex}].is_visible`}
							isDisabled={isSubmitting}
							type='checkbox'
							isChecked={values?.pages?.find((item) => item?.id === page?.id)?.is_visible || false}
						/>
					)}
				</FastField>
			</Td>
			<Td>
				<FastField key={isActiveDragAndDrop} name={`pages[${currentPagesIndex}].parent_menu`}>
					{() => (
						<SelectField
							name={`pages[${currentPagesIndex}].parent_menu`}
							isDisabled={isSubmitting || isActiveDragAndDrop}
							w={{ base: "120px", md: "150px", xl: "160px" }}
							h={["25px", "30px", "32px", "33px", "35px"]}
							options={parentMenus}
						/>
					)}
				</FastField>
			</Td>
			<Td>
				<FastField name={`pages[${currentPagesIndex}].order`}>
					{() => (
						<Badge p={1} colorScheme='blue' fontSize={{ base: 10, md: 11, xl: 12 }}>
							{page?.order}
						</Badge>
					)}
				</FastField>
			</Td>
			<PermissionGuard>
				<Td>
					<FastField name={`pages[${currentPagesIndex}].file_name`}>
						{() => (
							<DebounceTextField
								name={`pages[${currentPagesIndex}].file_name`}
								isDisabled={isSubmitting}
								h={["25px", "30px", "32px", "33px", "35px"]}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
								placeholder='Wpisz nazwe pliku'
								w={{ base: "150px", sm: "170px", md: "180px", xl: "190px" }}
							/>
						)}
					</FastField>
				</Td>
			</PermissionGuard>
			<Td>
				<Flex justifyContent={"center"}>
					<DeleteButton id={page?.id} mutation={deletePage} isDisabled={isSubmitting} />
				</Flex>
			</Td>
		</Tr>
	)
}

export default PageTableRow
