import { createSlice } from "@reduxjs/toolkit"

const news = createSlice({
	name: "news",
	initialState: {
		news: null,
		initArray: null,
	},
	reducers: {
		setNewsData(state, action) {
			state.news = action.payload
			state.initArray = action.payload
		},
		clearNewsData(state) {
			state.news = null
			state.initArray = null
		},
		setNews(state, action) {
			state.news = action.payload
		},
	},
})

export const { setNewsData, clearNewsData, setNews } = news.actions

export default news.reducer
