import { Checkbox, Flex, Tbody, Td, Tr } from "@chakra-ui/react"
import { memo } from "react"
import { FastField, Field, useFormikContext } from "formik"

import DeleteButton from "../../common/DeleteButton/DeleteButton"
import { useDeleteRecurringEventMutation } from "../../../services/api/recurringEventsApi"
import DebounceTextField from "../../common/Form/DebounceTextField"
import DateField from "../../common/Form/DateField"
import TimeField from "../../common/Form/TimeField"

const RecurringEventsTableBody = ({ recurringEvents }) => {
	const { values, isSubmitting } = useFormikContext()
	const [deleteEvent] = useDeleteRecurringEventMutation()

	return (
		<Tbody>
			{recurringEvents?.map((item, index) => {
				const currentItemIndex = values?.recurringEvents?.findIndex((event) => event.id === item.id)
				return (
					currentItemIndex !== undefined && (
						<Tr key={index}>
							<Td>
								<FastField name={`recurringEvents[${currentItemIndex}].is_active`}>
									{() => (
										<Field
											as={Checkbox}
											name={`recurringEvents[${currentItemIndex}].is_active`}
											isDisabled={isSubmitting}
											type='checkbox'
											isChecked={
												values?.recurringEvents?.find((event) => event?.id === item?.id)
													?.is_active || false
											}
										/>
									)}
								</FastField>
							</Td>
							<Td>
								<FastField name={`recurringEvents[${currentItemIndex}].from`}>
									{() => (
										<DateField
											isDisabled={isSubmitting}
											name={`recurringEvents[${currentItemIndex}].from`}
											fontSize={{ base: 12, sm: 13, xl: 14 }}
										/>
									)}
								</FastField>
							</Td>
							<Td>
								<FastField name={`recurringEvents[${currentItemIndex}].to`}>
									{() => (
										<DateField
											isDisabled={isSubmitting}
											name={`recurringEvents[${currentItemIndex}].to`}
											fontSize={{ base: 12, sm: 13, xl: 14 }}
										/>
									)}
								</FastField>
							</Td>
							<Td>
								<FastField name={`recurringEvents[${currentItemIndex}].hour`}>
									{() => (
										<TimeField
											isDisabled={isSubmitting}
											name={`recurringEvents[${currentItemIndex}].hour`}
											fontSize={{ base: 12, sm: 13, xl: 14 }}
										/>
									)}
								</FastField>
							</Td>
							<Td>
								<FastField name={`recurringEvents[${currentItemIndex}].except_day`}>
									{() => (
										<DateField
											isDisabled={isSubmitting}
											name={`recurringEvents[${currentItemIndex}].except_day`}
											fontSize={{ base: 12, sm: 13, xl: 14 }}
										/>
									)}
								</FastField>
							</Td>
							<Td>
								<FastField name={`recurringEvents[${currentItemIndex}].except_from`}>
									{() => (
										<DateField
											isDisabled={isSubmitting}
											name={`recurringEvents[${currentItemIndex}].except_from`}
											fontSize={{ base: 12, sm: 13, xl: 14 }}
										/>
									)}
								</FastField>
							</Td>
							<Td>
								<FastField name={`recurringEvents[${currentItemIndex}].except_to`}>
									{() => (
										<DateField
											isDisabled={isSubmitting}
											name={`recurringEvents[${currentItemIndex}].except_to`}
											fontSize={{ base: 12, sm: 13, xl: 14 }}
										/>
									)}
								</FastField>
							</Td>
							<Td>
								<FastField name={`recurringEvents[${currentItemIndex}].is_main`}>
									{() => (
										<Field
											as={Checkbox}
											name={`recurringEvents[${currentItemIndex}].is_main`}
											isDisabled={isSubmitting}
											type='checkbox'
											isChecked={
												values?.recurringEvents?.find((event) => event?.id === item?.id)
													?.is_main || false
											}
										/>
									)}
								</FastField>
							</Td>
							<Td>
								<FastField name={`recurringEvents[${currentItemIndex}].monday`}>
									{() => (
										<Field
											as={Checkbox}
											name={`recurringEvents[${currentItemIndex}].monday`}
											isDisabled={isSubmitting}
											type='checkbox'
											isChecked={
												values?.recurringEvents?.find((event) => event?.id === item?.id)
													?.monday || false
											}
										/>
									)}
								</FastField>
							</Td>
							<Td>
								<FastField name={`recurringEvents[${currentItemIndex}].tuesday`}>
									{() => (
										<Field
											as={Checkbox}
											name={`recurringEvents[${currentItemIndex}].tuesday`}
											isDisabled={isSubmitting}
											type='checkbox'
											isChecked={
												values?.recurringEvents?.find((event) => event?.id === item?.id)
													?.tuesday || false
											}
										/>
									)}
								</FastField>
							</Td>
							<Td>
								<FastField name={`recurringEvents[${currentItemIndex}].wednesday`}>
									{() => (
										<Field
											as={Checkbox}
											name={`recurringEvents[${currentItemIndex}].wednesday`}
											isDisabled={isSubmitting}
											type='checkbox'
											isChecked={
												values?.recurringEvents?.find((event) => event?.id === item?.id)
													?.wednesday || false
											}
										/>
									)}
								</FastField>
							</Td>
							<Td>
								<FastField name={`recurringEvents[${currentItemIndex}].thursday`}>
									{() => (
										<Field
											as={Checkbox}
											name={`recurringEvents[${currentItemIndex}].thursday`}
											isDisabled={isSubmitting}
											type='checkbox'
											isChecked={
												values?.recurringEvents?.find((event) => event?.id === item?.id)
													?.thursday || false
											}
										/>
									)}
								</FastField>
							</Td>
							<Td>
								<FastField name={`recurringEvents[${currentItemIndex}].friday`}>
									{() => (
										<Field
											as={Checkbox}
											name={`recurringEvents[${currentItemIndex}].friday`}
											isDisabled={isSubmitting}
											type='checkbox'
											isChecked={
												values?.recurringEvents?.find((event) => event?.id === item?.id)
													?.friday || false
											}
										/>
									)}
								</FastField>
							</Td>
							<Td>
								<FastField name={`recurringEvents[${currentItemIndex}].saturday`}>
									{() => (
										<Field
											as={Checkbox}
											name={`recurringEvents[${currentItemIndex}].saturday`}
											isDisabled={isSubmitting}
											type='checkbox'
											isChecked={
												values?.recurringEvents?.find((event) => event?.id === item?.id)
													?.saturday || false
											}
										/>
									)}
								</FastField>
							</Td>
							<Td>
								<FastField name={`recurringEvents[${currentItemIndex}].sunday`}>
									{() => (
										<Field
											as={Checkbox}
											name={`recurringEvents[${currentItemIndex}].sunday`}
											isDisabled={isSubmitting}
											type='checkbox'
											isChecked={
												values?.recurringEvents?.find((event) => event?.id === item?.id)
													?.sunday || false
											}
										/>
									)}
								</FastField>
							</Td>
							<Td>
								<FastField name={`recurringEvents[${currentItemIndex}].content`}>
									{() => (
										<DebounceTextField
											name={`recurringEvents[${currentItemIndex}].content`}
											isDisabled={isSubmitting}
											fontSize={{ base: 12, sm: 13, xl: 14 }}
											placeholder='Wpisz intencje'
											w={{ base: "150px", sm: "170px", md: "180px", xl: "190px" }}
										/>
									)}
								</FastField>
							</Td>
							<Td>
								<Flex justifyContent={"center"}>
									<DeleteButton id={item?.id} mutation={deleteEvent} isDisabled={isSubmitting} />
								</Flex>
							</Td>
						</Tr>
					)
				)
			})}
		</Tbody>
	)
}

export default memo(RecurringEventsTableBody)
