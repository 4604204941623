import { Checkbox, Flex, Tbody, Td, Tr } from "@chakra-ui/react"
import { memo } from "react"
import { FastField, Field, useFormikContext } from "formik"

import DeleteButton from "../../common/DeleteButton/DeleteButton"
import { useDeleteIntentionMutation } from "../../../services/api/intentionsApi"
import DebounceTextField from "../../common/Form/DebounceTextField"
import DateField from "../../common/Form/DateField"
import TimeField from "../../common/Form/TimeField"

const IntentionsTableBody = ({ intentions }) => {
	const { values, isSubmitting } = useFormikContext()
	const [deleteIntention] = useDeleteIntentionMutation()

	return (
		<Tbody>
			{intentions?.map((item, index) => {
				const currentItemIndex = values?.intentions?.findIndex((intention) => intention?.id === item?.id)
				return (
					currentItemIndex !== undefined && (
						<Tr key={index}>
							<Td>
								<FastField name={`intentions[${currentItemIndex}].date`}>
									{() => (
										<DateField
											isDisabled={isSubmitting}
											name={`intentions[${currentItemIndex}].date`}
											fontSize={{ base: 12, sm: 13, xl: 14 }}
											w={{ base: "150px", sm: "170px", md: "180px", xl: "190px" }}
										/>
									)}
								</FastField>
							</Td>
							<Td>
								<FastField name={`intentions[${currentItemIndex}].hour`}>
									{() => (
										<TimeField
											isDisabled={isSubmitting}
											name={`intentions[${currentItemIndex}].hour`}
											fontSize={{ base: 12, sm: 13, xl: 14 }}
											w={{ base: "150px", sm: "170px", md: "180px", xl: "190px" }}
										/>
									)}
								</FastField>
							</Td>
							<Td>
								<FastField name={`intentions[${currentItemIndex}].intention`}>
									{() => (
										<DebounceTextField
											name={`intentions[${currentItemIndex}].intention`}
											isDisabled={isSubmitting}
											fontSize={{ base: 12, sm: 13, xl: 14 }}
											placeholder='Wpisz intencje'
											w={{ base: "150px", sm: "170px", md: "180px", xl: "190px" }}
										/>
									)}
								</FastField>
							</Td>
							<Td>
								<FastField name={`intentions[${currentItemIndex}].for_dead`}>
									{() => (
										<Field
											as={Checkbox}
											name={`intentions[${currentItemIndex}].for_dead`}
											isDisabled={isSubmitting}
											type='checkbox'
											isChecked={
												values?.intentions?.find((intention) => intention?.id === item?.id)
													?.for_dead || false
											}
										/>
									)}
								</FastField>
							</Td>
							<Td>
								<Flex justifyContent={"center"}>
									<DeleteButton id={item?.id} mutation={deleteIntention} isDisabled={isSubmitting} />
								</Flex>
							</Td>
						</Tr>
					)
				)
			})}
		</Tbody>
	)
}

export default memo(IntentionsTableBody)
