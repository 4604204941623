import { createSlice } from "@reduxjs/toolkit"

const pagesWithSubPages = createSlice({
	name: "pagesWithSubPages",
	initialState: {
		subpages: null,
		initArray: null,
		parentPages: null,
		pageMenus: null,
		searchingTerm: "",
		showFilterValue: null,
	},
	reducers: {
		setSubPagesData(state, action) {
			state.subpages = action.payload.subpages
			state.initArray = action.payload.subpages
			state.pageMenus = action.payload.pageMenus
		},
		clearSubPagesData(state) {
			state.subpages = null
			state.initArray = null
			state.pageMenus = null
			state.searchingTerm = ""
			state.showFilterValue = null
		},
		setSubPages(state, action) {
			state.subpages = action.payload
		},
		setEditInitialValues(state, action) {
			state.pageMenus = action.payload
		},
		clearEditInitialValues(state) {
			state.pageMenus = null
		},
		setSearchingTerm(state, action) {
			state.searchingTerm = action.payload
		},
		setShowFilterValue(state, action) {
			state.showFilterValue = action.payload
		},
	},
})

export const {
	setSubPagesData,
	clearSubPagesData,
	setSubPages,
	setEditInitialValues,
	clearEditInitialValues,
	setSearchingTerm,
	setShowFilterValue,
} = pagesWithSubPages.actions

export default pagesWithSubPages.reducer
