import { Flex } from "@chakra-ui/layout"
import * as Yup from "yup"

import FormikForm from "../../../common/Form/FormikForm"
import FormActionsButtons from "../../../common/Form/FormActionsButtons"
import { useUpdateNewsMutation } from "../../../../services/api/newsApi"
import NewsEditBasicDetails from "./NewsEditBasicDetails"
import NewsEditSummary from "./NewsEditSummary"
import NewsEditContent from "./NewsEditContent"
import NewsEditImage from "./NewsEditImage"

const NewsEditForm = ({ initValues }) => {
	const [updateNews] = useUpdateNewsMutation()

	return (
		<FormikForm
			isPatchMethod
			enableReinitialize
			shouldNavigate
			initialValues={initValues}
			validationSchema={Yup.object().shape({
				topic: Yup.string().required("Temat jest wymagany"),
				date: Yup.date()
					.min("01-01-2000", "Data nie może być mniejsza niz 01.01.2000")
					.max("12-31-2099", "Data nie może być większa niz 31.12.2099")
					.required("Data jest wymagana"),
			})}
			onSubmit={updateNews}
		>
			<Flex flexDirection={"column"} gap={5} mt={7}>
				<NewsEditBasicDetails />
				<NewsEditSummary />
				<NewsEditContent />
				<NewsEditImage />
			</Flex>
			<Flex justifyContent={"flex-end"} mt={5}>
				<FormActionsButtons />
			</Flex>
		</FormikForm>
	)
}

export default NewsEditForm
