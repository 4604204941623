import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const authApi = createApi({
	reducerPath: "authApi",
	// eslint-disable-next-line no-undef
	baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_KEY}/api`, credentials: "include" }),
	tagTypes: ["Auth"],
	endpoints: (builder) => ({
		getSession: builder.query({
			query: () => "/session",
			providesTags: ["Auth"],
		}),
		login: builder.mutation({
			query: (credentials) => ({
				url: "/login",
				method: "POST",
				body: credentials,
			}),
			invalidatesTags: ["Auth"],
		}),
		logout: builder.mutation({
			query: () => ({
				url: "/logout",
				method: "POST",
			}),
			invalidatesTags: ["Auth"],
		}),
	}),
})

export const { useGetSessionQuery, useLoginMutation, useLogoutMutation } = authApi
