import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex } from "@chakra-ui/react"
import { useEffect } from "react"
import { ChevronRightIcon } from "@chakra-ui/icons"
import { useDispatch } from "react-redux"

import NavigationButtons from "../../../components/common/NavigationButtons/NavigationButtons"
import AnimatedPage from "../../../utils/animations/routes/AnimatedPage"
import LoaderHOC from "../../../components/common/Loader/LoaderHOC"
import OneDayEventsForm from "../../../components/management-panel/OneDayEvents/OneDayEventsForm"
import { useGetOneDayEventsQuery } from "../../../services/api/oneDayEventsApi"
import { clearOneDayEventsData, setOneDayEventsData } from "../../../services/state/reducers/oneDayEvents-slice"

const CalendarOneDayEventsPage = () => {
	const dispatch = useDispatch()
	const { data, isLoading } = useGetOneDayEventsQuery()

	useEffect(() => {
		if (!data) return
		dispatch(setOneDayEventsData())

		return () => dispatch(clearOneDayEventsData())
	}, [data])

	return (
		<AnimatedPage>
			<Flex alignItems={"center"} gap={5}>
				<NavigationButtons />
				<Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}>
							Wydarzenia do kalendarza jednodniowe
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>
			<LoaderHOC isLoading={isLoading} mt={{ base: "60px", md: "140px", lg: "250px" }}>
				{data?.data?.one_day_events && <OneDayEventsForm initValues={data?.data?.one_day_events} />}
			</LoaderHOC>
		</AnimatedPage>
	)
}

export default CalendarOneDayEventsPage
