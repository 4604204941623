import { Navigate, useLocation } from "react-router-dom"

import Layout from "../../layout/Layout"

/**
 *
 * @returns Navigate to allowed routes if user is login
 */
const LoggedInGuard = ({ LoggedIn }) => {
	const location = useLocation()

	return LoggedIn ? <Layout /> : <Navigate to='/' replace state={{ from: location }} />
}

export default LoggedInGuard
