import { Badge, Button, Checkbox, Flex, Tbody, Td, Tr } from "@chakra-ui/react"
import { memo } from "react"
import { FastField, Field, useFormikContext } from "formik"
import { useLocation, useNavigate } from "react-router-dom"

import EditButton from "../../common/EditButton/EditButton"
import { useDeleteGalleryMutation } from "../../../services/api/galleryApi"
import DeleteButton from "../../common/DeleteButton/DeleteButton"
import ImageIcon from "../../../assets/icons/gallery/ImageIcon"

const GalleryTableBody = ({ filteredGallery }) => {
	const { values, isSubmitting } = useFormikContext()
	const [deleteGallery] = useDeleteGalleryMutation()
	const location = useLocation()
	const navigate = useNavigate()

	const handleGalleryEditNavigate = (id) => {
		const splittedPath = location.pathname.split("/")[1]
		navigate(`/${splittedPath}/${id}`)
	}

	const handleImagesEditNavigate = (id) => {
		const splittedPath = location.pathname.split("/")[1]
		navigate(`/${splittedPath}/${id}/images`)
	}

	return (
		<Tbody>
			{filteredGallery?.map((item, index) => {
				const currentItemIndex = values?.gallery?.findIndex((res) => res?.id === item?.id)
				return (
					<Tr key={index}>
						<Td>
							<Badge p={1} colorScheme='blue' fontSize={{ base: 10, md: 11, xl: 12 }}>
								{item?.date}
							</Badge>
						</Td>
						<Td>{item.title}</Td>
						<Td>
							<FastField name={`gallery[${currentItemIndex}].is_visible`}>
								{() => (
									<Field
										as={Checkbox}
										name={`gallery[${currentItemIndex}].is_visible`}
										isDisabled={isSubmitting}
										type='checkbox'
										isChecked={
											values?.gallery?.find((record) => record?.id === item?.id)?.is_visible ||
											false
										}
									/>
								)}
							</FastField>
						</Td>
						<Td>
							<Button
								colorScheme='blue'
								p={[1, 2]}
								minW='21px'
								boxSize={["21px", "22px", "25px"]}
								onClick={() => handleImagesEditNavigate(item?.id)}
							>
								<ImageIcon boxSize={{ base: 5, md: 6 }} />
							</Button>
						</Td>
						<Td>
							<Flex justifyContent={"flex-end"} alignItems={"center"} gap={5}>
								<EditButton onClick={() => handleGalleryEditNavigate(item?.id)} />
								<DeleteButton
									id={item?.id}
									mutation={deleteGallery}
									isDisabled={isSubmitting}
									minW='21px'
									h={{ base: "25px", md: "27px", lg: "30px" }}
									w={{ base: "25px", sm: "27px", lg: "35px" }}
								/>
							</Flex>
						</Td>
					</Tr>
				)
			})}
		</Tbody>
	)
}

export default memo(GalleryTableBody)
