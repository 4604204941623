import { createSlice } from "@reduxjs/toolkit"

const footer = createSlice({
	name: "news",
	initialState: {
		searchingTerm: "",
	},
	reducers: {
		setSearchingTerm(state, action) {
			state.searchingTerm = action.payload
		},
		clearSearchingTerm(state) {
			state.setSearchingTerm = ""
		},
	},
})

export const { setSearchingTerm, clearSearchingTerm } = footer.actions

export default footer.reducer
