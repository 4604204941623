import { Table, TableContainer } from "@chakra-ui/react"
import { memo } from "react"
import { useSelector } from "react-redux"

import NoData from "../../../common/NoData/NoData"
import GalleryImagesTableHeaders from "./GalleryImagesTableHeaders"
import GalleryImagesTableBody from "./GalleryImagesTableBody"
import { useFormikContext } from "formik"

const GalleryImagesTable = () => {
	const { values } = useFormikContext()
	const searchingTerm = useSelector((store) => store.gallery.searchingTerm)

	const filteredImages = values?.images.filter((item) =>
		item.title.toString().toLowerCase().includes(searchingTerm.toString().toLowerCase()),
	)

	return (
		<>
			<TableContainer mt={10}>
				<Table
					borderRadius={"4px"}
					variant='simple'
					bgColor='#FFF'
					fontSize={{ base: 11, sm: 12, md: 13, lg: 13, xl: 14 }}
				>
					<GalleryImagesTableHeaders filteredImagesLength={filteredImages?.length} />
					{!!filteredImages?.length && <GalleryImagesTableBody filteredImages={filteredImages} />}
				</Table>
			</TableContainer>
			{!filteredImages?.length && <NoData />}
		</>
	)
}

export default memo(GalleryImagesTable)
