import { Badge, Flex, Tbody, Td, Tr } from "@chakra-ui/react"
import { memo } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import EditButton from "../../common/EditButton/EditButton"

const HomiliesTableBody = () => {
	const homilies = useSelector((store) => store.homilies.homilies)
	const navigate = useNavigate()

	const handleEditNavigate = (id) => {
		const splittedPath = location.pathname.split("/")
		navigate(`/${splittedPath[1]}/${id}`)
	}

	return (
		<Tbody>
			{homilies?.map((homilie, index) => (
				<Tr key={index}>
					<Td>
						<Badge p={1} colorScheme='blue' fontSize={{ base: 10, md: 11, xl: 12 }}>
							{homilie?.date}
						</Badge>
					</Td>
					<Td>{homilie?.topic}</Td>
					<Td>{homilie?.file_name}</Td>
					<Td>
						<Flex justifyContent={"end"}>
							<EditButton onClick={() => handleEditNavigate(homilie?.id)} />
						</Flex>
					</Td>
				</Tr>
			))}
		</Tbody>
	)
}

export default memo(HomiliesTableBody)
