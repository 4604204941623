import { FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react"
import { Field, useField, useFormikContext } from "formik"
import React, { useState } from "react"
import { useEffect } from "react"
import { debounce } from "lodash"
import { useCallback } from "react"

const DebounceTextField = ({ label, ...props }) => {
	const [field, meta, helpers] = useField(props)
	const [fieldValue, setFieldValue] = useState(field.value)
	const { setSubmitting, handleSubmit } = useFormikContext()

	useEffect(() => {
		if (field.value !== fieldValue) {
			setFieldValue(field.value)
		}
	}, [field.value])

	const fieldProps = {
		...field,
		...props,
		value: fieldValue,
		onChange: (e) => setFieldValue(e.target.value),
		onBlur: () => {},
	}

	delete fieldProps?.name

	const handleOnChangeValue = useCallback(
		debounce((value) => helpers.setValue(value), 300),
		[helpers],
	)

	useEffect(() => {
		handleOnChangeValue(fieldValue)
	}, [fieldValue])

	const handleKeyDown = () => (e) => {
		if (e.key === "Enter") {
			setSubmitting(true)
			e.preventDefault()
			setTimeout(() => {
				handleSubmit()
			}, 311)
		}
	}

	return (
		<FormControl isInvalid={meta.error && meta.touched}>
			<FormLabel my='0'>{label}</FormLabel>
			<Field
				as={Input}
				fontSize={[13, 14, 15, 15, 16]}
				h={{ base: "30px", lg: "35px" }}
				my='0'
				autoComplete='true'
				onKeyPress={handleKeyDown()}
				{...fieldProps}
			/>
			<FormErrorMessage fontSize={[11, 12, 13, 13, 14]} my='0'>
				{meta.error}
			</FormErrorMessage>
		</FormControl>
	)
}

export default DebounceTextField
