import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const galleryApi = createApi({
	reducerPath: "galleryApi",
	// eslint-disable-next-line no-undef
	baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_KEY}/api`, credentials: "include" }),
	tagTypes: ["Gallery", "GalleryImages"],
	endpoints: (builder) => ({
		getGallery: builder.query({
			query: () => "/gallery",
			keepUnusedDataFor: 0,
			providesTags: ["Gallery"],
		}),
		addGallery: builder.mutation({
			query: (gallery) => ({
				url: "/gallery",
				method: "POST",
				body: gallery,
			}),
		}),
		updateGallery: builder.mutation({
			query: (gallery) => ({
				url: "/gallery",
				method: "PATCH",
				body: gallery,
			}),
			invalidatesTags: ["Gallery"],
		}),
		getGalleryRecord: builder.query({
			query: (id) => `/gallery/${id}`,
			keepUnusedDataFor: 0,
		}),
		updateGalleryRecord: builder.mutation({
			query: (newRecord) => ({
				url: "/updateGalleryRecord",
				method: "PATCH",
				body: newRecord,
			}),
			invalidatesTags: ["Gallery"],
		}),
		deleteGallery: builder.mutation({
			query: (id) => ({
				url: `/gallery/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Gallery"],
		}),
		getImages: builder.query({
			query: (id) => `/galleryImages/${id}`,
			keepUnusedDataFor: 0,
			providesTags: ["GalleryImages"],
		}),
		deleteGalleryImage: builder.mutation({
			query: (id) => ({
				url: `/galleryImages/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["GalleryImages"],
		}),
		addGalleryImages: builder.mutation({
			query: (newImages) => ({
				url: "/galleryImages",
				method: "POST",
				body: newImages,
			}),
		}),
		updateGalleryImages: builder.mutation({
			query: (newImages) => ({
				url: "/galleryImages",
				method: "PATCH",
				body: newImages,
			}),
			invalidatesTags: ["GalleryImages"],
		}),
		getImage: builder.query({
			query: (id) => `/images/${id}`,
			keepUnusedDataFor: 0,
		}),
		updateGalleryImage: builder.mutation({
			query: (updatedImage) => ({
				url: "/images",
				method: "PATCH",
				body: updatedImage,
				invalidatesTags: ["GalleryImages"],
			}),
		}),
	}),
})

export const {
	useGetGalleryQuery,
	useAddGalleryMutation,
	useUpdateGalleryMutation,
	useGetGalleryRecordQuery,
	useUpdateGalleryRecordMutation,
	useDeleteGalleryMutation,
	useGetImagesQuery,
	useDeleteGalleryImageMutation,
	useAddGalleryImagesMutation,
	useUpdateGalleryImagesMutation,
	useUpdateGalleryImageMutation,
	useGetImageQuery,
} = galleryApi
