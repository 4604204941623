import { Flex } from "@chakra-ui/layout"
import * as Yup from "yup"

import FormikForm from "../../../common/Form/FormikForm"
import FormActionsButtons from "../../../common/Form/FormActionsButtons"
import { useAddExCathedraMutation } from "../../../../services/api/exCathedraApi"
import ExCathedraAddBasicDetails from "./ExCathedraAddBasicDetails"
import ExCathedraAddFiles from "./ExCathedraAddFiles"

const ExCathedraAddForm = () => {
	const [addExCathedra] = useAddExCathedraMutation()

	return (
		<FormikForm
			enableReinitialize
			shouldNavigate
			initialValues={{
				date: "",
				number: "",
				year: "",
				icon: "",
				pdf: {
					file: "",
					file_name: "",
				},
			}}
			validationSchema={Yup.object().shape({
				date: Yup.date()
					.min("01-01-2000", "Data nie może być mniejsza niz 01.01.2000")
					.max("12-31-2099", "Data nie może być większa niz 31.12.2099")
					.required("Data jest wymagana"),
				number: Yup.string().required("Numer jest wymagany"),
				year: Yup.number()
					.min(2000, "Rocznik nie może być mniejszy niż 2000")
					.max(2099, "Rocznik nie może być większy niż 2099")
					.typeError("Rocznik nie może zawierać znaków specjalnych")
					.required("Rocznik jest wymagany"),
				pdf: Yup.object().shape({
					file: Yup.string().required("PDF jest wymagany"),
				}),
			})}
			onSubmit={addExCathedra}
		>
			<Flex flexDirection={"column"} gap={5} mt={7}>
				<ExCathedraAddBasicDetails />
				<ExCathedraAddFiles />
			</Flex>
			<Flex justifyContent={"flex-end"} mt={5}>
				<FormActionsButtons />
			</Flex>
		</FormikForm>
	)
}

export default ExCathedraAddForm
