import "@fontsource/inter"
import "@fontsource/eb-garamond"
import "react-toastify/dist/ReactToastify.css"

import ReactDOM from "react-dom/client"
import { ChakraProvider } from "@chakra-ui/react"
import { ToastContainer } from "react-toastify"
import { Provider } from "react-redux"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"

import App from "./App"
import chakraTheme from "./chakraTheme"
import store from "./services/state/store/store"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
	<Provider store={store}>
		<ChakraProvider theme={chakraTheme}>
			<ToastContainer style={{ fontSize: "16px" }} />
			<DndProvider backend={HTML5Backend}>
				<App />
			</DndProvider>
		</ChakraProvider>
	</Provider>,
)
