import { Box, Flex, Text, Divider } from "@chakra-ui/layout"
import { FastField, Field, FieldArray, useFormikContext } from "formik"
import { Button, Checkbox } from "@chakra-ui/react"
import React from "react"

import AddNewButton from "../../../common/AddNewButton/AddNewButton"
import BinIcon from "../../../../assets/icons/common/BinIcon"
import DebounceTextField from "../../../common/Form/DebounceTextField"
import DateField from "../../../common/Form/DateField"
import TimeField from "../../../common/Form/TimeField"

const IntentionsAddPanel = () => {
	const { isSubmitting, values } = useFormikContext()

	const emptyIntention = {
		date: "",
		hour: null,
		intention: "",
		for_dead: false,
	}

	const handleAddClick = (push) => {
		push(emptyIntention)
	}

	return (
		<Box
			borderRadius='4px'
			boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
			bgColor='#FFF'
			p={7}
		>
			<FieldArray name='intentions'>
				{({ push, remove }) => (
					<>
						{values?.intentions.length === 0 ? (
							<Button
								bgColor={"blackAlpha.100"}
								_hover={{
									bgColor: "blackAlpha.300",
								}}
								w='100%'
								h={{ base: "200px" }}
								justifyContent={"center"}
								alignItems={"center"}
								onClick={() => handleAddClick(push)}
							>
								<Text color='gray' fontSize={[20]}>
									Dodaj intencje
								</Text>
							</Button>
						) : (
							<Flex flexDirection={"column"} gap={7}>
								<Flex justifyContent={"flex-end"}>
									<AddNewButton onClick={() => handleAddClick(push)} />
								</Flex>
								{values?.intentions.map((_, index) => (
									<Flex key={index} flexDirection={"column"} alignItems={"center"} gap={10}>
										{!!index && <Divider mt={10} />}
										<Flex
											gap={{ base: "4rem", sm: "6rem", md: "8rem", lg: "8rem", xl: "12rem" }}
											alignItems={"center"}
											justifyContent={"center"}
											flexWrap={"wrap"}
										>
											<Flex flexDirection={"column"} gap={5}>
												<FastField name={`intentions[${index}].date`}>
													{() => (
														<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
															<Text
																minW={{ base: "70px", sm: "120px", lg: "150px" }}
																fontWeight={600}
																fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
															>
																Data
															</Text>
															<DateField
																isDisabled={isSubmitting}
																name={`intentions[${index}].date`}
															/>
														</Flex>
													)}
												</FastField>
												<FastField name={`intentions[${index}].hour`}>
													{() => (
														<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
															<Text
																minW={{ base: "70px", sm: "120px", lg: "150px" }}
																fontWeight={600}
																fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
															>
																Godzina
															</Text>
															<TimeField
																isDisabled={isSubmitting}
																name={`intentions[${index}].hour`}
															/>
														</Flex>
													)}
												</FastField>
												<FastField name={`intentions[${index}].intention`}>
													{() => (
														<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
															<Text
																minW={{ base: "70px", sm: "120px", lg: "150px" }}
																fontWeight={600}
																fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
															>
																Intencja
															</Text>
															<DebounceTextField
																isDisabled={isSubmitting}
																name={`intentions[${index}].intention`}
																fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
																w={{ base: "170px", sm: "300px", lg: "350px" }}
																placeholder='Wpisz intencje'
															/>
														</Flex>
													)}
												</FastField>
												<FastField name={`intentions[${index}].for_dead`}>
													{() => (
														<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
															<Text
																minW={{ base: "70px", sm: "120px", lg: "150px" }}
																fontWeight={600}
																fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
															>
																Za zmarłych
															</Text>
															<Field
																as={Checkbox}
																name={`intentions[${index}].for_dead`}
																isDisabled={isSubmitting}
																type='checkbox'
															/>
														</Flex>
													)}
												</FastField>
											</Flex>
											<Button
												isDisabled={isSubmitting}
												p={{ base: 0 }}
												boxSize={["30px", "35px"]}
												colorScheme='gray'
												onClick={() => remove(index)}
											>
												<BinIcon color='red' boxSize={[8, 9]} />
											</Button>
										</Flex>
									</Flex>
								))}
							</Flex>
						)}
					</>
				)}
			</FieldArray>
		</Box>
	)
}

export default IntentionsAddPanel
