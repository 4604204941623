import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const intentionsApi = createApi({
	reducerPath: "intentionsApi",
	// eslint-disable-next-line no-undef
	baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_KEY}/api`, credentials: "include" }),
	tagTypes: ["Intentions"],
	endpoints: (builder) => ({
		getIntentions: builder.query({
			query: () => "/intentions",
			keepUnusedDataFor: 0,
			providesTags: ["Intentions"],
		}),
		addIntention: builder.mutation({
			query: (intention) => ({
				url: "/intentions",
				method: "POST",
				body: intention,
			}),
		}),
		updateIntentions: builder.mutation({
			query: (newIntentions) => ({
				url: "/intentions",
				method: "PATCH",
				body: newIntentions,
			}),
			invalidatesTags: ["Intentions"],
		}),
		deleteIntention: builder.mutation({
			query: (id) => ({
				url: `/intentions/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Intentions"],
		}),
	}),
})

export const {
	useGetIntentionsQuery,
	useAddIntentionMutation,
	useUpdateIntentionsMutation,
	useDeleteIntentionMutation,
} = intentionsApi
