import { Flex } from "@chakra-ui/layout"
import * as Yup from "yup"

import FormikForm from "../../../common/Form/FormikForm"
import FormActionsButtons from "../../../common/Form/FormActionsButtons"
import { useUpdateSinglePageMutation } from "../../../../services/api/singlePagesApi"
import SinglePageEditHeader from "./SinglePageEditHeader"
import SinglePageEditBasicDetails from "./SinglePageEditBasicDetails"
import SinglePageEditIntroduction from "./SinglePageEditIntroduction"
import SinglePageEditSound from "./SinglePageEditSound"
import SinglePageEditAssignment from "./SinglePageEditAssignment"
import SinglePageEditContent from "./SinglePageEditContent"

const SinglePageEditForm = ({ initValues }) => {
	const [updateSinglePage] = useUpdateSinglePageMutation()

	return (
		<FormikForm
			isPatchMethod={true}
			enableReinitialize
			shouldNavigate={true}
			initialValues={initValues || { icon: "", is_visible: false }}
			validationSchema={Yup.object().shape({
				header: Yup.string().required("Nagłówek jest wymagany"),
			})}
			onSubmit={updateSinglePage}
		>
			<Flex flexDirection={"column"} gap={5} mt={7}>
				<SinglePageEditHeader />
				<SinglePageEditBasicDetails />
				<SinglePageEditIntroduction />
				<SinglePageEditContent />
				<SinglePageEditSound />
				<SinglePageEditAssignment />
			</Flex>
			<Flex justifyContent={"flex-end"} mt={5}>
				<FormActionsButtons />
			</Flex>
		</FormikForm>
	)
}

export default SinglePageEditForm
