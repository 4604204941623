import { Flex } from "@chakra-ui/layout"
import { Button } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

import FormikForm from "../../common/Form/FormikForm"
import FormActionsButtons from "../../common/Form/FormActionsButtons"
import { useUpdateFooterPageMutation } from "../../../services/api/footerApi"
import FooterPageEditHeader from "./FooterPageEditHeader"
import FooterPageEditOpenHours from "./FooterPageEditOpenHours"
import FooterPageEditContactData from "./FooterPageEditContactData"
import FooterPageEditNumber from "./FooterPageEditNumber"

const FooterPageEditForm = ({ initValues }) => {
	const [updateFooterPage] = useUpdateFooterPageMutation()
	const navigate = useNavigate()

	const handleNavigateToIcons = () => navigate("/footer/icons")

	return (
		<FormikForm
			isPatchMethod
			enableReinitialize
			initialValues={initValues || { title: "", open_hours: "", contact_data: "", account_number: "" }}
			onSubmit={updateFooterPage}
		>
			<Flex flexDirection={"column"} gap={5} mt={7}>
				<FooterPageEditHeader />
				<FooterPageEditOpenHours />
				<FooterPageEditContactData />
				<FooterPageEditNumber />
				<Button
					bgColor={"blue.400"}
					_hover={{
						bgColor: "blue.500",
					}}
					color='#FFF'
					h={["25px", "30px", "32px", "33px", "35px"]}
					fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
					onClick={handleNavigateToIcons}
				>
					Przejdź do ikon
				</Button>
			</Flex>
			<Flex justifyContent={"flex-end"} mt={5}>
				<FormActionsButtons />
			</Flex>
		</FormikForm>
	)
}

export default FooterPageEditForm
