import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom"
import { createContext, useState } from "react"

import LoggedInGuard from "./services/guards/LoggedInGuard"
import LoggedOutGuard from "./services/guards/LoggedOutGuard"

import LoginPage from "./pages/auth/LoginPage"
import PagesPage from "./pages/management-panel/Pages/PagesPage"
import { useGetSessionQuery } from "./services/api/authApi"
import ContentWithSubpagesPage from "./pages/management-panel/ContentWithSubpages/ContentWithSubpagesPage"
import SinglePageContentPage from "./pages/management-panel/SinglePageContent/SinglePageContentPage"
import NewsPage from "./pages/management-panel/News/NewsPage"
import ExCathedraPage from "./pages/management-panel/ExCathedra/ExCathedraPage"
import GalleryPage from "./pages/management-panel/Gallery/GalleryPage"
import HomiliesPage from "./pages/management-panel/Homilies/HomiliesPage"
import CalendarIntentionsPage from "./pages/management-panel/CalendarIntentions/CalendarIntentionsPage"
import CalendarRecurringEventsPage from "./pages/management-panel/CalendarRecurringEvents/CalendarRecurringEventsPage"
import CalendarOneDayEventsPage from "./pages/management-panel/CalendarOneDayEvents/CalendarOneDayEventsPage"
import SubpagesEditPage from "./pages/management-panel/ContentWithSubpages/Edit/SubpagesEditPage"
import NewsEditPage from "./pages/management-panel/News/Edit/NewsEditPage"
import ExCathedraEditPage from "./pages/management-panel/ExCathedra/Edit/ExCathedraEditPage"
import HomiliesEditPage from "./pages/management-panel/Homilies/Edit/HomiliesEditPage"
import GalleryEditPage from "./pages/management-panel/Gallery/Edit/GalleryEditPage"
import GalleryImagesPage from "./pages/management-panel/Gallery/GalleryImages/GalleryImagesPage"
import GalleryImagesEditPage from "./pages/management-panel/Gallery/GalleryImages/Edit/GalleryImagesEditPage"
import SinglePageEditPage from "./pages/management-panel/SinglePageContent/Edit/SinglePageEditPage"
import PagesAddPage from "./pages/management-panel/Pages/Add/PagesAddPage"
import NewsAddPage from "./pages/management-panel/News/Add/NewsAddPage"
import ExCathedraAddPage from "./pages/management-panel/ExCathedra/Add/ExCathedraAddPage"
import GalleryAddPage from "./pages/management-panel/Gallery/Add/GalleryAddPage"
import GalleryImagesAddPage from "./pages/management-panel/Gallery/GalleryImages/Add/GalleryImagesAddPage"
import HomiliesAddPage from "./pages/management-panel/Homilies/Add/HomiliesAddPage"
import IntentionsAddPage from "./pages/management-panel/CalendarIntentions/Add/IntentionsAddPage"
import RecurringEventsAddPage from "./pages/management-panel/CalendarRecurringEvents/Add/RecurringEventsAddPage"
import OneDayEventsAddPage from "./pages/management-panel/CalendarOneDayEvents/Add/OneDayEventsAddPage"
import PermissionRouteGuard from "./services/guards/PermissionRouteGuard"
import EditPageFooter from "./pages/management-panel/PageFooter/EditPageFooter"
import FooterIconsPage from "./pages/management-panel/PageFooter/Icons/FooterIconsPage"
import FooterIconsAddPage from "./pages/management-panel/PageFooter/Icons/Add/FooterIconsAddPage"
import FooterIconsEditPage from "./pages/management-panel/PageFooter/Icons/Edit/FooterIconsEditPage"

export const PWAContext = createContext()

const App = () => {
	const [promptEvent, setPromptEvent] = useState()
	const [appInstalled, setAppInstalled] = useState(window.matchMedia("(display-mode: standalone)").matches)
	const { data: LoggedIn, isLoading } = useGetSessionQuery()

	window.addEventListener("beforeinstallprompt", (e) => {
		setPromptEvent(e)
	})

	window.addEventListener("appinstalled", () => {
		setAppInstalled(true)
	})

	return (
		(!isLoading || LoggedIn === false) && (
			<PWAContext.Provider value={{ promptEvent, appInstalled }}>
				<Router>
					<Routes>
						<Route element={<LoggedOutGuard LoggedIn={LoggedIn?.data.is_auth} />}>
							<Route path='/' element={<LoginPage />} />
							<Route path='*' element={<Navigate to='/' />} />
						</Route>
						<Route element={<LoggedInGuard LoggedIn={LoggedIn?.data.is_auth} />}>
							<Route path='/pages'>
								<Route index={true} element={<PagesPage />} />
								<Route element={<PermissionRouteGuard />}>
									<Route path='add' element={<PagesAddPage />} />
								</Route>
							</Route>
							<Route path='/content-with-subpages'>
								<Route index={true} element={<ContentWithSubpagesPage />} />
								<Route path=':subpageId' element={<SubpagesEditPage />} />
							</Route>
							<Route path='/single-page-content'>
								<Route index={true} element={<SinglePageContentPage />} />
								<Route path=':singlePageId' element={<SinglePageEditPage />} />
							</Route>
							<Route path='/news'>
								<Route index={true} element={<NewsPage />} />
								<Route path=':newsId' element={<NewsEditPage />} />
								<Route path='add' element={<NewsAddPage />} />
							</Route>
							<Route path='/exCathedra'>
								<Route index={true} element={<ExCathedraPage />} />
								<Route path=':exCathedraId' element={<ExCathedraEditPage />} />
								<Route path='add' element={<ExCathedraAddPage />} />
							</Route>
							<Route path='/gallery'>
								<Route index={true} element={<GalleryPage />} />
								<Route path='add' element={<GalleryAddPage />} />
								<Route path=':galleryId' element={<GalleryEditPage />} />
								<Route path=':galleryId/images'>
									<Route index={true} element={<GalleryImagesPage />} />
									<Route path='add' element={<GalleryImagesAddPage />} />
									<Route path=':imageId' element={<GalleryImagesEditPage />} />
								</Route>
							</Route>
							<Route path='/homilies'>
								<Route index={true} element={<HomiliesPage />} />
								<Route path=':homilieId' element={<HomiliesEditPage />} />
								<Route path='add' element={<HomiliesAddPage />} />
							</Route>
							<Route path='/calendar-intentions'>
								<Route index={true} element={<CalendarIntentionsPage />} />
								<Route path='add' element={<IntentionsAddPage />} />
							</Route>
							<Route path='/calendar-recurring-events'>
								<Route index={true} element={<CalendarRecurringEventsPage />} />
								<Route path='add' element={<RecurringEventsAddPage />} />
							</Route>
							<Route path='/calendar-one-day-events'>
								<Route index={true} element={<CalendarOneDayEventsPage />} />
								<Route path='add' element={<OneDayEventsAddPage />} />
							</Route>
							<Route path='/footer'>
								<Route index={true} element={<EditPageFooter />} />
								<Route path='icons'>
									<Route index={true} element={<FooterIconsPage />} />
									<Route path='add' element={<FooterIconsAddPage />} />
									<Route path=':iconId' element={<FooterIconsEditPage />} />
								</Route>
							</Route>
							<Route path='*' element={<Navigate to='/pages' />} />
						</Route>
					</Routes>
				</Router>
			</PWAContext.Provider>
		)
	)
}

export default App
