import { Box, Checkbox, Flex, Text } from "@chakra-ui/react"
import { FastField, Field, useFormikContext } from "formik"

import DebounceTextField from "../../../common/Form/DebounceTextField"
import SelectField from "../../../common/Form/SelectField"
import { useSelector } from "react-redux"

const PagesAddPanel = () => {
	const { isSubmitting } = useFormikContext()
	const parentMenus = useSelector((store) => store.pages.parentMenus)

	return (
		<Box
			borderRadius='4px'
			boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
			bgColor='#FFF'
			mt={5}
			px={7}
			py={5}
		>
			<Flex flexDirection={"column"} gap={6}>
				<FastField name='title'>
					{() => (
						<Flex
							gap={{ base: 2, lg: 10 }}
							alignItems={["flex-start", "center"]}
							flexDirection={["column", "row"]}
						>
							<Text
								minW={{ base: "100px", sm: "120px", lg: "150px" }}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								Tytuł
							</Text>

							<DebounceTextField
								isDisabled={isSubmitting}
								name='title'
								w='auto'
								placeholder='Wpisz tytuł'
							/>
						</Flex>
					)}
				</FastField>
				<FastField name={"is_visible"}>
					{() => (
						<Flex
							gap={{ base: 2, lg: 10 }}
							alignItems={["flex-start", "center"]}
							flexDirection={["column", "row"]}
						>
							<Text
								minW={{ base: "100px", sm: "120px", lg: "150px" }}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								Pokaż
							</Text>
							<Field as={Checkbox} name={"is_visible"} isDisabled={isSubmitting} type='checkbox' />
						</Flex>
					)}
				</FastField>
				<FastField key={parentMenus} name='parent_menu'>
					{() => (
						<Flex
							gap={{ base: 2, lg: 10 }}
							alignItems={["flex-start", "center"]}
							flexDirection={["column", "row"]}
						>
							<Text
								minW={{ base: "100px", sm: "120px", lg: "150px" }}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								Menu nadrzędne
							</Text>
							<SelectField
								name={"parent_menu"}
								placeholder='Wybierz menu'
								isDisabled={isSubmitting}
								w={{ base: "120px", md: "150px", xl: "160px" }}
								h={["25px", "30px", "32px", "33px", "35px"]}
								options={parentMenus}
							/>
						</Flex>
					)}
				</FastField>
				<FastField name='file_name'>
					{() => (
						<Flex
							gap={{ base: 2, lg: 10 }}
							alignItems={["flex-start", "center"]}
							flexDirection={["column", "row"]}
						>
							<Text
								minW={{ base: "100px", sm: "120px", lg: "150px" }}
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
							>
								Nazwa pliku
							</Text>

							<DebounceTextField
								isDisabled={isSubmitting}
								name='file_name'
								w='auto'
								placeholder='Wpisz nazwę'
							/>
						</Flex>
					)}
				</FastField>
			</Flex>
		</Box>
	)
}

export default PagesAddPanel
