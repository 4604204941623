import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const oneDayEventsApi = createApi({
	reducerPath: "oneDayEventsApi",
	// eslint-disable-next-line no-undef
	baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_KEY}/api`, credentials: "include" }),
	tagTypes: ["OneDayEvents"],
	endpoints: (builder) => ({
		getOneDayEvents: builder.query({
			query: () => "/oneDayEvents",
			keepUnusedDataFor: 0,
			providesTags: ["OneDayEvents"],
		}),
		addOneDayEvent: builder.mutation({
			query: (event) => ({
				url: "/oneDayEvents",
				method: "POST",
				body: event,
			}),
		}),
		updateOneDayEvents: builder.mutation({
			query: (event) => ({
				url: "/oneDayEvents",
				method: "PATCH",
				body: event,
			}),
			invalidatesTags: ["OneDayEvents"],
		}),
		deleteOneDayEvent: builder.mutation({
			query: (id) => ({
				url: `/oneDayEvents/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["OneDayEvents"],
		}),
	}),
})

export const {
	useGetOneDayEventsQuery,
	useAddOneDayEventMutation,
	useUpdateOneDayEventsMutation,
	useDeleteOneDayEventMutation,
} = oneDayEventsApi
