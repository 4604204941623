import { Flex } from "@chakra-ui/react"
import { memo } from "react"

import menu from "../../../utils/menu/menu.json"
import MenuItem from "./MenuItem"

const MenuItems = ({ fontSize, ...props }) => {
	return (
		<Flex as='nav' flexDirection={"column"} {...props}>
			{menu.map((item) => (
				<MenuItem key={item.id} name={item.name} path={item.path} fontSize={fontSize} />
			))}
		</Flex>
	)
}

export default memo(MenuItems)
