import { Flex, Text } from "@chakra-ui/react"

const NoData = ({ boxStyles, ...props }) => {
	return (
		<Flex justifyContent='center' alignItems='center' h='40vh' style={boxStyles}>
			<Text
				fontSize={{ base: "18px", md: "24px" }}
				letterSpacing={{ base: "2px", md: "4px" }}
				fontWeight='600'
				color='#05050580'
				{...props}
			>
				Brak danych
			</Text>
		</Flex>
	)
}

export default NoData
