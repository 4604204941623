import { Button } from "@chakra-ui/react"
import { memo } from "react"

const HamburgerButton = ({ isOpen, toggle }) => {
	return (
		<Button
			as='svg'
			position={"fixed"}
			top={10}
			left={10}
			borderRadius={0}
			zIndex={2}
			cursor={"pointer"}
			viewBox={"0 0 32 32"}
			size={"20px"}
			colorScheme='transparent'
			p={0}
			height={["3em", "3.5em", "4em"]}
			w={["3em", "3.5em", "4em"]}
			transition='transform 600ms cubic-bezier(0.4, 0, 0.2, 1)'
			transform={isOpen ? "rotate(-45deg)" : ""}
			onClick={toggle}
		>
			<path
				className='line line-top-bottom'
				d='M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22'
				fill='none'
				stroke={isOpen ? "#FFF" : "#000"}
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='3'
				strokeDasharray={isOpen ? "20 300" : "12 63"}
				strokeDashoffset={isOpen ? "-32.42" : ""}
				style={{
					transition:
						"stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1)",
				}}
			></path>
			<path
				className='line'
				d='M7 16 27 16'
				fill='none'
				stroke={isOpen ? "#FFF" : "#000"}
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='3'
				style={{
					transition:
						"stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1)",
				}}
			></path>
		</Button>
	)
}

export default memo(HamburgerButton)
