import { Button } from "@chakra-ui/react"

import { EditIcon } from "@chakra-ui/icons"

const EditButton = ({ ...props }) => {
	return (
		<>
			<Button colorScheme='blue' p={[1, 2]} minW='21px' boxSize={["21px", "22px", "25px"]} {...props}>
				<EditIcon color='white' boxSize={{ base: 5, md: 6 }} />
			</Button>
		</>
	)
}

export default EditButton
