import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const newsApi = createApi({
	reducerPath: "newsApi",
	// eslint-disable-next-line no-undef
	baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_KEY}/api`, credentials: "include" }),
	tagTypes: ["News"],
	endpoints: (builder) => ({
		getNews: builder.query({
			query: () => "/news",
			keepUnusedDataFor: 0,
			providesTags: ["News"],
		}),
		addNews: builder.mutation({
			query: (news) => ({
				url: "/news",
				method: "POST",
				body: news,
			}),
			invalidatesTags: ["News"],
		}),
		updateNews: builder.mutation({
			query: (news) => ({
				url: "/news",
				method: "PATCH",
				body: news,
			}),
			invalidatesTags: ["News"],
		}),
		getNew: builder.query({
			query: (id) => `/news/${id}`,
			keepUnusedDataFor: 0,
		}),
	}),
})

export const { useGetNewsQuery, useAddNewsMutation, useUpdateNewsMutation, useGetNewQuery } = newsApi
