import { createSlice } from "@reduxjs/toolkit"

const homiliesSlice = createSlice({
	name: "homilies",
	initialState: {
		homilies: null,
		initArray: null,
	},
	reducers: {
		setHomiliesData(state, action) {
			state.homilies = action.payload
			state.initArray = action.payload
		},
		clearHomiliesData(state) {
			state.homilies = null
			state.initArray = null
		},
		setHomilies(state, action) {
			state.homilies = action.payload
		},
	},
})

export const { setHomiliesData, clearHomiliesData, setHomilies } = homiliesSlice.actions

export default homiliesSlice.reducer
