import { Table, TableContainer } from "@chakra-ui/react"
import { memo } from "react"
import { useSelector } from "react-redux"

import NoData from "../../common/NoData/NoData"
import ExCathedraTableHeaders from "./ExCathedraTableHeaders"
import ExCathedraTableBody from "./ExCathedraTableBody"

const ExCathedraTable = () => {
	const exCathedra = useSelector((store) => store.exCathedra.exCathedra)

	return (
		<>
			<TableContainer mt={10}>
				<Table
					borderRadius={"4px"}
					variant='simple'
					bgColor='#FFF'
					fontSize={{ base: 11, sm: 12, md: 13, lg: 13, xl: 14 }}
				>
					<ExCathedraTableHeaders />
					{!!exCathedra?.length && <ExCathedraTableBody />}
				</Table>
			</TableContainer>
			{!exCathedra?.length && <NoData />}
		</>
	)
}

export default memo(ExCathedraTable)
