import { Th, Thead, Tr, useBoolean } from "@chakra-ui/react"
import { memo, useCallback } from "react"
import { useFormikContext } from "formik"

import SortDownIcon from "../../../assets/icons/common/SortDownIcon"
import SortUpIcon from "../../../assets/icons/common/SortUpIcon"

const GalleryTableHeaders = ({ filteredGalleryLength }) => {
	const { values, setFieldValue } = useFormikContext()
	const [isDescSortByDate, setIsDescSortByDate] = useBoolean(true)
	const [isDescSortByTitle, setIsDescSortByTitle] = useBoolean(true)
	const [isDescSortByShow, setIsDescSortByShow] = useBoolean(true)

	const sorting = useCallback((array, key, order) => {
		return array?.toSorted((a, b) => {
			const valueA = typeof a[key] === "string" ? a[key].toLowerCase() : a[key]
			const valueB = typeof b[key] === "string" ? b[key].toLowerCase() : b[key]

			if (!order) {
				if (valueA < valueB) return -1
				if (valueA > valueB) return 1
				return 0
			}
			if (valueA > valueB) return -1
			if (valueA < valueB) return 1
			return 0
		})
	}, [])

	const sortingDate = useCallback((array, key, order) => {
		return array?.toSorted((a, b) => {
			const dateA = new Date(a[key])
			const dateB = new Date(b[key])
			if (order) return dateA - dateB
			if (!order) return dateB - dateA
			return 0
		})
	}, [])

	const handleSorting = (toggle, key, order, isDate) => {
		if (filteredGalleryLength <= 1) return
		const sortedData = isDate ? sortingDate(values?.gallery, key, order) : sorting(values?.gallery, key, order)

		setFieldValue("gallery", sortedData)

		toggle()
	}

	const getSortIcon = (isDescSort) => {
		switch (isDescSort) {
			case true:
				return <SortDownIcon ms={2} boxSize={6} color='blue.600' />
			case false:
				return <SortUpIcon ms={2} boxSize={6} color='blue.600' />
			default:
				return <SortDownIcon ms={2} boxSize={6} color='blue.600' />
		}
	}

	return (
		<Thead>
			<Tr>
				<Th
					cursor={filteredGalleryLength > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByDate.toggle, "date", isDescSortByDate, true)}
				>
					Data
					{getSortIcon(isDescSortByDate)}
				</Th>
				<Th
					cursor={filteredGalleryLength > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByTitle.toggle, "title", isDescSortByTitle)}
				>
					Tytuł
					{getSortIcon(isDescSortByTitle)}
				</Th>
				<Th
					cursor={filteredGalleryLength > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByShow.toggle, "is_visible", isDescSortByShow)}
				>
					Pokaż
					{getSortIcon(isDescSortByShow)}
				</Th>
				<Th fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }} borderColor={"gray.700"}>
					Zdjęcia
				</Th>
				<Th borderColor={"gray.700"}></Th>
			</Tr>
		</Thead>
	)
}

export default memo(GalleryTableHeaders)
