import { Box, Button, Flex, Image, Input, Text } from "@chakra-ui/react"
import { FastField, useFormikContext } from "formik"
import React, { useRef } from "react"

import DebounceTextField from "../../../../common/Form/DebounceTextField"
import DebounceTextArea from "../../../../common/Form/DebounceTextArea"

const GalleryImagesEdit = () => {
	const { values, isSubmitting, setFieldValue } = useFormikContext()
	const imageInputRef = useRef()

	const handleEditImage = () => {
		if (!imageInputRef) return

		imageInputRef.current.click()
	}

	const onFileUpload = (e) => {
		e.preventDefault()

		const file = e.type === "drop" ? e.dataTransfer.files[0] : e.target.files[0]
		if (!file || !file.type.includes("image")) return
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => {
			setFieldValue("image", reader.result)
		}
	}

	return (
		<Box
			borderRadius='4px'
			boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
			bgColor='#FFF'
			px={7}
			py={5}
		>
			<Flex gap={{ base: 10, md: 7, lg: 12 }} alignItems={"center"} flexDirection={["column", "row"]}>
				<Flex flexDirection={"column"} gap={2} onDragOver={(e) => e.preventDefault()} onDrop={onFileUpload}>
					<Box boxSize={{ base: "120px", md: "160px" }}>
						<Image w='100%' h='100%' objectFit={"contain"} src={values?.image} />
					</Box>
					<Button
						isDisabled={isSubmitting}
						fontSize={[11, 12, 13, 14, 15]}
						fontWeight={500}
						p={{ base: 0 }}
						minH='20px'
						h={["20px", "25px"]}
						colorScheme='blue'
						onClick={handleEditImage}
					>
						Edytuj zdjęcie
					</Button>
				</Flex>
				<Flex flexDirection={"column"} gap={7} alignItems={"center"}>
					<Flex gap={4}>
						<Text fontSize={[13, 14, 15, 15, 16]}>Tytuł</Text>
						<FastField name='title'>
							{() => (
								<DebounceTextField
									isDisabled={isSubmitting}
									name='title'
									w={{ base: "170px", sm: "220px", md: "450px" }}
									placeholder='Wpisz tytuł'
								/>
							)}
						</FastField>
					</Flex>
					<Flex gap={4}>
						<Text fontSize={[13, 14, 15, 15, 16]}>Opis</Text>
						<FastField name='description'>
							{() => (
								<DebounceTextArea
									isDisabled={isSubmitting}
									name='description'
									w={{ base: "170px", sm: "220px", md: "450px" }}
									fontSize={[13, 14, 15, 15, 16]}
									placeholder='Wpisz tytuł'
								/>
							)}
						</FastField>
					</Flex>
				</Flex>
			</Flex>
			<Input ref={imageInputRef} display={"none"} type='file' accept='image/*' onChange={onFileUpload} />
		</Box>
	)
}

export default GalleryImagesEdit
