import { Flex } from "@chakra-ui/react"
import { memo } from "react"
import * as Yup from "yup"

import FormActionsButtons from "../../../common/Form/FormActionsButtons"
import FormikForm from "../../../common/Form/FormikForm"
import { useAddPageMutation } from "../../../../services/api/pagesApi"
import PagesAddPanel from "./PagesAddPanel"

const PagesAddForm = () => {
	const [addPage] = useAddPageMutation()

	const convertToNumberParentMenu = (data) => ({
		data: {
			...data,
			parent_menu: Number(data.parent_menu),
		},
	})

	return (
		<FormikForm
			shouldNavigate
			enableReinitialize
			initialValues={{ title: "", is_visible: false, parent_menu: "", file_name: "" }}
			validationSchema={Yup.object().shape({
				title: Yup.string().required("Tytuł jest wymagany"),
				parent_menu: Yup.number().required("Menu jest wymagane"),
			})}
			middleware={convertToNumberParentMenu}
			onSubmit={addPage}
		>
			<PagesAddPanel />
			<Flex justifyContent={"flex-end"} mt={8}>
				<FormActionsButtons />
			</Flex>
		</FormikForm>
	)
}

export default memo(PagesAddForm)
