import { Flex } from "@chakra-ui/layout"

import FormikForm from "../../../../common/Form/FormikForm"
import FormActionsButtons from "../../../../common/Form/FormActionsButtons"
import { useAddFooterIconsMutation } from "../../../../../services/api/footerApi"
import FooterIconsAddPanel from "./FooterIconsAddPanel"

const FooterIconsAddForm = () => {
	const [addFooterIcons] = useAddFooterIconsMutation()

	return (
		<FormikForm enableReinitialize shouldNavigate={true} initialValues={{ icons: [] }} onSubmit={addFooterIcons}>
			<Flex flexDirection={"column"} gap={5} mt={7}>
				<FooterIconsAddPanel />
			</Flex>
			<Flex justifyContent={"flex-end"} mt={5}>
				<FormActionsButtons />
			</Flex>
		</FormikForm>
	)
}

export default FooterIconsAddForm
