import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const pagesApi = createApi({
	reducerPath: "pagesApi",
	// eslint-disable-next-line no-undef
	baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_KEY}/api`, credentials: "include" }),
	tagTypes: ["Pages"],
	endpoints: (builder) => ({
		getPages: builder.query({
			query: () => "/pages",
			keepUnusedDataFor: 0,
			providesTags: ["Pages"],
		}),
		updatePages: builder.mutation({
			query: (newPages) => ({
				url: "/pages",
				method: "PATCH",
				body: newPages,
			}),
			invalidatesTags: ["Pages"],
		}),
		addPage: builder.mutation({
			query: (newPage) => ({
				url: "/pages",
				method: "POST",
				body: newPage,
			}),
			invalidatesTags: ["Pages"],
		}),
		deletePage: builder.mutation({
			query: (id) => ({
				url: `/pages/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Pages"],
		}),
		getParentMenus: builder.query({
			query: () => "/parentMenus",
			keepUnusedDataFor: 0,
		}),
	}),
})

export const {
	useGetPagesQuery,
	useUpdatePagesMutation,
	useAddPageMutation,
	useDeletePageMutation,
	useGetParentMenusQuery,
} = pagesApi
