import { SearchIcon } from "@chakra-ui/icons"
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react"
import { memo, useCallback } from "react"

const SearchInput = ({
	isDisabled,
	searchText,
	setSearchText,
	originalArray,
	handleSetResults,
	fieldsToFilter,
	dependencies,
	...props
}) => {
	const handleFilterByDependencies = (item) =>
		dependencies.every((field) => {
			const fieldValue = item[field.name]?.toString().toLowerCase()
			return (
				typeof fieldValue &&
				(field.value === "" ||
					fieldValue ===
						(field.value === "Tak"
							? "true"
							: field.value === "Nie"
								? "false"
								: field.value.toString().toLowerCase()))
			)
		})

	const handleSearching = useCallback(
		(searchValue) => {
			if (!originalArray) return
			setSearchText(searchValue)
			if (!handleSetResults) return
			if (searchValue === "") {
				if (!dependencies) handleSetResults(originalArray)
				else {
					const filteredGroups = originalArray.filter((item) => handleFilterByDependencies(item))
					handleSetResults(filteredGroups)
				}
			} else {
				const filteredGroups = originalArray.filter((item) => {
					const searchingResults = fieldsToFilter.some((field) => {
						const fieldValue = item[field]?.toString().toLowerCase()
						return (
							typeof fieldValue && (searchValue === "" || fieldValue.includes(searchValue.toLowerCase()))
						)
					})
					if (!dependencies) return searchingResults

					return searchingResults && handleFilterByDependencies(item)
				})
				handleSetResults(filteredGroups)
			}
		},
		[setSearchText, originalArray, fieldsToFilter, handleSetResults],
	)

	const handleBlockSubmitValues = (e) => {
		if (e.key !== "Enter") return

		e.preventDefault()
	}

	return (
		<InputGroup justifyContent='end'>
			<Input
				aria-label='Search input'
				bgColor={"#fff"}
				w={["200px", "auto"]}
				h={["25px", "30px", "32px", "33px", "35px"]}
				fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
				borderRadius={"4px"}
				placeholder={"Szukaj..."}
				value={searchText}
				isDisabled={isDisabled}
				onChange={(e) => handleSearching(e.target.value)}
				onKeyDown={handleBlockSubmitValues}
				{...props}
			/>
			<InputRightElement h={"100%"}>
				<SearchIcon boxSize={{ base: 4, sm: 5, lg: 6 }} />
			</InputRightElement>
		</InputGroup>
	)
}

export default memo(SearchInput)
