import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
} from "@chakra-ui/react"
import { useRef } from "react"

const DeleteAlert = ({ isOpen, onClose, handleConfirmRemove }) => {
	const cancelRef = useRef()

	return (
		<AlertDialog
			motionPreset='slideInBottom'
			leastDestructiveRef={cancelRef}
			onClose={onClose}
			isOpen={isOpen}
			isCentered
		>
			<AlertDialogOverlay />

			<AlertDialogContent minW={{ base: "320px", lg: "350px" }}>
				<AlertDialogHeader fontSize={[14, 16, 18, 20]}>Potwierdź usunięcie</AlertDialogHeader>
				<AlertDialogBody fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}>
					Czy jesteś pewien/a, że chcesz usunąć ten element? Tej operacji nie można cofnąć.
				</AlertDialogBody>
				<AlertDialogFooter gap={3}>
					<Button
						fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
						h={["25px", "30px", "32px", "33px", "35px"]}
						ref={cancelRef}
						onClick={onClose}
					>
						Anuluj
					</Button>
					<Button
						fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
						h={["25px", "30px", "32px", "33px", "35px"]}
						colorScheme='red'
						onClick={handleConfirmRemove}
					>
						Usuń
					</Button>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}

export default DeleteAlert
