import { createSlice } from "@reduxjs/toolkit"

const pagesSlice = createSlice({
	name: "pages",
	initialState: {
		pages: null,
		initValues: null,
		parentMenus: null,
		searchingTerm: "",
		showFilterValue: null,
		parentMenuFilterValue: null,
		isDisabledDragAndDrop: true,
		isActiveDragAndDrop: false,
	},
	reducers: {
		setPagesData(state, action) {
			state.pages = action.payload.pages
			state.initValues = action.payload.pages
			state.parentMenus = action.payload.parentMenus
		},
		setSearchingTerm(state, action) {
			state.searchingTerm = action.payload
		},
		setShowFilterValue(state, action) {
			state.showFilterValue = action.payload
		},
		setParentMenuFilterValue(state, action) {
			state.parentMenuFilterValue = action.payload
		},
		clearPagesData(state) {
			state.pages = null
			state.initValues = null
			state.parentMenus = null
			state.searchingTerm = ""
			state.showFilterValue = ""
			state.parentMenuFilterValue = ""
		},
		setPages(state, action) {
			state.pages = action.payload
		},
		setIsDisabledDragAndDrop(state, action) {
			state.isDisabledDragAndDrop = action.payload
		},
		setIsActiveDragAndDrop(state, action) {
			state.isActiveDragAndDrop = action.payload
		},
		setParentMenus(state, action) {
			state.parentMenus = action.payload
		},
		clearParentMenus(state) {
			state.parentMenus = null
		},
	},
})

export const {
	setPagesData,
	clearPagesData,
	setSearchingTerm,
	setShowFilterValue,
	setParentMenuFilterValue,
	setPages,
	setIsDisabledDragAndDrop,
	setIsActiveDragAndDrop,
	setParentMenus,
	clearParentMenus,
} = pagesSlice.actions

export default pagesSlice.reducer
