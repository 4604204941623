import { Flex, Table, TableContainer } from "@chakra-ui/react"
import { memo, useEffect, useState } from "react"
import { useFormikContext } from "formik"

import NoData from "../../common/NoData/NoData"
import RecurringEventsTableBody from "./RecurringEventsTableBody"
import RecurringEventsTableHeaders from "./RecurringEventsTableHeaders"
import { useDispatch, useSelector } from "react-redux"
import Pagination from "../../common/Pagination/Pagination"
import { setCurrentPage, setRecordsPerPage } from "../../../services/state/reducers/recurringEvents-slice"
import FormActionsButtons from "../../common/Form/FormActionsButtons"

const RecurringEventsTable = () => {
	const dispatch = useDispatch()
	const { values } = useFormikContext()
	const searchingTerm = useSelector((store) => store.recurringEvents.searchingTerm)
	const recordsPerPage = useSelector((store) => store.recurringEvents.recordsPerPage)
	const currentPage = useSelector((store) => store.recurringEvents.currentPage)
	const [startIndex, setStartIndex] = useState(0)
	const [endIndex, setEndIndex] = useState(recordsPerPage)
	const [recurringEvents, setRecurringEvents] = useState(
		values?.recurringEvents?.filter((event) =>
			event.content?.toString().toLowerCase().includes(searchingTerm?.toString().toLowerCase()),
		),
	)

	useEffect(() => {
		const start = (currentPage - 1) * recordsPerPage
		setStartIndex((currentPage - 1) * recordsPerPage)
		setEndIndex(start + recordsPerPage)
	}, [currentPage, recordsPerPage])

	useEffect(() => {
		setRecurringEvents(
			values?.recurringEvents?.filter((event) =>
				event.content?.toString().toLowerCase().includes(searchingTerm?.toString().toLowerCase()),
			),
		)
	}, [searchingTerm])

	useEffect(() => {
		const updatedEvents = recurringEvents.map((intention) => ({
			...values.recurringEvents?.find((item) => item.id === intention.id),
		}))
		setRecurringEvents(updatedEvents)
	}, [values.recurringEvents])

	const handleChangePage = (page) => {
		dispatch(setCurrentPage(page))
	}

	const handleChangeRecordsPerPage = (event) => {
		dispatch(setRecordsPerPage(Number(event.target.value)))
	}

	return (
		<>
			<TableContainer mt={10}>
				<Table
					borderRadius={"4px"}
					variant='simple'
					bgColor='#FFF'
					fontSize={{ base: 11, sm: 12, md: 13, lg: 13, xl: 14 }}
				>
					<RecurringEventsTableHeaders
						recurringEvents={recurringEvents}
						setRecurringEvents={setRecurringEvents}
					/>
					{!!recurringEvents?.length && (
						<RecurringEventsTableBody recurringEvents={recurringEvents.slice(startIndex, endIndex)} />
					)}
				</Table>
			</TableContainer>
			{!recurringEvents?.length && <NoData />}
			{!!recurringEvents?.length && (
				<Flex flexDirection={"column"} mt={10}>
					<Pagination
						totalRecords={recurringEvents?.length}
						page={currentPage}
						recordsPerPage={recordsPerPage}
						setRecordsPerPage={handleChangeRecordsPerPage}
						onPageChange={(page) => handleChangePage(page)}
					/>
					<Flex justifyContent={"flex-end"} mt={8}>
						<FormActionsButtons />
					</Flex>
				</Flex>
			)}
		</>
	)
}

export default memo(RecurringEventsTable)
