import { Flex } from "@chakra-ui/react"
import { memo, useEffect } from "react"
import { useFormikContext } from "formik"

import SearchInput from "../../common/SearchInput/SearchInput"
import FilterSelect from "../../common/FilterSelect/FilterSelect"
import { useDispatch, useSelector } from "react-redux"
import {
	setIsActiveDragAndDrop,
	setIsDisabledDragAndDrop,
	setParentMenuFilterValue,
	setSearchingTerm,
	setShowFilterValue,
} from "../../../services/state/reducers/pages-slice"

const PagesFilterBar = () => {
	const { values } = useFormikContext()
	const dispatch = useDispatch()
	const parentMenus = useSelector((store) => store.pages.parentMenus)
	const searchingTerm = useSelector((store) => store.pages.searchingTerm)
	const showFilterValue = useSelector((store) => store.pages.showFilterValue)
	const parentMenuFilterValue = useSelector((store) => store.pages.parentMenuFilterValue)

	useEffect(() => {
		const isDisabledDragAndDrop = !parentMenuFilterValue || searchingTerm !== ""
		dispatch(setIsDisabledDragAndDrop(isDisabledDragAndDrop))
		deactivationDragAndDrop()
	}, [parentMenuFilterValue, searchingTerm])

	const deactivationDragAndDrop = () => {
		if (parentMenuFilterValue !== "" && searchingTerm === "") return
		dispatch(setIsActiveDragAndDrop(false))
	}

	const handleSetSearchingTerm = (results) => {
		dispatch(setSearchingTerm(results))
	}

	const handleSetShowFilterValue = (results) => {
		dispatch(setShowFilterValue(results))
	}

	const handleSetParentMenuFilterValue = (results) => {
		dispatch(setParentMenuFilterValue(results))
	}

	return (
		<Flex gap={3} flexWrap={{ base: "wrap", md: "nowrap" }} justifyContent={"flex-end"}>
			<SearchInput
				searchText={searchingTerm}
				setSearchText={handleSetSearchingTerm}
				fieldsToFilter={["title"]}
				isDisabled={!values?.pages}
				originalArray={values?.pages}
				dependencies={[
					{ name: "is_visible", value: showFilterValue },
					{ name: "parent_menu", value: parentMenuFilterValue },
				]}
			/>
			<FilterSelect
				name='is_visible'
				isDisabled={!values?.pages}
				value={showFilterValue}
				setFilterValue={handleSetShowFilterValue}
				searchingBy={{ name: "title", value: searchingTerm }}
				filterFields={[
					{ name: "is_visible", value: showFilterValue },
					{ name: "parent_menu", value: parentMenuFilterValue },
				]}
				values={[
					{ id: true, name: "Tak" },
					{ id: false, name: "Nie" },
				]}
				array={values?.pages}
				placeholder={"Widoczne na stronie"}
			/>
			<FilterSelect
				name='parent_menu'
				isDisabled={!values?.pages || !parentMenus}
				value={parentMenuFilterValue}
				setFilterValue={handleSetParentMenuFilterValue}
				searchingBy={{ name: "title", value: searchingTerm }}
				filterFields={[
					{ name: "is_visible", value: showFilterValue },
					{ name: "parent_menu", value: parentMenuFilterValue },
				]}
				values={parentMenus}
				array={values?.pages}
				placeholder={"Menu nadrzędne"}
			/>
		</Flex>
	)
}

export default memo(PagesFilterBar)
