import { Flex, Text } from "@chakra-ui/layout"
import { useCallback, useState } from "react"
import { useFormikContext } from "formik"

import Editor from "../../../common/Editor/Editor"

const NewsEditContent = () => {
	const { values, setFieldValue } = useFormikContext()
	const [editorData] = useState(values?.content)

	const handleSetEditorData = useCallback((value) => setFieldValue("content", value), [])

	return (
		<Flex
			borderRadius='4px'
			boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
			bgColor='#FFF'
			px={7}
			py={5}
			flexDirection={"column"}
			gap={5}
		>
			<Text fontWeight={600} fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}>
				Treść
			</Text>
			<Editor editorData={editorData} setEditorData={handleSetEditorData} />
		</Flex>
	)
}

export default NewsEditContent
