import { Flex } from "@chakra-ui/react"
import { memo } from "react"
import { useFormikContext } from "formik"
import { useDispatch, useSelector } from "react-redux"

import SearchInput from "../../common/SearchInput/SearchInput"
import { setCurrentPage, setSearchingTerm } from "../../../services/state/reducers/recurringEvents-slice"

const RecurringEventsFilterBar = () => {
	const dispatch = useDispatch()
	const { values } = useFormikContext()
	const searchingTerm = useSelector((store) => store.recurringEvents.searchingTerm)

	const handleSetSearchingTerm = (results) => {
		dispatch(setSearchingTerm(results))
		dispatch(setCurrentPage(1))
	}

	return (
		<Flex gap={3} flexWrap={{ base: "wrap", md: "nowrap" }} justifyContent={"flex-end"}>
			<SearchInput
				searchText={searchingTerm || ""}
				setSearchText={handleSetSearchingTerm}
				fieldsToFilter={["intention"]}
				isDisabled={!values?.recurringEvents}
				originalArray={values?.recurringEvents}
			/>
		</Flex>
	)
}

export default memo(RecurringEventsFilterBar)
