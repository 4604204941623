import { Flex, Text } from "@chakra-ui/layout"
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/breadcrumb"
import { ChevronRightIcon } from "@chakra-ui/icons"
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import { Link, useParams } from "react-router-dom"

import AnimatedPage from "../../../../utils/animations/routes/AnimatedPage"
import NavigationButtons from "../../../../components/common/NavigationButtons/NavigationButtons"
import LoaderHOC from "../../../../components/common/Loader/LoaderHOC"
import { useGetSinglePageQuery } from "../../../../services/api/singlePagesApi"
import { clearSinglePageData, setSinglePageData } from "../../../../services/state/reducers/singlePage-slice"
import SinglePageEditForm from "../../../../components/management-panel/SinglePageContent/Edit/SinglePageEditForm"

const SinglePageEditPage = () => {
	const { singlePageId } = useParams()
	const { data, isLoading } = useGetSinglePageQuery(singlePageId)
	const dispatch = useDispatch()

	useEffect(() => {
		if (!data) return

		dispatch(setSinglePageData(data?.data?.page_menus))

		return () => dispatch(clearSinglePageData())
	}, [data])

	return (
		<AnimatedPage>
			<Flex alignItems={"center"} gap={5}>
				<NavigationButtons />
				<Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
					<BreadcrumbItem>
						<BreadcrumbLink
							as={Link}
							to={"/single-page-content"}
							fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}
						>
							Treści stron pojedynczych
						</BreadcrumbLink>
					</BreadcrumbItem>
					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}>
							{data?.data?.single_page?.header}
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>
			<Text mt={5} fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}>
				Edycja strony
			</Text>
			<LoaderHOC isLoading={isLoading}>
				<SinglePageEditForm initValues={data?.data?.single_page} />
			</LoaderHOC>
		</AnimatedPage>
	)
}

export default SinglePageEditPage
