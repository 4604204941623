import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react"
import { Field, useField, useFormikContext } from "formik"
import React, { useRef, useState } from "react"
import { useEffect } from "react"
import { debounce } from "lodash"
import { useCallback } from "react"

import { Textarea } from "@chakra-ui/textarea"

const DebounceTextArea = ({ label, ...props }) => {
	const textareaRef = useRef(null)
	const [field, meta, helpers] = useField(props)
	const [fieldValue, setFieldValue] = useState(field.value)
	const { setSubmitting, handleSubmit } = useFormikContext()

	useEffect(() => {
		if (field.value !== fieldValue) {
			setFieldValue(field.value)
		}
	}, [field.value])

	const fieldProps = {
		...field,
		...props,
		value: fieldValue,
		onChange: (e) => setFieldValue(e.target.value),
		onBlur: () => {},
	}

	delete fieldProps?.name
	const handleOnChangeValue = useCallback(
		debounce((value) => helpers.setValue(value), 300),
		[helpers],
	)

	useEffect(() => {
		handleOnChangeValue(fieldValue)
	}, [fieldValue])

	const handleInputChange = () => {
		const textarea = textareaRef.current
		textarea.style.height = "auto"
		textarea.style.height = textarea.scrollHeight + "px"
	}

	const handleKeyDown = () => (e) => {
		if (e.key === "Enter") {
			setSubmitting(true)
			e.preventDefault()
			setTimeout(() => {
				handleSubmit()
			}, 311)
		}
	}

	return (
		<FormControl isInvalid={meta.error && meta.touched}>
			<FormLabel my='0'>{label}</FormLabel>
			<Field>
				{({ field }) => (
					<Textarea
						ref={textareaRef}
						overflowY='hidden'
						onInput={handleInputChange}
						my='0'
						fontSize={[11, 12, 13, 13, 14]}
						autoComplete='true'
						onKeyPress={handleKeyDown()}
						{...field}
						{...fieldProps}
					/>
				)}
			</Field>
			<FormErrorMessage my='0' fontSize={[11, 12, 13, 13, 14]}>
				{meta.error}
			</FormErrorMessage>
		</FormControl>
	)
}

export default DebounceTextArea
