import { Button } from "@chakra-ui/react"
import { memo } from "react"

import LogOutIcon from "../../../assets/icons/menu/LogOutIcon"
import { useLogoutMutation } from "../../../services/api/authApi"

const LogOutButton = ({ boxSize, ...props }) => {
	const [logout, { isLoading, isSuccess }] = useLogoutMutation()

	return (
		<Button
			w='100%'
			isDisabled={isLoading || isSuccess}
			bgColor={"blue.900"}
			fontWeight={500}
			gap={5}
			color='#FFF'
			_hover={{
				bgColor: "blue.800",
			}}
			{...props}
			onClick={logout}
		>
			Wyloguj się <LogOutIcon boxSize={boxSize} />
		</Button>
	)
}

export default memo(LogOutButton)
