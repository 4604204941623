import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const recurringEventsApi = createApi({
	reducerPath: "recurringEventsApi",
	// eslint-disable-next-line no-undef
	baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_KEY}/api`, credentials: "include" }),
	tagTypes: ["RecurringEvents"],
	endpoints: (builder) => ({
		getRecurringEvents: builder.query({
			query: () => "/recurringEvents",
			keepUnusedDataFor: 0,
			providesTags: ["RecurringEvents"],
		}),
		addRecurringEvent: builder.mutation({
			query: (event) => ({
				url: "/recurringEvents",
				method: "POST",
				body: event,
			}),
		}),
		updateRecurringEvents: builder.mutation({
			query: (newEvents) => ({
				url: "/recurringEvents",
				method: "PATCH",
				body: newEvents,
			}),
			invalidatesTags: ["RecurringEvents"],
		}),
		deleteRecurringEvent: builder.mutation({
			query: (id) => ({
				url: `/recurringEvents/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["RecurringEvents"],
		}),
	}),
})

export const {
	useGetRecurringEventsQuery,
	useAddRecurringEventMutation,
	useUpdateRecurringEventsMutation,
	useDeleteRecurringEventMutation,
} = recurringEventsApi
