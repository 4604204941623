import { memo } from "react"
import { useSelector } from "react-redux"

const PermissionGuard = ({ children }) => {
	const hasPermission = useSelector(
		(state) => state.authApi.queries["getSession(undefined)"].data.data.is_super_admin,
	)
	return hasPermission && children
}

export default memo(PermissionGuard)
