import warnUpdateToast from "../../services/notification/updateToasts/warnUpdateToast"
import errorUpdateToast from "../../services/notification/updateToasts/errorUpdateToast"
import infoUpdateToast from "../../services/notification/updateToasts/infoUpdateToast"
import redirectUpdateToast from "../../services/notification/updateToasts/redirectUpdateToast"
import successUpdateToast from "../../services/notification/updateToasts/successUpdateToast"

const statusHandlers = [
	{
		condition: (status) => status >= 100 && status <= 199,
		handler: ({ toastId, message }) => {
			infoUpdateToast(toastId, message)
		},
	},
	{
		condition: (status) => status >= 200 && status <= 299,
		handler: ({ toastId, message }) => {
			successUpdateToast(toastId, message)
		},
	},
	{
		condition: (status) => status >= 300 && status <= 399,
		handler: ({ toastId, message }) => {
			redirectUpdateToast(toastId, message)
		},
	},
	{
		condition: (status) => status >= 400 && status <= 499,
		handler: ({ toastId, message }) => {
			warnUpdateToast(toastId, message)
		},
	},
	{
		condition: (status) => status >= 500 && status <= 599,
		handler: ({ toastId, message }) => {
			errorUpdateToast(toastId, message)
		},
	},
]

export const matchingHandler = (status) => statusHandlers.find(({ condition }) => condition(status))
