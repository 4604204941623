import { Th, Thead, Tr, useBoolean } from "@chakra-ui/react"
import { memo, useCallback, useMemo } from "react"

import SortDownIcon from "../../../assets/icons/common/SortDownIcon"
import SortUpIcon from "../../../assets/icons/common/SortUpIcon"
import PermissionGuard from "../../common/Permissions/PermissionGuard"
import { useFormikContext } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { setIsActiveDragAndDrop } from "../../../services/state/reducers/pages-slice"

const PagesTableHeaders = ({ pages }) => {
	const dispatch = useDispatch()
	const parentMenus = useSelector((store) => store.pages.parentMenus)
	const isActiveDragAndDrop = useSelector((store) => store.pages.isActiveDragAndDrop)

	const [isDescSortByTitle, setIsDescSortByTitle] = useBoolean(true)
	const [isDescSortByShow, setIsDescSortByShow] = useBoolean(true)
	const [isDescSortByMenu, setIsDescSortByMenu] = useBoolean(true)
	const [isDescSortByOrder, setIsDescSortByOrder] = useBoolean(true)
	const [isDescSortByFileName, setIsDescSortByFileName] = useBoolean(true)
	const { values, setFieldValue } = useFormikContext()

	const deactivationDragAndDrop = (key) => {
		if (!isActiveDragAndDrop || key === "parent_menu_name") return

		dispatch(setIsActiveDragAndDrop(false))
	}

	const sorting = useCallback((array, key, order) => {
		return array?.toSorted((a, b) => {
			const valueA = typeof a[key] === "string" ? a[key].toLowerCase() : a[key]
			const valueB = typeof b[key] === "string" ? b[key].toLowerCase() : b[key]

			if (!order) {
				if (valueA < valueB) return -1
				if (valueA > valueB) return 1
				return 0
			}
			if (valueA > valueB) return -1
			if (valueA < valueB) return 1
			return 0
		})
	}, [])

	const handleSorting = (toggle, key, order) => {
		if (pages?.length <= 1) return
		const sortedData = sorting(values.pages, key, order)

		deactivationDragAndDrop(key)
		setFieldValue("pages", sortedData)
		toggle()
	}

	const getSortIcon = (isDescSort) => {
		switch (isDescSort) {
			case true:
				return <SortDownIcon ms={2} boxSize={6} color='blue.600' />
			case false:
				return <SortUpIcon ms={2} boxSize={6} color='blue.600' />
			default:
				return <SortDownIcon ms={2} boxSize={6} color='blue.600' />
		}
	}

	const pagesWithParentMenuName = useMemo(
		() =>
			values.pages.map((page) => ({
				...page,
				parent_menu_name: parentMenus.find((item) => item.id === Number(page.parent_menu)).name,
			})),
		[values.pages],
	)

	const getPagesWithOutParentMenuName = useCallback(
		(array) =>
			array.map((page) => {
				const item = {
					...page,
				}
				delete item.parent_menu_name
				return item
			}),
		[],
	)

	const handleSortingByParentMenu = () => {
		if (pages?.length <= 1) return

		const sortedData = sorting(pagesWithParentMenuName, "parent_menu_name", isDescSortByMenu)

		deactivationDragAndDrop("parent_menu_name")

		setFieldValue("pages", getPagesWithOutParentMenuName(sortedData))
		setIsDescSortByMenu.toggle()
	}

	return (
		<Thead>
			<Tr>
				{isActiveDragAndDrop && <Th borderColor={"gray.700"}></Th>}
				<Th
					cursor={pages?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByTitle.toggle, "title", isDescSortByTitle)}
				>
					Tytuł
					{getSortIcon(isDescSortByTitle)}
				</Th>
				<Th
					cursor={pages?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByShow.toggle, "is_visible", isDescSortByShow)}
				>
					Pokaż
					{getSortIcon(isDescSortByShow)}
				</Th>
				<Th
					cursor={pages?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={handleSortingByParentMenu}
				>
					Menu nadrzędne
					{getSortIcon(isDescSortByMenu)}
				</Th>
				<Th
					cursor={pages?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByOrder.toggle, "order", isDescSortByOrder)}
				>
					Kolejność
					{getSortIcon(isDescSortByOrder)}
				</Th>
				<PermissionGuard>
					<Th
						cursor={pages?.length > 1 ? "pointer" : "not-allowed"}
						fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
						borderColor={"gray.700"}
						onClick={() => handleSorting(setIsDescSortByFileName.toggle, "file_name", isDescSortByFileName)}
					>
						Nazwa pliku
						{getSortIcon(isDescSortByFileName)}
					</Th>
				</PermissionGuard>
				<Th borderColor={"gray.700"}></Th>
			</Tr>
		</Thead>
	)
}

export default memo(PagesTableHeaders)
