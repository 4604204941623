import { Badge, Flex, Tbody, Td, Tr } from "@chakra-ui/react"
import { memo } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import EditButton from "../../common/EditButton/EditButton"

const SubpagesTableBody = ({ subpages }) => {
	const location = useLocation()
	const navigate = useNavigate()

	const handleEditNavigate = (id) => {
		const splittedPath = location.pathname.split("/")[1]
		navigate(`/${splittedPath}/${id}`)
	}

	return (
		<Tbody>
			{subpages?.map((subpage, index) => (
				<Tr key={index}>
					<Td>{subpage?.header}</Td>
					<Td>{subpage?.introduction}</Td>
					<Td>{subpage?.page_menu}</Td>
					<Td>
						<Badge p={1} colorScheme='blue' fontSize={{ base: 10, md: 11, xl: 12 }}>
							{subpage?.order}
						</Badge>
					</Td>
					<Td>
						<Flex justifyContent={"end"}>
							<EditButton onClick={() => handleEditNavigate(subpage?.id)} />
						</Flex>
					</Td>
				</Tr>
			))}
		</Tbody>
	)
}

export default memo(SubpagesTableBody)
