import { Th, Thead, Tr, useBoolean } from "@chakra-ui/react"
import { memo, useCallback } from "react"

import SortDownIcon from "../../../../assets/icons/common/SortDownIcon"
import SortUpIcon from "../../../../assets/icons/common/SortUpIcon"

const FooterIconsTableHeaders = ({ filteredIcons, setFilteredIcons }) => {
	const [isDescSortByName, setIsDescSortByName] = useBoolean(true)
	const [isDescSortByAlt, setIsDescSortByAlt] = useBoolean(true)
	const [isDescSortByLink, setIsDescSortByLink] = useBoolean(true)

	const sorting = useCallback((array, key, order) => {
		return array?.toSorted((a, b) => {
			const valueA = typeof a[key] === "string" ? a[key].toLowerCase() : a[key]
			const valueB = typeof b[key] === "string" ? b[key].toLowerCase() : b[key]

			if (!order) {
				if (valueA < valueB) return -1
				if (valueA > valueB) return 1
				return 0
			}
			if (valueA > valueB) return -1
			if (valueA < valueB) return 1
			return 0
		})
	}, [])

	const handleSorting = (toggle, key, order) => {
		if (filteredIcons?.length <= 1) return
		const sortedData = sorting(filteredIcons, key, order)

		setFilteredIcons(sortedData)

		toggle()
	}

	const getSortIcon = (isDescSort) => {
		switch (isDescSort) {
			case true:
				return <SortDownIcon ms={2} boxSize={6} color='blue.600' />
			case false:
				return <SortUpIcon ms={2} boxSize={6} color='blue.600' />
			default:
				return <SortDownIcon ms={2} boxSize={6} color='blue.600' />
		}
	}

	return (
		<Thead>
			<Tr>
				<Th borderColor={"gray.700"}></Th>
				<Th
					cursor={filteredIcons?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByName.toggle, "name", isDescSortByName)}
				>
					Nazwa
					{getSortIcon(isDescSortByName)}
				</Th>
				<Th
					cursor={filteredIcons?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByAlt.toggle, "alt", isDescSortByAlt)}
				>
					Alt
					{getSortIcon(isDescSortByAlt)}
				</Th>
				<Th
					cursor={filteredIcons?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByLink.toggle, "link", isDescSortByLink)}
				>
					Link
					{getSortIcon(isDescSortByLink)}
				</Th>
				<Th borderColor={"gray.700"}></Th>
			</Tr>
		</Thead>
	)
}

export default memo(FooterIconsTableHeaders)
