import { Flex } from "@chakra-ui/react"
import { useFormikContext } from "formik"

import CancelButton from "./CancelButton"
import SaveButton from "./SaveButton"

const FormActionsButtons = () => {
	const { isSubmitting } = useFormikContext()

	return (
		<Flex gap={{ base: 3, md: 4, xl: 5 }}>
			<CancelButton isDisabled={isSubmitting} />
			<SaveButton isDisabled={isSubmitting} />
		</Flex>
	)
}

export default FormActionsButtons
