import { Box, Flex, Text } from "@chakra-ui/layout"
import { FastField, useFormikContext } from "formik"
import { Input } from "@chakra-ui/input"
import { Image } from "@chakra-ui/image"
import { Button } from "@chakra-ui/button"
import { useRef } from "react"

import DebounceTextField from "../../../common/Form/DebounceTextField"
import BinIcon from "../../../../assets/icons/common/BinIcon"

const SinglePageEditBasicDetails = () => {
	const iconInputRef = useRef()
	const { isSubmitting, setFieldValue, values } = useFormikContext()

	const handleSetIcon = (e) => {
		e.preventDefault()

		const file = e.type === "drop" ? e.dataTransfer.files[0] : e.target.files[0]
		if (!file || !file.type.includes("image")) return
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => {
			setFieldValue("icon", reader.result)
		}
	}

	const handleClickAddIcon = () => {
		if (!iconInputRef) return

		iconInputRef.current.click()
	}

	const handleDeleteIcon = () => {
		if (!values.icon) return

		iconInputRef.current.value = null
		setFieldValue("icon", "")
	}

	return (
		<Box
			borderRadius='4px'
			boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
			bgColor='#FFF'
			px={7}
			py={3}
		>
			<Flex flexDirection={"column"} gap={4}>
				<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
					<Text
						w={{ base: "100px", sm: "120px", lg: "150px" }}
						fontWeight={600}
						fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
					>
						Ikona
					</Text>
					<Box w='100%' onDragOver={(e) => e.preventDefault()} onDrop={handleSetIcon}>
						{values?.icon ? (
							<Flex alignItems={"center"} gap={10}>
								<Image
									alt='Ikona'
									boxSize={{ base: "30px", sm: "32px", lg: "40px" }}
									objectFit={"contain"}
									src={values.icon}
								/>
								<Button
									p={1}
									h={{ base: "30px", sm: "32px", lg: "35px" }}
									w={{ base: "30px", sm: "32px", lg: "35px" }}
									isDisabled={isSubmitting}
									onClick={handleDeleteIcon}
								>
									<BinIcon color='red' boxSize={[8, 9]} />
								</Button>
							</Flex>
						) : (
							<Button
								fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
								colorScheme='blue'
								isDisabled={isSubmitting}
								onClick={handleClickAddIcon}
							>
								Dodaj ikone
							</Button>
						)}
					</Box>
					<Input ref={iconInputRef} display={"none"} type='file' accept='image/*' onChange={handleSetIcon} />
				</Flex>
				<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
					<Text
						w={{ base: "100px", sm: "120px", lg: "150px" }}
						fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
					>
						Tytuł
					</Text>
					<FastField name='title'>
						{() => (
							<DebounceTextField
								isDisabled={isSubmitting}
								name='title'
								w='auto'
								placeholder='Wpisz tytuł'
							/>
						)}
					</FastField>
				</Flex>
				<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
					<Text
						w={{ base: "100px", sm: "120px", lg: "150px" }}
						fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
					>
						Autor
					</Text>
					<FastField name='author'>
						{() => (
							<DebounceTextField
								isDisabled={isSubmitting}
								name='author'
								w='auto'
								placeholder='Wpisz tytuł'
							/>
						)}
					</FastField>
				</Flex>
				<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
					<Text
						w={{ base: "100px", sm: "120px", lg: "150px" }}
						fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
					>
						Alt
					</Text>
					<FastField name='alt'>
						{() => (
							<DebounceTextField
								isDisabled={isSubmitting}
								name='alt'
								w='auto'
								placeholder='Wpisz tytuł'
							/>
						)}
					</FastField>
				</Flex>
			</Flex>
		</Box>
	)
}

export default SinglePageEditBasicDetails
