import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex } from "@chakra-ui/react"
import { useEffect } from "react"
import { ChevronRightIcon } from "@chakra-ui/icons"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import NavigationButtons from "../../../components/common/NavigationButtons/NavigationButtons"
import AnimatedPage from "../../../utils/animations/routes/AnimatedPage"
import LoaderHOC from "../../../components/common/Loader/LoaderHOC"
import { useGetNewsQuery } from "../../../services/api/newsApi"
import NewsFilterBar from "../../../components/management-panel/News/NewsFilterBar"
import NewsTable from "../../../components/management-panel/News/NewsTable"
import AddNewButton from "../../../components/common/AddNewButton/AddNewButton"
import { clearNewsData, setNewsData } from "../../../services/state/reducers/news-slice"

const NewsPage = () => {
	const { data, isLoading } = useGetNewsQuery()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const news = useSelector((store) => store.news.news)

	useEffect(() => {
		if (!data) return
		dispatch(setNewsData(data?.data?.news))

		return () => dispatch(clearNewsData())
	}, [data])

	const handleNavigateToAdd = () => navigate("/news/add")

	return (
		<AnimatedPage>
			<Flex alignItems={"center"} gap={5}>
				<NavigationButtons />
				<Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}>Aktualności</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>
			<LoaderHOC isLoading={isLoading} mt={{ base: "60px", md: "140px", lg: "250px" }}>
				{news && (
					<>
						<Flex justifyContent={"flex-end"} mt={{ base: 3, md: 6 }} gap={3} flexWrap={"wrap"}>
							<NewsFilterBar />
							<AddNewButton onClick={handleNavigateToAdd} />
						</Flex>
						<NewsTable />
					</>
				)}
			</LoaderHOC>
		</AnimatedPage>
	)
}

export default NewsPage
