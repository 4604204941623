import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const singlePagesApi = createApi({
	reducerPath: "singlePagesApi",
	// eslint-disable-next-line no-undef
	baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_KEY}/api`, credentials: "include" }),
	tagTypes: ["SinglePages"],
	endpoints: (builder) => ({
		getSinglePages: builder.query({
			query: () => "/singlePageContent",
			keepUnusedDataFor: 0,
			providesTags: ["SinglePages"],
		}),
		updateSinglePage: builder.mutation({
			query: (newSinglePage) => ({
				url: "/singlePageContent",
				method: "PATCH",
				body: newSinglePage,
			}),
			invalidatesTags: ["SinglePages"],
		}),
		getSinglePage: builder.query({
			query: (id) => `/singlePageContent/${id}`,
			keepUnusedDataFor: 0,
		}),
		getSinglePageParentMenu: builder.query({
			query: (id) => `/getParentMenu/${id}`,
			keepUnusedDataFor: 0,
		}),
	}),
})

export const {
	useGetSinglePagesQuery,
	useUpdateSinglePageMutation,
	useGetSinglePageQuery,
	useGetSinglePageParentMenuQuery,
} = singlePagesApi
