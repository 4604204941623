import { createSlice } from "@reduxjs/toolkit"

const exCathedra = createSlice({
	name: "exCathedra",
	initialState: {
		exCathedra: null,
		initArray: null,
	},
	reducers: {
		setExCathedraData(state, action) {
			state.exCathedra = action.payload
			state.initArray = action.payload
		},
		clearExCathedraData(state) {
			state.exCathedra = null
			state.initArray = null
		},
		setExCathedra(state, action) {
			state.exCathedra = action.payload
		},
	},
})

export const { setExCathedraData, clearExCathedraData, setExCathedra } = exCathedra.actions

export default exCathedra.reducer
