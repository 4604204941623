import { Box, Flex, Text } from "@chakra-ui/layout"
import { FastField, useFormikContext } from "formik"

import DebounceTextField from "../../../common/Form/DebounceTextField"

const SubpageEditHeader = () => {
	const { isSubmitting } = useFormikContext()

	return (
		<Box
			borderRadius='4px'
			boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
			bgColor='#FFF'
			px={7}
			py={3}
		>
			<Flex gap={{ base: 5, lg: 10 }} alignItems={"center"}>
				<Text
					w={{ base: "100px", sm: "120px", lg: "150px" }}
					fontWeight={600}
					fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}
				>
					Nagłówek
				</Text>
				<FastField name='header'>
					{() => (
						<DebounceTextField
							isDisabled={isSubmitting}
							name='header'
							w={["auto"]}
							placeholder='Wpisz nagłówek'
						/>
					)}
				</FastField>
			</Flex>
		</Box>
	)
}

export default SubpageEditHeader
