import { Flex } from "@chakra-ui/react"
import { memo } from "react"
import { useNavigate } from "react-router-dom"

import FormikForm from "../../common/Form/FormikForm"
import AddNewButton from "../../common/AddNewButton/AddNewButton"
import RecurringEventsTable from "./RecurringEventsTable"
import RecurringEventsFilterBar from "./RecurringEventsFilterBar"
import { useUpdateRecurringEventsMutation } from "../../../services/api/recurringEventsApi"

const RecurringEventsForm = ({ initValues }) => {
	const [updateIntentions] = useUpdateRecurringEventsMutation()
	const navigate = useNavigate()

	const handleNavigateToAdd = () => navigate("/calendar-recurring-events/add")

	return (
		<FormikForm
			isPatchMethod
			enableReinitialize
			initialValues={{ recurringEvents: initValues }}
			onSubmit={updateIntentions}
		>
			<Flex justifyContent={"flex-end"} mt={{ base: 3, md: 6 }} gap={3} flexWrap={"wrap"}>
				<RecurringEventsFilterBar />
				<AddNewButton onClick={handleNavigateToAdd} />
			</Flex>
			<RecurringEventsTable />
		</FormikForm>
	)
}

export default memo(RecurringEventsForm)
