import { Flex, Text } from "@chakra-ui/layout"
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/breadcrumb"
import { ChevronRightIcon } from "@chakra-ui/icons"
import { Link, useParams } from "react-router-dom"

import AnimatedPage from "../../../../utils/animations/routes/AnimatedPage"
import NavigationButtons from "../../../../components/common/NavigationButtons/NavigationButtons"
import LoaderHOC from "../../../../components/common/Loader/LoaderHOC"
import { useGetNewQuery } from "../../../../services/api/newsApi"
import NewsEditForm from "../../../../components/management-panel/News/Edit/NewsEditForm"

const NewsEditPage = () => {
	const { newsId } = useParams()
	const { data, isLoading } = useGetNewQuery(newsId)

	return (
		<AnimatedPage>
			<Flex alignItems={"center"} gap={5}>
				<NavigationButtons />
				<Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
					<BreadcrumbItem>
						<BreadcrumbLink as={Link} to='/news' fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}>
							Aktualności
						</BreadcrumbLink>
					</BreadcrumbItem>
					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}>
							{data?.data?.news?.topic}
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>
			<Text mt={5} fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}>
				Edycja aktualności
			</Text>
			<LoaderHOC isLoading={isLoading}>
				<NewsEditForm initValues={data?.data?.news} />
			</LoaderHOC>
		</AnimatedPage>
	)
}

export default NewsEditPage
