import { Th, Thead, Tr, useBoolean } from "@chakra-ui/react"
import { memo } from "react"
import { useDispatch } from "react-redux"

import SortDownIcon from "../../../assets/icons/common/SortDownIcon"
import SortUpIcon from "../../../assets/icons/common/SortUpIcon"
import { setCurrentPage } from "../../../services/state/reducers/recurringEvents-slice"

const RecurringEvents = ({ recurringEvents, setRecurringEvents }) => {
	const dispatch = useDispatch()
	const [isDescSortByActive, setIsDescSortByActive] = useBoolean(true)
	const [isDescSortByFrom, setIsDescSortByFrom] = useBoolean(true)
	const [isDescSortByTo, setIsDescSortByTo] = useBoolean(true)
	const [isDescSortByHour, setIsDescSortByHour] = useBoolean(true)
	const [isDescSortByApartFromDay, setIsDescSortByApartFromDay] = useBoolean(true)
	const [isDescSortByApartFrom, setIsDescSortByApartFrom] = useBoolean(true)
	const [isDescSortByApartFromTo, setIsDescSortByApartFromTo] = useBoolean(true)
	const [isDescSortByContent, setIsDescSortByContent] = useBoolean(true)
	const [isDescSortByMain, setIsDescSortByMain] = useBoolean(true)
	const [isDescSortByMon, setIsDescSortByMon] = useBoolean(true)
	const [isDescSortByTue, setIsDescSortByTue] = useBoolean(true)
	const [isDescSortByWed, setIsDescSortByWed] = useBoolean(true)
	const [isDescSortByThu, setIsDescSortByThu] = useBoolean(true)
	const [isDescSortByFri, setIsDescSortByFri] = useBoolean(true)
	const [isDescSortBySat, setIsDescSortBySat] = useBoolean(true)
	const [isDescSortBySun, setIsDescSortBySun] = useBoolean(true)

	const sort = (array, key) => array?.sort((a, b) => (a[key] > b[key] ? 1 : -1))

	const sortByDate = (array, key) => array?.sort((a, b) => new Date(a[key]) - new Date(b[key]))

	const sortByTime = (array, key) =>
		array?.sort((a, b) => {
			const timeA = a[key]?.split(":") || "00:00"
			const timeB = b[key]?.split(":") || "00:00"

			if (parseInt(timeA[0]) !== parseInt(timeB[0])) {
				return parseInt(timeA[0]) - parseInt(timeB[0])
			}

			return parseInt(timeA[1]) - parseInt(timeB[1])
		})

	const getCurrentSorting = (type) => {
		switch (type) {
			case "date":
				return sortByDate
			case "time":
				return sortByTime
			default:
				return sort
		}
	}

	const handleSorting = (toggle, key, order, type) => {
		if (recurringEvents?.length <= 1) return

		const sortedData = getCurrentSorting(type)(recurringEvents, key)

		setRecurringEvents(order ? [...sortedData] : [...sortedData.reverse()])

		dispatch(setCurrentPage(1))

		toggle()
	}

	const getSortIcon = (isDescSort) => {
		switch (isDescSort) {
			case true:
				return <SortDownIcon ms={2} boxSize={6} color='blue.600' />
			case false:
				return <SortUpIcon ms={2} boxSize={6} color='blue.600' />
			default:
				return <SortDownIcon ms={2} boxSize={6} color='blue.600' />
		}
	}

	return (
		<Thead>
			<Tr>
				<Th
					cursor={recurringEvents?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByActive.toggle, "is_active", isDescSortByActive, "date")}
				>
					Aktywny
					{getSortIcon(isDescSortByActive)}
				</Th>
				<Th
					cursor={recurringEvents?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByFrom.toggle, "from", isDescSortByFrom, "date")}
				>
					Od
					{getSortIcon(isDescSortByFrom)}
				</Th>
				<Th
					cursor={recurringEvents?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByTo.toggle, "to", isDescSortByTo, "date")}
				>
					Do
					{getSortIcon(isDescSortByTo)}
				</Th>
				<Th
					cursor={recurringEvents?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByHour.toggle, "hour", isDescSortByHour, "time")}
				>
					Godzina
					{getSortIcon(isDescSortByHour)}
				</Th>
				<Th
					cursor={recurringEvents?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() =>
						handleSorting(
							setIsDescSortByApartFromDay.toggle,
							"except_day",
							isDescSortByApartFromDay,
							"date",
						)
					}
				>
					Oprócz dnia
					{getSortIcon(isDescSortByApartFromDay)}
				</Th>
				<Th
					cursor={recurringEvents?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() =>
						handleSorting(setIsDescSortByApartFrom.toggle, "except_from", isDescSortByApartFrom, "date")
					}
				>
					Oprócz od
					{getSortIcon(isDescSortByApartFrom)}
				</Th>
				<Th
					cursor={recurringEvents?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() =>
						handleSorting(setIsDescSortByApartFromTo.toggle, "except_to", isDescSortByApartFromTo, "date")
					}
				>
					Oprócz do
					{getSortIcon(isDescSortByApartFromTo)}
				</Th>
				<Th
					cursor={recurringEvents?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByMain.toggle, "is_main", isDescSortByMain)}
				>
					Główne
					{getSortIcon(isDescSortByMain)}
				</Th>
				<Th
					cursor={recurringEvents?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByMon.toggle, "monday", isDescSortByMon)}
				>
					Pn
					{getSortIcon(isDescSortByMon)}
				</Th>
				<Th
					cursor={recurringEvents?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByTue.toggle, "tuesday", isDescSortByTue)}
				>
					Wt
					{getSortIcon(isDescSortByTue)}
				</Th>
				<Th
					cursor={recurringEvents?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByWed.toggle, "wednesday", isDescSortByWed)}
				>
					Sr
					{getSortIcon(isDescSortByWed)}
				</Th>
				<Th
					cursor={recurringEvents?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByThu.toggle, "thursday", isDescSortByThu)}
				>
					Cz
					{getSortIcon(isDescSortByThu)}
				</Th>
				<Th
					cursor={recurringEvents?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByFri.toggle, "friday", isDescSortByFri)}
				>
					Pt
					{getSortIcon(isDescSortByFri)}
				</Th>
				<Th
					cursor={recurringEvents?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortBySat.toggle, "saturday", isDescSortBySat)}
				>
					So
					{getSortIcon(isDescSortBySat)}
				</Th>
				<Th
					cursor={recurringEvents?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortBySun.toggle, "sunday", isDescSortBySun)}
				>
					Nd
					{getSortIcon(isDescSortBySun)}
				</Th>
				<Th
					cursor={recurringEvents?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByContent.toggle, "content", isDescSortByContent)}
				>
					Treść
					{getSortIcon(isDescSortByContent)}
				</Th>
				<Th fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }} borderColor={"gray.700"}></Th>
			</Tr>
		</Thead>
	)
}

export default memo(RecurringEvents)
