import { Flex } from "@chakra-ui/react"
import { memo } from "react"
import { useNavigate } from "react-router-dom"

import FormikForm from "../../common/Form/FormikForm"
import AddNewButton from "../../common/AddNewButton/AddNewButton"
import OneDayEventsFilterBar from "./OneDayEventsFilterBar"
import OneDayEventsTable from "./OneDayEventsTable"
import { useUpdateOneDayEventsMutation } from "../../../services/api/oneDayEventsApi"

const OneDayEventsForm = ({ initValues }) => {
	const navigate = useNavigate()
	const [updateIntentions] = useUpdateOneDayEventsMutation()

	const handleNavigateToAdd = () => navigate("/calendar-one-day-events/add")

	return (
		<FormikForm
			isPatchMethod
			enableReinitialize
			initialValues={{ oneDayEvents: initValues }}
			onSubmit={updateIntentions}
		>
			<Flex justifyContent={"flex-end"} mt={{ base: 3, md: 6 }} gap={3} flexWrap={"wrap"}>
				<OneDayEventsFilterBar />
				<AddNewButton onClick={handleNavigateToAdd} />
			</Flex>
			<OneDayEventsTable />
		</FormikForm>
	)
}

export default memo(OneDayEventsForm)
