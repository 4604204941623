import { Th, Thead, Tr, useBoolean } from "@chakra-ui/react"
import { memo } from "react"
import { useDispatch } from "react-redux"
import { useFormikContext } from "formik"

import SortDownIcon from "../../../assets/icons/common/SortDownIcon"
import SortUpIcon from "../../../assets/icons/common/SortUpIcon"
import { setCurrentPage } from "../../../services/state/reducers/oneDayEvents-slice"

const OneDayEventsTableHeaders = ({ oneDayEvents, setOneDayEvents }) => {
	const dispatch = useDispatch()
	const { values } = useFormikContext()
	const [isDescSortByIsActive, setIsDescSortByIsActive] = useBoolean(true)
	const [isDescSortByDay, setIsDescSortByDay] = useBoolean(true)
	const [isDescSortByHour, setIsDescSortByHour] = useBoolean(true)
	const [isDescSortByIsMain, setIsDescSortByIsMain] = useBoolean(true)
	const [isDescSortByContent, setIsDescSortByContent] = useBoolean(true)

	const sort = (array, key) => array?.sort((a, b) => (a[key] > b[key] ? 1 : -1))

	const sortByDate = (array, key) => array?.sort((a, b) => new Date(a[key]) - new Date(b[key]))

	const sortByTime = (array, key) =>
		array?.sort((a, b) => {
			const timeA = a[key]?.split(":") || "00:00"
			const timeB = b[key]?.split(":") || "00:00"

			if (parseInt(timeA[0]) !== parseInt(timeB[0])) {
				return parseInt(timeA[0]) - parseInt(timeB[0])
			}

			return parseInt(timeA[1]) - parseInt(timeB[1])
		})

	const getCurrentSorting = (type) => {
		switch (type) {
			case "date":
				return sortByDate
			case "time":
				return sortByTime
			default:
				return sort
		}
	}

	const handleSorting = (toggle, key, order, type) => {
		if (values?.intentions?.length <= 1) return

		const sortedData = getCurrentSorting(type)(oneDayEvents, key)

		setOneDayEvents(order ? [...sortedData] : [...sortedData.reverse()])

		dispatch(setCurrentPage(1))

		toggle()
	}
	const getSortIcon = (isDescSort) => {
		switch (isDescSort) {
			case true:
				return <SortDownIcon ms={2} boxSize={6} color='blue.600' />
			case false:
				return <SortUpIcon ms={2} boxSize={6} color='blue.600' />
			default:
				return <SortDownIcon ms={2} boxSize={6} color='blue.600' />
		}
	}

	return (
		<Thead>
			<Tr>
				<Th
					cursor={oneDayEvents?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByIsActive.toggle, "is_active", isDescSortByIsActive)}
				>
					Aktywny
					{getSortIcon(isDescSortByIsActive)}
				</Th>
				<Th
					cursor={oneDayEvents?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByDay.toggle, "in_day", isDescSortByDay)}
				>
					W dniu
					{getSortIcon(isDescSortByDay)}
				</Th>
				<Th
					cursor={oneDayEvents?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByHour.toggle, "hour", isDescSortByHour)}
				>
					Godzina
					{getSortIcon(isDescSortByHour)}
				</Th>
				<Th
					cursor={oneDayEvents?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByIsMain.toggle, "is_main", isDescSortByIsMain)}
				>
					Główne
					{getSortIcon(isDescSortByIsMain)}
				</Th>
				<Th
					cursor={oneDayEvents?.length > 1 ? "pointer" : "not-allowed"}
					fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }}
					borderColor={"gray.700"}
					onClick={() => handleSorting(setIsDescSortByContent.toggle, "content", isDescSortByContent)}
				>
					Treść
					{getSortIcon(isDescSortByContent)}
				</Th>
				<Th fontSize={{ base: 10, sm: 11, md: 12, xl: 13 }} borderColor={"gray.700"}></Th>
			</Tr>
		</Thead>
	)
}

export default memo(OneDayEventsTableHeaders)
