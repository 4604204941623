import { Flex } from "@chakra-ui/layout"

import FormikForm from "../../../common/Form/FormikForm"
import FormActionsButtons from "../../../common/Form/FormActionsButtons"
import OneDayEventsAddPanel from "./OneDayEventsAddPanel"
import { useAddOneDayEventMutation } from "../../../../services/api/oneDayEventsApi"

const OneDayEventsAddForm = () => {
	const [addEvent] = useAddOneDayEventMutation()

	return (
		<FormikForm
			enableReinitialize
			shouldNavigate={true}
			initialValues={{
				is_active: "",
				in_day: "",
				hour: "",
				is_main: "",
				content: "",
			}}
			onSubmit={addEvent}
		>
			<Flex flexDirection={"column"} gap={5} mt={7}>
				<OneDayEventsAddPanel />
			</Flex>
			<Flex justifyContent={"flex-end"} mt={5}>
				<FormActionsButtons />
			</Flex>
		</FormikForm>
	)
}

export default OneDayEventsAddForm
