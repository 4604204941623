import { Badge, Flex, Tbody, Td, Tr } from "@chakra-ui/react"
import { memo } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

import EditButton from "../../common/EditButton/EditButton"

const NewsTableBody = () => {
	const news = useSelector((store) => store.news.news)
	const location = useLocation()
	const navigate = useNavigate()

	const handleEditNavigate = (id) => {
		const splittedPath = location.pathname.split("/")[1]
		navigate(`/${splittedPath}/${id}`)
	}

	return (
		<Tbody>
			{news?.map((item, index) => (
				<Tr key={index}>
					<Td>
						<Badge p={1} colorScheme='blue' fontSize={{ base: 10, md: 11, xl: 12 }}>
							{item?.date}
						</Badge>
					</Td>
					<Td>{item?.topic}</Td>
					<Td>
						<Flex justifyContent={"end"}>
							<EditButton onClick={() => handleEditNavigate(item.id)} />
						</Flex>
					</Td>
				</Tr>
			))}
		</Tbody>
	)
}

export default memo(NewsTableBody)
