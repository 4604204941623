import { Flex } from "@chakra-ui/react"
import { memo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import SearchInput from "../../common/SearchInput/SearchInput"
import { setHomilies } from "../../../services/state/reducers/homilies-slice"

const HomiliesFilterBar = () => {
	const dispatch = useDispatch()
	const initArray = useSelector((store) => store.homilies.initArray)
	const [searchingTerm, setSearchingTerm] = useState("")

	const handleSetResults = (results) => {
		dispatch(setHomilies(results))
	}

	return (
		<Flex gap={3} flexWrap={{ base: "wrap", md: "nowrap" }} justifyContent={"flex-end"}>
			<SearchInput
				searchText={searchingTerm}
				setSearchText={setSearchingTerm}
				fieldsToFilter={["topic"]}
				isDisabled={!initArray}
				handleSetResults={handleSetResults}
				originalArray={initArray}
			/>
		</Flex>
	)
}

export default memo(HomiliesFilterBar)
