import { Table, TableContainer } from "@chakra-ui/react"
import { memo, useCallback } from "react"
import { useSelector } from "react-redux"
import { useFormikContext } from "formik"

import PagesTableHeaders from "./PagesTableHeaders"
import PagesTableBody from "./PagesTableBody"
import NoData from "../../common/NoData/NoData"

const PagesTable = () => {
	const { values } = useFormikContext()
	const searchingTerm = useSelector((store) => store.pages.searchingTerm)
	const showFilterValue = useSelector((store) => store.pages.showFilterValue)
	const parentMenuFilterValue = useSelector((store) => store.pages.parentMenuFilterValue)
	const isActiveDragAndDrop = useSelector((store) => store.pages.isActiveDragAndDrop)

	const orderSorting = useCallback((array) => {
		return array?.sort((a, b) => {
			const valueA = a.order
			const valueB = b.order

			if (valueA < valueB) return -1
			if (valueA > valueB) return 1
			return 0
		})
	}, [])

	let filteredPages = values?.pages?.filter((page) =>
		page.title.toString().toLowerCase().includes(searchingTerm.toString().toLowerCase()),
	)
	if (typeof showFilterValue === "boolean") {
		filteredPages = filteredPages.filter((page) => page.is_visible === showFilterValue)
	}
	if (parentMenuFilterValue) {
		filteredPages = filteredPages.filter((page) => Number(page.parent_menu) === parentMenuFilterValue)
	}

	isActiveDragAndDrop && orderSorting(filteredPages)

	return (
		<>
			<TableContainer mt={10}>
				<Table
					borderRadius={"4px"}
					variant='simple'
					bgColor='#FFF'
					fontSize={{ base: 11, sm: 12, md: 13, lg: 13, xl: 14 }}
				>
					<PagesTableHeaders pages={filteredPages} />
					{!!filteredPages?.length && <PagesTableBody pages={filteredPages} />}
				</Table>
			</TableContainer>
			{!filteredPages?.length && <NoData />}
		</>
	)
}

export default memo(PagesTable)
