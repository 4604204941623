import { useSelector } from "react-redux"
import { Navigate, Outlet } from "react-router-dom"

/**
 *
 * @returns Navigate to allowed routes if user has permission
 */
const PermissionRouteGuard = () => {
	const hasPermission = useSelector(
		(state) => state.authApi.queries["getSession(undefined)"].data.data.is_super_admin,
	)

	return hasPermission ? <Outlet /> : <Navigate to='/pages' />
}

export default PermissionRouteGuard
