import { memo, useState } from "react"
import { NavLink } from "react-router-dom"

const MenuItem = ({ name, path, fontSize }) => {
	const [isHover, setIsHover] = useState(false)

	return (
		<NavLink
			to={path}
			style={({ isActive, isTransitioning }) => {
				return {
					borderLeftWidth: "4px",
					borderLeftColor: "#63B3ED",
					borderRadius: "8px",
					fontSize: fontSize,
					paddingLeft: 15,
					paddingTop: 7,
					paddingBottom: 7,
					color: "#FFF",
					fontWeight: isActive ? "bold" : "",
					backgroundColor: isActive || isHover ? "#3182CE" : "",
					viewTransitionName: isTransitioning ? "slide" : "",
					transition: "background-color 0.3s",
				}
			}}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
		>
			{name}
		</NavLink>
	)
}

export default memo(MenuItem)
