import { Flex, Text } from "@chakra-ui/layout"
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/breadcrumb"
import { ChevronRightIcon } from "@chakra-ui/icons"
import { Link, useParams } from "react-router-dom"

import AnimatedPage from "../../../../../utils/animations/routes/AnimatedPage"
import NavigationButtons from "../../../../../components/common/NavigationButtons/NavigationButtons"
import GalleryImagesAddForm from "../../../../../components/management-panel/Gallery/GalleryImages/Add/GalleryImagesAddForm"

const GalleryImagesAddPage = () => {
	const { galleryId } = useParams()

	return (
		<AnimatedPage>
			<Flex alignItems={"center"} gap={5}>
				<NavigationButtons />
				<Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
					<BreadcrumbItem>
						<BreadcrumbLink as={Link} to='/gallery' fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}>
							Galeria
						</BreadcrumbLink>
					</BreadcrumbItem>
					<BreadcrumbItem>
						<BreadcrumbLink
							as={Link}
							to={`/gallery/${galleryId}/images`}
							fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}
						>
							Zdjęcia
						</BreadcrumbLink>
					</BreadcrumbItem>
					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}>Dodawanie</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>
			<Text mt={5} fontSize={{ base: 12, sm: 13, md: 14, lg: 15, xl: 16 }}>
				Dodawanie zdjęcia
			</Text>
			<GalleryImagesAddForm />
		</AnimatedPage>
	)
}

export default GalleryImagesAddPage
