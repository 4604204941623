import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex } from "@chakra-ui/react"
import { useEffect } from "react"
import { ChevronRightIcon } from "@chakra-ui/icons"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import NavigationButtons from "../../../components/common/NavigationButtons/NavigationButtons"
import AnimatedPage from "../../../utils/animations/routes/AnimatedPage"
import LoaderHOC from "../../../components/common/Loader/LoaderHOC"
import AddNewButton from "../../../components/common/AddNewButton/AddNewButton"
import ExCathedraFilterBar from "../../../components/management-panel/ExCathedra/ExCathedraFilterBar"
import ExCathedraTable from "../../../components/management-panel/ExCathedra/ExCathedraTable"
import { useGetExCathedraQuery } from "../../../services/api/exCathedraApi"
import { clearExCathedraData, setExCathedraData } from "../../../services/state/reducers/exCathedra-slice"

const ExCathedraPage = () => {
	const dispatch = useDispatch()
	const exCathedra = useSelector((store) => store.exCathedra.exCathedra)
	const navigate = useNavigate()
	const { data, isLoading } = useGetExCathedraQuery()

	useEffect(() => {
		if (!data) return
		dispatch(setExCathedraData(data?.data?.exCathedra))

		return () => dispatch(clearExCathedraData())
	}, [data])

	const handleNavigateToAdd = () => navigate("/exCathedra/add")

	return (
		<AnimatedPage>
			<Flex alignItems={"center"} gap={5}>
				<NavigationButtons />
				<Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize={{ base: 12, sm: 13, md: 14, lg: 16 }}>ExCathedra</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>
			<LoaderHOC isLoading={isLoading} mt={{ base: "60px", md: "140px", lg: "250px" }}>
				{exCathedra && (
					<>
						<Flex justifyContent={"flex-end"} mt={{ base: 3, md: 6 }} gap={3} flexWrap={"wrap"}>
							<ExCathedraFilterBar />
							<AddNewButton onClick={handleNavigateToAdd} />
						</Flex>
						<ExCathedraTable />
					</>
				)}
			</LoaderHOC>
		</AnimatedPage>
	)
}

export default ExCathedraPage
