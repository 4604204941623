import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const footerApi = createApi({
	reducerPath: "footerApi",
	// eslint-disable-next-line no-undef
	baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_KEY}/api`, credentials: "include" }),
	tagTypes: ["FooterPage", "FooterIcons"],
	endpoints: (builder) => ({
		getFooterPage: builder.query({
			query: () => "/footer",
			keepUnusedDataFor: 0,
			providesTags: ["FooterPage"],
		}),
		updateFooterPage: builder.mutation({
			query: (footerForm) => ({
				url: "/footer",
				method: "PATCH",
				body: footerForm,
			}),
			invalidatesTags: ["FooterPage"],
		}),
		getFooterIcons: builder.query({
			query: () => "/footer/icons",
			keepUnusedDataFor: 0,
			providesTags: ["FooterIcons"],
		}),
		getFooterIcon: builder.query({
			query: (id) => `/footer/icons/${id}`,
			keepUnusedDataFor: 0,
		}),
		addFooterIcons: builder.mutation({
			query: (icons) => ({
				url: "/footer/icons",
				method: "POST",
				body: icons,
			}),
			invalidatesTags: ["FooterIcons"],
		}),
		updateFooterIcon: builder.mutation({
			query: (icon) => ({
				url: `/footer/icons/${icon.id}`,
				method: "PATCH",
				body: icon,
			}),
			invalidatesTags: ["FooterIcons"],
		}),
		deleteFooterIcon: builder.mutation({
			query: (id) => ({
				url: `/footer/icons/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["FooterIcons"],
		}),
	}),
})

export const {
	useGetFooterPageQuery,
	useUpdateFooterPageMutation,
	useGetFooterIconsQuery,
	useGetFooterIconQuery,
	useAddFooterIconsMutation,
	useUpdateFooterIconMutation,
	useDeleteFooterIconMutation,
} = footerApi
